import React from 'react';

const Dots = (props) => {
  return (
    <svg width='164' height='164' {...props} viewBox='0 0 164 164' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.5'>
        <path d='M3 1.5C3 2.32837 2.32843 3 1.5 3C0.67157 3 0 2.32837 0 1.5C0 0.671631 0.67157 0 1.5 0C2.32843 0 3 0.671631 3 1.5Z' fill='#86A7FF' />
        <path d='M3 24.5C3 25.3284 2.32843 26 1.5 26C0.67157 26 0 25.3284 0 24.5C0 23.6716 0.67157 23 1.5 23C2.32843 23 3 23.6716 3 24.5Z' fill='#86A7FF' />
        <path d='M1.5 49C2.32843 49 3 48.3284 3 47.5C3 46.6716 2.32843 46 1.5 46C0.67157 46 0 46.6716 0 47.5C0 48.3284 0.67157 49 1.5 49Z' fill='#86A7FF' />
        <path d='M3 70.5C3 71.3284 2.32843 72 1.5 72C0.67157 72 0 71.3284 0 70.5C0 69.6716 0.67157 69 1.5 69C2.32843 69 3 69.6716 3 70.5Z' fill='#86A7FF' />
        <path d='M1.5 95C2.32843 95 3 94.3284 3 93.5C3 92.6716 2.32843 92 1.5 92C0.67157 92 0 92.6716 0 93.5C0 94.3284 0.67157 95 1.5 95Z' fill='#86A7FF' />
        <path d='M3 116.5C3 117.328 2.32843 118 1.5 118C0.67157 118 0 117.328 0 116.5C0 115.672 0.67157 115 1.5 115C2.32843 115 3 115.672 3 116.5Z' fill='#86A7FF' />
        <path d='M1.5 141C2.32843 141 3 140.328 3 139.5C3 138.672 2.32843 138 1.5 138C0.67157 138 0 138.672 0 139.5C0 140.328 0.67157 141 1.5 141Z' fill='#86A7FF' />
        <path d='M3 162.5C3 163.328 2.32843 164 1.5 164C0.67157 164 0 163.328 0 162.5C0 161.672 0.67157 161 1.5 161C2.32843 161 3 161.672 3 162.5Z' fill='#86A7FF' />
        <path d='M26 1.5C26 2.32837 25.3284 3 24.5 3C23.6716 3 23 2.32837 23 1.5C23 0.671631 23.6716 0 24.5 0C25.3284 0 26 0.671631 26 1.5Z' fill='#86A7FF' />
        <path d='M26 24.5C26 25.3284 25.3284 26 24.5 26C23.6716 26 23 25.3284 23 24.5C23 23.6716 23.6716 23 24.5 23C25.3284 23 26 23.6716 26 24.5Z' fill='#86A7FF' />
        <path d='M24.5 49C25.3284 49 26 48.3284 26 47.5C26 46.6716 25.3284 46 24.5 46C23.6716 46 23 46.6716 23 47.5C23 48.3284 23.6716 49 24.5 49Z' fill='#86A7FF' />
        <path d='M26 70.5C26 71.3284 25.3284 72 24.5 72C23.6716 72 23 71.3284 23 70.5C23 69.6716 23.6716 69 24.5 69C25.3284 69 26 69.6716 26 70.5Z' fill='#86A7FF' />
        <path d='M24.5 95C25.3284 95 26 94.3284 26 93.5C26 92.6716 25.3284 92 24.5 92C23.6716 92 23 92.6716 23 93.5C23 94.3284 23.6716 95 24.5 95Z' fill='#86A7FF' />
        <path d='M26 116.5C26 117.328 25.3284 118 24.5 118C23.6716 118 23 117.328 23 116.5C23 115.672 23.6716 115 24.5 115C25.3284 115 26 115.672 26 116.5Z' fill='#86A7FF' />
        <path d='M24.5 141C25.3284 141 26 140.328 26 139.5C26 138.672 25.3284 138 24.5 138C23.6716 138 23 138.672 23 139.5C23 140.328 23.6716 141 24.5 141Z' fill='#86A7FF' />
        <path d='M26 162.5C26 163.328 25.3284 164 24.5 164C23.6716 164 23 163.328 23 162.5C23 161.672 23.6716 161 24.5 161C25.3284 161 26 161.672 26 162.5Z' fill='#86A7FF' />
        <path d='M47.5 3C48.3284 3 49 2.32837 49 1.5C49 0.671631 48.3284 0 47.5 0C46.6716 0 46 0.671631 46 1.5C46 2.32837 46.6716 3 47.5 3Z' fill='#86A7FF' />
        <path d='M47.5 26C48.3284 26 49 25.3284 49 24.5C49 23.6716 48.3284 23 47.5 23C46.6716 23 46 23.6716 46 24.5C46 25.3284 46.6716 26 47.5 26Z' fill='#86A7FF' />
        <path d='M49 47.5C49 48.3284 48.3284 49 47.5 49C46.6716 49 46 48.3284 46 47.5C46 46.6716 46.6716 46 47.5 46C48.3284 46 49 46.6716 49 47.5Z' fill='#86A7FF' />
        <path d='M47.5 72C48.3284 72 49 71.3284 49 70.5C49 69.6716 48.3284 69 47.5 69C46.6716 69 46 69.6716 46 70.5C46 71.3284 46.6716 72 47.5 72Z' fill='#86A7FF' />
        <path d='M49 93.5C49 94.3284 48.3284 95 47.5 95C46.6716 95 46 94.3284 46 93.5C46 92.6716 46.6716 92 47.5 92C48.3284 92 49 92.6716 49 93.5Z' fill='#86A7FF' />
        <path d='M47.5 118C48.3284 118 49 117.328 49 116.5C49 115.672 48.3284 115 47.5 115C46.6716 115 46 115.672 46 116.5C46 117.328 46.6716 118 47.5 118Z' fill='#86A7FF' />
        <path d='M49 139.5C49 140.328 48.3284 141 47.5 141C46.6716 141 46 140.328 46 139.5C46 138.672 46.6716 138 47.5 138C48.3284 138 49 138.672 49 139.5Z' fill='#86A7FF' />
        <path d='M47.5 164C48.3284 164 49 163.328 49 162.5C49 161.672 48.3284 161 47.5 161C46.6716 161 46 161.672 46 162.5C46 163.328 46.6716 164 47.5 164Z' fill='#86A7FF' />
        <path d='M72 1.5C72 2.32837 71.3284 3 70.5 3C69.6716 3 69 2.32837 69 1.5C69 0.671631 69.6716 0 70.5 0C71.3284 0 72 0.671631 72 1.5Z' fill='#86A7FF' />
        <path d='M72 24.5C72 25.3284 71.3284 26 70.5 26C69.6716 26 69 25.3284 69 24.5C69 23.6716 69.6716 23 70.5 23C71.3284 23 72 23.6716 72 24.5Z' fill='#86A7FF' />
        <path d='M70.5 49C71.3284 49 72 48.3284 72 47.5C72 46.6716 71.3284 46 70.5 46C69.6716 46 69 46.6716 69 47.5C69 48.3284 69.6716 49 70.5 49Z' fill='#86A7FF' />
        <path d='M72 70.5C72 71.3284 71.3284 72 70.5 72C69.6716 72 69 71.3284 69 70.5C69 69.6716 69.6716 69 70.5 69C71.3284 69 72 69.6716 72 70.5Z' fill='#86A7FF' />
        <path d='M70.5 95C71.3284 95 72 94.3284 72 93.5C72 92.6716 71.3284 92 70.5 92C69.6716 92 69 92.6716 69 93.5C69 94.3284 69.6716 95 70.5 95Z' fill='#86A7FF' />
        <path d='M72 116.5C72 117.328 71.3284 118 70.5 118C69.6716 118 69 117.328 69 116.5C69 115.672 69.6716 115 70.5 115C71.3284 115 72 115.672 72 116.5Z' fill='#86A7FF' />
        <path d='M70.5 141C71.3284 141 72 140.328 72 139.5C72 138.672 71.3284 138 70.5 138C69.6716 138 69 138.672 69 139.5C69 140.328 69.6716 141 70.5 141Z' fill='#86A7FF' />
        <path d='M72 162.5C72 163.328 71.3284 164 70.5 164C69.6716 164 69 163.328 69 162.5C69 161.672 69.6716 161 70.5 161C71.3284 161 72 161.672 72 162.5Z' fill='#86A7FF' />
        <path d='M93.5 3C94.3284 3 95 2.32837 95 1.5C95 0.671631 94.3284 0 93.5 0C92.6716 0 92 0.671631 92 1.5C92 2.32837 92.6716 3 93.5 3Z' fill='#86A7FF' />
        <path d='M93.5 26C94.3284 26 95 25.3284 95 24.5C95 23.6716 94.3284 23 93.5 23C92.6716 23 92 23.6716 92 24.5C92 25.3284 92.6716 26 93.5 26Z' fill='#86A7FF' />
        <path d='M95 47.5C95 48.3284 94.3284 49 93.5 49C92.6716 49 92 48.3284 92 47.5C92 46.6716 92.6716 46 93.5 46C94.3284 46 95 46.6716 95 47.5Z' fill='#86A7FF' />
        <path d='M93.5 72C94.3284 72 95 71.3284 95 70.5C95 69.6716 94.3284 69 93.5 69C92.6716 69 92 69.6716 92 70.5C92 71.3284 92.6716 72 93.5 72Z' fill='#86A7FF' />
        <path d='M95 93.5C95 94.3284 94.3284 95 93.5 95C92.6716 95 92 94.3284 92 93.5C92 92.6716 92.6716 92 93.5 92C94.3284 92 95 92.6716 95 93.5Z' fill='#86A7FF' />
        <path d='M93.5 118C94.3284 118 95 117.328 95 116.5C95 115.672 94.3284 115 93.5 115C92.6716 115 92 115.672 92 116.5C92 117.328 92.6716 118 93.5 118Z' fill='#86A7FF' />
        <path d='M95 139.5C95 140.328 94.3284 141 93.5 141C92.6716 141 92 140.328 92 139.5C92 138.672 92.6716 138 93.5 138C94.3284 138 95 138.672 95 139.5Z' fill='#86A7FF' />
        <path d='M93.5 164C94.3284 164 95 163.328 95 162.5C95 161.672 94.3284 161 93.5 161C92.6716 161 92 161.672 92 162.5C92 163.328 92.6716 164 93.5 164Z' fill='#86A7FF' />
        <path d='M118 1.5C118 2.32837 117.328 3 116.5 3C115.672 3 115 2.32837 115 1.5C115 0.671631 115.672 0 116.5 0C117.328 0 118 0.671631 118 1.5Z' fill='#86A7FF' />
        <path d='M118 24.5C118 25.3284 117.328 26 116.5 26C115.672 26 115 25.3284 115 24.5C115 23.6716 115.672 23 116.5 23C117.328 23 118 23.6716 118 24.5Z' fill='#86A7FF' />
        <path d='M116.5 49C117.328 49 118 48.3284 118 47.5C118 46.6716 117.328 46 116.5 46C115.672 46 115 46.6716 115 47.5C115 48.3284 115.672 49 116.5 49Z' fill='#86A7FF' />
        <path d='M118 70.5C118 71.3284 117.328 72 116.5 72C115.672 72 115 71.3284 115 70.5C115 69.6716 115.672 69 116.5 69C117.328 69 118 69.6716 118 70.5Z' fill='#86A7FF' />
        <path d='M116.5 95C117.328 95 118 94.3284 118 93.5C118 92.6716 117.328 92 116.5 92C115.672 92 115 92.6716 115 93.5C115 94.3284 115.672 95 116.5 95Z' fill='#86A7FF' />
        <path d='M118 116.5C118 117.328 117.328 118 116.5 118C115.672 118 115 117.328 115 116.5C115 115.672 115.672 115 116.5 115C117.328 115 118 115.672 118 116.5Z' fill='#86A7FF' />
        <path d='M116.5 141C117.328 141 118 140.328 118 139.5C118 138.672 117.328 138 116.5 138C115.672 138 115 138.672 115 139.5C115 140.328 115.672 141 116.5 141Z' fill='#86A7FF' />
        <path d='M118 162.5C118 163.328 117.328 164 116.5 164C115.672 164 115 163.328 115 162.5C115 161.672 115.672 161 116.5 161C117.328 161 118 161.672 118 162.5Z' fill='#86A7FF' />
        <path d='M139.5 3C140.328 3 141 2.32837 141 1.5C141 0.671631 140.328 0 139.5 0C138.672 0 138 0.671631 138 1.5C138 2.32837 138.672 3 139.5 3Z' fill='#86A7FF' />
        <path d='M139.5 26C140.328 26 141 25.3284 141 24.5C141 23.6716 140.328 23 139.5 23C138.672 23 138 23.6716 138 24.5C138 25.3284 138.672 26 139.5 26Z' fill='#86A7FF' />
        <path d='M141 47.5C141 48.3284 140.328 49 139.5 49C138.672 49 138 48.3284 138 47.5C138 46.6716 138.672 46 139.5 46C140.328 46 141 46.6716 141 47.5Z' fill='#86A7FF' />
        <path d='M139.5 72C140.328 72 141 71.3284 141 70.5C141 69.6716 140.328 69 139.5 69C138.672 69 138 69.6716 138 70.5C138 71.3284 138.672 72 139.5 72Z' fill='#86A7FF' />
        <path d='M141 93.5C141 94.3284 140.328 95 139.5 95C138.672 95 138 94.3284 138 93.5C138 92.6716 138.672 92 139.5 92C140.328 92 141 92.6716 141 93.5Z' fill='#86A7FF' />
        <path d='M139.5 118C140.328 118 141 117.328 141 116.5C141 115.672 140.328 115 139.5 115C138.672 115 138 115.672 138 116.5C138 117.328 138.672 118 139.5 118Z' fill='#86A7FF' />
        <path d='M141 139.5C141 140.328 140.328 141 139.5 141C138.672 141 138 140.328 138 139.5C138 138.672 138.672 138 139.5 138C140.328 138 141 138.672 141 139.5Z' fill='#86A7FF' />
        <path d='M139.5 164C140.328 164 141 163.328 141 162.5C141 161.672 140.328 161 139.5 161C138.672 161 138 161.672 138 162.5C138 163.328 138.672 164 139.5 164Z' fill='#86A7FF' />
        <path d='M164 1.5C164 2.32837 163.328 3 162.5 3C161.672 3 161 2.32837 161 1.5C161 0.671631 161.672 0 162.5 0C163.328 0 164 0.671631 164 1.5Z' fill='#86A7FF' />
        <path d='M164 24.5C164 25.3284 163.328 26 162.5 26C161.672 26 161 25.3284 161 24.5C161 23.6716 161.672 23 162.5 23C163.328 23 164 23.6716 164 24.5Z' fill='#86A7FF' />
        <path d='M162.5 49C163.328 49 164 48.3284 164 47.5C164 46.6716 163.328 46 162.5 46C161.672 46 161 46.6716 161 47.5C161 48.3284 161.672 49 162.5 49Z' fill='#86A7FF' />
        <path d='M164 70.5C164 71.3284 163.328 72 162.5 72C161.672 72 161 71.3284 161 70.5C161 69.6716 161.672 69 162.5 69C163.328 69 164 69.6716 164 70.5Z' fill='#86A7FF' />
        <path d='M162.5 95C163.328 95 164 94.3284 164 93.5C164 92.6716 163.328 92 162.5 92C161.672 92 161 92.6716 161 93.5C161 94.3284 161.672 95 162.5 95Z' fill='#86A7FF' />
        <path d='M164 116.5C164 117.328 163.328 118 162.5 118C161.672 118 161 117.328 161 116.5C161 115.672 161.672 115 162.5 115C163.328 115 164 115.672 164 116.5Z' fill='#86A7FF' />
        <path d='M162.5 141C163.328 141 164 140.328 164 139.5C164 138.672 163.328 138 162.5 138C161.672 138 161 138.672 161 139.5C161 140.328 161.672 141 162.5 141Z' fill='#86A7FF' />
        <path d='M164 162.5C164 163.328 163.328 164 162.5 164C161.672 164 161 163.328 161 162.5C161 161.672 161.672 161 162.5 161C163.328 161 164 161.672 164 162.5Z' fill='#86A7FF' />
      </g>
    </svg>
  );
};

export default Dots;
