import React from "react";

const ImpressumPage = () => {
  return (
    <React.Fragment>
      <div className="max-w-full mx-auto px-4 sm:px-6 py-5">
        <h1 className="text-left font-[Poppins] font-bold text-xl text-gray-800">
          Impressum
        </h1>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Schwanenwik 14
          <br />
          22087 Hamburg
          <br />
          Tel: 040655 925 90
          <br />
          Fax: 040659 085 70
          <br />
          <a href="mailto:info@pflegebox-daheim.de">info@pflegebox-daheim.de</a>
          <p className="mt-2 text-lg sm:text-md text-gray-700">
            <strong>Registereintrag:</strong>
            <br />
            Eingetragen im Handelsregister
            <br />
            Registergericht: Amtsgericht / Hamburg
            <br />
            Registernummer: HRB 173614
          </p>
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          <strong>Vertreten durch:</strong>
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Mesut D&#252;zel (Gesch&#228;ftsf&#252;hrer)
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Kyrill Heinz (Gesch&#228;ftsf&#252;hrer)
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          <strong>Umsatzsteuer-ID:</strong>
          <br />
          Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:
          <br />
          DE351068343
        </p>
        <meta
          name="generator"
          content="Impressum-Generator der Kanzlei Hensche 
Rechtsanwälte"
        />
        <p className="mt-2 text-lg sm:text-md text-gray-700">&nbsp;</p>
        <h1>Disclaimer - rechtliche Hinweise</h1>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          <strong>Auskunfts- und Widerrufsrecht</strong>
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Sie haben jederzeit das Recht, sich unentgeltlich und
          unverz&#252;glich &#252;ber die zu Ihrer Person erhobenen Daten zu
          erkundigen. Ebenfalls k&#246;nnen Sie Ihre Zustimmung zur Verwendung
          Ihrer angegebenen pers&#246;nlichen Daten mit Wirkung f&#252;r die
          Zukunft widerrufen. Hierf&#252;r wenden Sie sich bitte an den im
          Impressum angegebenen Diensteanbieter.
        </p>
      </div>
    </React.Fragment>
  );
};

export default ImpressumPage;
