import React from "react";

import Text from "./Text";

const Tracker = ({ title, caption, img, SvgIcon }) => {
  return (
    <div className="flex flex-col justify-center items-center space-y-4 rounded-xl p-4 max-w-sm">
      <div className="h-24 w-24 object-cover">
        {img ? <img src={img} alt={title} /> : <SvgIcon />}
      </div>

      {/* <div className='h-16 w-16 bg-secondary rounded-full flex justify-center items-center text-2xl font-bold text-white'>{label}</div> */}
      <div className="text-center space-y-6">
        <Text type="h3">{title}</Text>
      </div>
    </div>
  );
};

export default Tracker;
