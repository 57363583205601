import Mobile from "assets/svg/mobile";
import React from "react";
import * as Button from "./Button";
import Text from "./Text";

const Section = ({ title, caption, show, img }) => {
  console.log(caption[0], "caption");
  return (
    <div className="relative flex flex-col justify-center items-center space-y-10 rounded-xl p-6 max-w-sm">
      <div
        className={`hidden lg:block absolute border-r-2 border-secondary ${
          show ? "h-40" : "h-0"
        } right-0 opacity-40 rounded-full`}
      ></div>
      <div className="w-32 h-32 flex justify-center">
        <div className="w-24 h-24 bg-light rounded-full relative flex justify-center">
          <div className="mt-5">
            <img src={img} alt={title} />
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <Text type="h3">{title}</Text>
        <ul class="list-none">
          {caption.map((item, key) => {
            return (
              <li className="text-center">
                {" "}
                <Text>{item}</Text>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Section;
