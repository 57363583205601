import React, { useState } from "react";

import * as Button from "components/Button";
import Text from "components/Text";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const shippingSchema = Yup.object().shape({
  name_ns: Yup.string().required("Pflichtfeld *"),
  street_no_ns: Yup.string().required("Pflichtfeld *"),
  house_no_ns: Yup.string().required("Pflichtfeld *"),
  postal_code_ns: Yup.string().required("Pflichtfeld *"),
  address_ns: Yup.string().required("Pflichtfeld *"),
  privacy_acceptance: Yup.bool().oneOf([true], "Pflichtfeld *"),
});

const ShippingStep = ({ goAhead, goBack, data }) => {
  const shippingSchemaSetting = Yup.object().shape({
    form_send: data.detail_cg ? Yup.string().required("Pflichtfeld *") : "",
    monthly_delivery: Yup.string().required("Pflichtfeld *"),
  });
  const [pflegedienst, setPflegedienst] = useState(data.monthly_delivery);

  const handleSubmit = (values) => {
    console.log(values, "Shipping Values");
    goAhead(values);
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={
        pflegedienst === "nursingService"
          ? shippingSchema
          : shippingSchemaSetting
      }
      onSubmit={handleSubmit}
    >
      {({}) => (
        <Form className="space-y-12 w-full lg:px-10 sm:px-5">
          <div className=" ">
            <div className="space-y-2">
              <h3 className="font-[Poppins] text-left font-bold text-xl text-gray-800">
                Lieferoptionen
              </h3>
            </div>
            {data.detail_cg && (
              <section className="flex flex-col w-full justify-items-start items-start space-y-4">
                <Text type="h4">
                  An welche E-Mail-Adresse soll die Bestellbestätigung gesendet
                  werden
                </Text>
                <div className="flex items-center  lg:flex-row  lg:space-x-12 sm:space-x-6  flex-col ">
                  <div className="flex justify-items-start items-center w-full	 space-x-2">
                    <Field type="radio" name="form_send" value="insurePerson" />
                    <Text>Versicherte(r)</Text>
                  </div>
                  <div className="flex justify-items-start items-center w-full	space-x-2">
                    <Field type="radio" name="form_send" value="careGiver" />
                    <Text>Angehörige(r)/Pflegeperson</Text>
                  </div>
                </div>
                <h4 className="text-red-500 font-bold">
                  <ErrorMessage name="form_send" />
                </h4>
              </section>
            )}
            <section className="flex flex-col mt-2 mb-2 w-full justify-items-start mt-5 items-start space-y-4">
              <Text type="h4">
                An welche Adresse soll die Pflegebox geliefert werden?
              </Text>
              <div className="flex items-center  lg:flex-row  lg:space-x-12 sm:space-x-6  flex-col ">
                {data.detail_cg && (
                  <div className="flex justify-items-start items-center w-full	 space-x-2">
                    <Field
                      type="radio"
                      name="monthly_delivery"
                      value="careGiver"
                      onClick={() => setPflegedienst("careGiver")}
                    />
                    <Text>Angehörige(r)/Pflegeperson</Text>
                  </div>
                )}
                <div className="flex justify-items-start items-center w-full	 space-x-2">
                  <Field
                    type="radio"
                    name="monthly_delivery"
                    value="insurePerson"
                    onClick={() => setPflegedienst("insurePerson")}
                  />
                  <Text>Versicherte(r)</Text>
                </div>
                <div className="flex justify-items-start items-center w-full	 space-x-2">
                  <Field
                    type="radio"
                    name="monthly_delivery"
                    value="nursingService"
                    onClick={() => setPflegedienst("nursingService")}
                  />
                  <Text>Pflegedienst</Text>
                </div>{" "}
              </div>{" "}
              <h4 className="text-red-500 font-bold">
                <ErrorMessage name="monthly_delivery" />
              </h4>
            </section>
            {pflegedienst === "nursingService" && (
              <section className="flex w-full text-left  relative flex-wrap">
                <div className="w-full">
                  <div className="relative space-y-4 m-2">
                    <Text type="h4">Name des Pflegedienstes *</Text>
                    <Field
                      name="name_ns"
                      placeholder="Name des Pflegedienstes *"
                      className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                    />
                    <h4 className="text-red-500 font-bold">
                      <ErrorMessage name="name_ns" />
                    </h4>
                  </div>
                </div>
                <div className="w-full flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="relative space-y-4 m-2">
                      <Text type="h4">Straße *</Text>
                      <Field
                        name="street_no_ns"
                        placeholder=" Straße *"
                        className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                      />{" "}
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="street_no_ns" />
                      </h4>
                    </div>
                  </div>{" "}
                  <div className="w-full md:w-1/2">
                    <div className="relative space-y-4 m-2">
                      <Text type="h4">Hausnummer *</Text>
                      <Field
                        name="house_no_ns"
                        placeholder="Hausnummer *"
                        className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                      />{" "}
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="house_no_ns" />
                      </h4>
                    </div>
                  </div>{" "}
                  <div className="w-full md:w-1/2">
                    <div className="relative space-y-4 m-2">
                      <Text type="h4">PLZ *</Text>
                      <Field
                        name="postal_code_ns"
                        placeholder="PLZ *"
                        className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                      />{" "}
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="postal_code_ns" />
                      </h4>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="relative space-y-4 m-2">
                      <Text type="h4">Ort *</Text>
                      <Field
                        name="address_ns"
                        placeholder="Ort *"
                        className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                      />{" "}
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="address_ns" />
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex space-x-2 justify-center items-center">
                    <Field
                      type="checkbox"
                      className="mr-2"
                      name="privacy_acceptance"
                    />
                    <div className="w-1/2">
                      <p className="text-xs text-left mb-2 text-gray-500">
                        Ich wünsche, dass der genannte Pflegedienst die
                        folgenden Leistungen erbringt: Information über den
                        gesetzlichen Anspruch auf die Erstattung von zum
                        Verbrauch bestimmten Pflegehilfsmitteln, Unterstützung
                        bei der Auswahl der geeigneten Produkte und Bearbeitung
                        des Antragformulars, Einweisung in die sach- und
                        fachgerechte Nutzung der Produkte.
                      </p>
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="privacy_acceptance" />
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <Button.Secondary onClick={goBack}>Zurück</Button.Secondary>
            <Button.Primary type="submit">
              Weiter zur Bestellübersicht
            </Button.Primary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShippingStep;
