import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

import FaqPage from 'pages/Faq';
import HomePage from './pages/Home';
import ComingSoonPage from 'pages/ComingSoon';
import ScrollToTop from 'components/ScrollToTop';
import ContactPage from 'pages/Contact';
import CareboxPage from 'pages/Carebox';
import CustomizerPage from 'pages/Customizer';
import CheckoutPage from 'pages/Checkout';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderColor } from 'app/slice/appSlice';
import { setLocalCart } from 'utils/storage';
import DatenschutzPage from 'pages/Datenschutz';
import ImpressumPage from 'pages/Impressum';

const PLAIN_HEADER_PATH = ['/customize', '/checkout'];

const App = () => {
  const cart = useSelector((state) => state.app.cart);
  const location = useLocation();
  const dispatch = useDispatch();

  // Change Header Background
  useEffect(() => {
    const setFill = !PLAIN_HEADER_PATH.includes(location.pathname);
    dispatch(setHeaderColor(setFill));
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setLocalCart(cart);
  }, [cart]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className='h-full'>
        <Navbar />
        <main className='space-y-4'>
          <Routes>
            <Route path='/wissenswertes' element={<FaqPage />} />
            <Route path='/kontakt' element={<ContactPage />} />
            <Route path='/pflegebox-bestellen' element={<CareboxPage />} />
            <Route path='/customize' element={<CustomizerPage />} />
            <Route path='/checkout' element={<CheckoutPage />} />
            <Route path='/datenschutz' element={<DatenschutzPage />} />
            <Route path='/impressum' element={<ImpressumPage />} />
            <Route path='/' element={<HomePage />} />
            <Route path='*' element={<ComingSoonPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default App;
