import React from "react";

import Text from "components/Text";
import * as Button from "components/Button";
import CurveLine from "assets/svg/curve";
import Carebox from "components/Carebox";
import Container from "components/Container";
import BookMe from "components/common/BookMe";
import Dots from "assets/svg/dots";
import Heart from "assets/svg/heart";
import { Products } from "db/Products";
import { Link, Navigate, useNavigate } from "react-router-dom";

const CareboxPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/customize");
  };
  return (
    <React.Fragment>
      <div className="relative max-w-full mx-auto md:px-10 min-h-max pt-10 bg-light overflow-hidden flex flex-col items-center space-y-6">
        <div className="max-w-7xl  flex-col md:flex-row lg:flex-row px-4 md:px-20 flex justify-start">
          <div className="lg:w-2/5 md:w-full sm:w-full flex">
            <img
              className="left-0 bottom-0 w-[600px]  h-[380px] object-cover py-4 md:py-0 lg:py-0 "
              src="/pflegebox-bestellen.png"
              alt="doctor"
            />
          </div>
          <div className="lg:w-3/5 sm:w-full   flex flex-col items-center py-4 md:py-0 lg:py-0">
            <div className="space-y-6">
              <Text type="h1">
                Die <span className="text-secondary">Pflegeboxen</span>
              </Text>
              <p className=" text-center text-lg mb-10">
                Mit dem Bestellen der Pflegebox-Daheim erhalten Sie jeden Monat
                die gewünschten Pflegehilfsmittel KOSTENLOS nach Hause geliefert
                und können somit im Jahr 480€ einsparen. Um Ihre Bestellung zu
                erleichtern, haben wir Ihnen 6 gängige Pflegeboxen
                zusammengestellt. Sie können Sich zwischen diesen 6 Pflegeboxen
                entscheiden oder Ihre individuelle Pflegebox zusammenstellen.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-full">
        <div className="max-w-7xl px-4  md:px-20 mx-auto flex flex-col lg:flex-row justify-around items-center ">
          <div className="relative md:w-1/2  shadow-md p-4 px-6 flex justify-center items-center ">
            <img
              className="h-full md:h-80 aspect-video z-10 object-cover"
              src="/box.png"
              alt="box"
            />
          </div>
          <div className="md:w-1/2 flex flex-col justify-center items-center space-y-8 py-10 sm:py-20 text-center">
            <div className="space-y-4 sm:space-y-1">
              <h2 className="font-[Poppins] font-bold text-2xl md:text-4xl text-center text-[#86A7FF]">
                Pflegebox individuell zusammenstellen
              </h2>
              <h4 className="font-[Poppins] font-bold text-xl text-gray-800">
                {" "}
                Pflegebox 'Individuell'
              </h4>
            </div>
            <div className="text-center px-4 space-y-4">
              <Text>
                Stellen Sie Ihre Pflegebox ganz individuell zusammen aus
                Einmalhandschuhen, Desinfektionsmittel, Bettschutzeinlagen und
                weiteren Produkten. Alternativ können Sie unten eine der
                voreingestellten Boxen auswählen.
              </Text>
            </div>
            <div className="">
              <Button.Primary
                onClick={() => {
                  handleClick();
                  window.localStorage.clear();
                }}
              >
                Box individuell zusammenstellen
              </Button.Primary>
            </div>
          </div>
        </div>
      </div>
      <section className="relative max-w-7xl px-4 md:px-20  mx-auto">
        <Dots className="absolute right-0 top-5" />
        <Heart className="absolute right-0 top-[40%] opacity-40" />
        <div className=" flex flex-col justify-center items-center">
          <Text type="h2">
            <span className="text-secondary">
              Vorgefertigte Pflegebox auswählen
            </span>
          </Text>
          <CurveLine className="w-32" />
        </div>

        <div className="flex flex-wrap justify-center items-center mt-10">
          {Products.map((product, key) => {
            return <Carebox key={key} product={product} />;
          })}
        </div>
      </section>

      <BookMe />
    </React.Fragment>
  );
};

export default CareboxPage;
