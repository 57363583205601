import React from 'react';

const CurveLine = (props) => {
  return (
    <svg width='386' height='25' {...props} viewBox='0 0 386 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.5' d='M3 22C3 22 118.61 2.99999 193 2.99998C267.39 2.99998 383 22 383 22' stroke='#86A7FF' stroke-width='5' stroke-linecap='round' />
    </svg>
  );
};

export default CurveLine;
