import React from "react";

const DatenschutzPage = () => {
  return (
    <React.Fragment>
      <div className="max-w-full mx-auto px-4 sm:px-6 py-5">
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800">
          Datenschutz (allgemein)
        </h2>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Beim Zugriff auf unsere Webseite werden automatisch allgemeine
          Informationen (sog. Server-Logfiles) erfasst. Diese beinhalten u.a.
          den von Ihnen verwendeten Webbrowser sowie Ihr Betriebssystem und
          Ihren Internet Service Provider. Diese Daten lassen keinerlei
          Rückschlüsse auf Ihre Person zu und werden von uns statistisch
          ausgewertet, um unseren Internetauftritt technisch und inhaltlich zu
          verbessern. Das Erfassen dieser Informationen ist notwendig, um den
          Inhalt der Webseite korrekt ausliefern zu können.
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Die Nutzung der Webseite ist grundsätzlich ohne Angabe
          personenbezogener Daten möglich. Soweit personenbezogene Daten
          (beispielsweise Name, Anschrift oder E-Mail- Adressen) erhoben werden,
          erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese
          Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte
          weitergegeben.
        </p>{" "}
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Sofern ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder
          geändert werden soll oder Sie an uns eine Anfrage stellen, erheben und
          verwenden wir personenbezogene Daten von Ihnen, soweit dies zu diesem
          Zwecke erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und
          nutzen personenbezogene Daten soweit dies erforderlich ist, um Ihnen
          die Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten).
          Sämtliche personenbezogenen Daten werden nur solange gespeichert wie
          dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder
          Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer-
          und handelsrechtliche Aufbewahrungsfristen von uns berücksichtigt. Auf
          Anordnung der zuständigen Stellen müssen wir im Einzelfall Auskunft
          über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der
          Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen
          Aufgaben der Verfassungsschutzbehörden oder des Militärischen
          Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen
          Eigentum erforderlich ist
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im
          Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen kann. Vor dem Zugriff auf Daten kann nicht lückenlos
          geschützt werden.
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          ausdrücklich untersagt. Ausgenommen hiervon sind bestehende
          Geschäftsbeziehungen bzw. es liegt Ihnen eine entsprechende
          Einwilligung von uns vor.
        </p>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Die Anbieter und alle auf dieser Website genannten Dritten behalten
          sich ausdrücklich rechtliche Schritte im Falle der unverlangten
          Zusendung von Werbeinformationen vor. Gleiches gilt für die
          kommerzielle Verwendung und Weitergabe der Daten
        </p>
        <h3 className="text-left mt-4 font-[Poppins] font-bold text-md text-gray-800">
          Datenschutzerklärung Facebook-Plugins (Like/ Gefällt mir-Button etc.)
        </h3>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Auf dieser Website sind Plugins des Social-Media-Anbieters
          Facebook.com (für Europa: Facebook Irland Limited, 4 Grand Canal
          Square, Dublin 2, Ireland; für die USA: Facebook Inc., 1601 Willow
          Road, Menlo Park, CA 94025, USA), implementiert. Eine Übersicht der
          Facebook-Plugins finden Sie hier:
          <a href="https://developers.facebook.com/docs/plugins/">
            https://developers.facebook.com/docs/plugins/
          </a>
          . Durch Ihren Besuch auf Seiten mit Facebook-Plugins, wird durch das
          Plugin eine Verbindung zwischen Ihrem Browser und einem Server von
          Facebook hergestellt. Hierdurch erhält Facebook Informationen zu Ihrer
          IP-Adresse und dem Besuch auf unserer Website.Um eine Zuordnung durch
          Facebook zwischen Ihrem Besuch auf unserer Website zu Ihrem
          Benutzerkonto zu verhindern, müssen Sie sich aus Ihrem
          Facebook-Account ausloggen.
        </p>
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800 mt-2 mb-2">
          Google Analytics{" "}
        </h2>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. ("Google"), 1600 Amphitheatre Parkway, Mountain View, CA
          94043, USA. Google Analytics verwendet sog. "Cookies" (Textdateien),
          die auf Ihrem Computer gespeichert werden und die eine Analyse der
          Benutzung der Website durch Sie ermöglichen. Die durch das Cookie
          erzeugten Informationen über Ihre Benutzung dieser Website werden in
          der Regel an einen Server von Google in den USA übertragen und dort
          gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser
          Website, wird Ihre IP-Adresse von Google jedoch innerhalb von
          Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
          des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
          in Ausnahmefällen wird die vollständige IP-Adresse an einen Server von
          Google in den USA übertragen und dort anonymisiert. Im Auftrag des
          Betreibers dieser Website wird Google diese Informationen benutzen, um
          Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
          Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt.
          <br /> Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich nutzen können. Sie können
          darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
          Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
          Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter dem folgenden Link verfügbare Browser-Add-on
          herunterladen und installieren:
          <a href=" http://tools.google.com/dlpage/gaoptout?hl=de">
            {" "}
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          . Das Browser-Add-on zur Deaktivierung von Google Analytics ist mit
          Chrome, Internet Explorer 8 bis 11, Safari, Firefox und Opera
          kompatibel. Weitere Informationen zu Nutzungsbedingungen und
          Datenschutz finden Sie unter folgenden Links:
          <a href=" http://www.google.com/analytics/terms/de.html">
            {" "}
            http://www.google.com/analytics/terms/de.html
          </a>{" "}
          und{" "}
          <a href="https://www.google.de/intl/de/policies/">
            https://www.google.de/intl/de/policies/
          </a>
        </p>
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800 mt-2 mb-2">
          Datenschutzerklärung Google AdWords Conversion-Tracking
        </h2>
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Auf dieser Website ist das Plugin für die "Google AdWords
          Conversion-Tracking"-Funktion der Google Inc. ("Google"), 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA implementiert.
          Hierfür werden sog. "Cookies" (Textdateien), die auf Ihrem Computer
          gespeichert werden und die eine Analyse der Benutzung der Website
          durch Sie ermöglichen, wenn Sie auf eine Anzeige von Google geklickt
          haben, verwendet. Die durch das Cookie erzeugten Informationen über
          Ihre Benutzung dieser Website werden für 90 Tage gespeichert.
          Personalisierte Daten werden dabei nicht gespeichert. Für die
          Gültigkeitsdauer können Google und wir als Betreiber erkennen, dass
          Sie eine Anzeige angeklickt haben und zu einer entsprechenden Zielsite
          gelangt sind. Die gespeicherten Cookies können nicht über mehrere
          Websites von verschiedenen AdWords-Kunden verfolgt werden. Es werden
          im Google AdWords-Dienst sog. Conversion-Statistiken erhoben, welche
          die Anzahl der Nutzer, die auf eine Anzeige geklickt haben, erfasst.
          Zusätzlich wird beziffert, wie viele Nutzer zu einer
          "Conversion-Tag-Zielsite" verlinkt wurden. <br />
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich nutzen können.
          <br /> Durch die Nutzung dieser Website erklären Sie sich mit der
          Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor
          beschriebenen Art und Weise und zu dem zuvor benannten Zweck
          einverstanden. Weitere Informationen zu Nutzungsbedingungen und
          Datenschutz finden Sie unter folgenden Links:
          <a href="https://support.google.com/adwords/answer/93148?ctx=tltpund">
            https://support.google.com/adwords/answer
          </a>
          <br></br>
          <a href="http://www.google.de/policie s/privacy/">
            http://www.google.de/policie s/privacy/
          </a>
          .
        </p>
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800 mt-2 mb-2">
          Disclaimer (Haftungsausschluss){" "}
        </h2>
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800 mt-2 mb-2">
          1. Haftung für Inhalte
        </h2>{" "}
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>{" "}
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800 mt-2 mb-2">
          2. Haftung für Links
        </h2>{" "}
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Diese Website enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte kein Einfluss genommen werden kann. Deshalb kann für diese
          fremden Inhalte auch keine Gewähr übernommen werden. Für die Inhalte
          der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
          der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
          der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend
          von dieser Website auf die rechtsverletzende Site entfernen.
        </p>{" "}
        <h2 className="text-left font-[Poppins] font-bold text-xl text-gray-800 mt-2 mb-2">
          3. Urheberrecht
        </h2>{" "}
        <p className="mt-2 text-lg sm:text-md text-gray-700">
          Die durch die Diensteanbieter, deren Mitarbeiter und beauftragte
          Dritte erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
          deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
          und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
          bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen Autors
          bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
          privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
          auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
          als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </React.Fragment>
  );
};

export default DatenschutzPage;
