import { createSlice } from "@reduxjs/toolkit";
import { STEPS } from "utils/common";
import { getLocalCart, clearLocalCart } from "utils/storage";

const initialState = {
  header: {
    filled: true,
  },
  cart: {
    product_id: null,
    extra: {},
    step_key: STEPS[0].key,
  },
};

const getInitialState = () => {
  const state = { ...initialState };

  const cart = getLocalCart();
  if (cart) state.cart = { ...state.cart, ...cart };

  return state;
};

export const appSlice = createSlice({
  name: "app",
  initialState: getInitialState(),
  reducers: {
    setHeaderColor: (state, action) => {
      state.header.filled = action.payload;
    },
    setProduct: (state, action) => {
      state.cart.product_id = action.payload.id;
    },

    plusExtraItem: (state, action) => {
      const key = action.payload.id;
      const value = state.cart.extra[key];

      if (value >= 0) state.cart.extra[key] = value + 1;
      else state.cart.extra[key] = 0;
    },

    minusExtraItem: (state, action) => {
      const key = action.payload.id;
      const value = state.cart.extra[key];

      if (state.cart.extra[key] > 0) state.cart.extra[key] = value - 1;
    },

    clearCart: (state, action) => {
      state.cart = initialState.cart;
      clearLocalCart();
    },

    changeStep: (state, action) => {
      state.cart.step_key = action.payload;
    },
  },
});

export const {
  setHeaderColor,
  setProduct,
  clearCart,
  plusExtraItem,
  minusExtraItem,
  changeStep,
} = appSlice.actions;

export default appSlice.reducer;
