import React, { useEffect, useState } from "react";

import Container from "components/Container";
import Information from "components/Information";

import { sections } from "utils/seeder";
import * as Button from "./Button";

const Listing = () => {
  console.log(sections);
  const [info, setInfo] = useState(sections[0].data);

  const [active, setActive] = useState(0);

  useEffect(() => {
    console.log({ active });
  }, [active]);

  const handleChange = (item, key) => {
    setInfo(item.data);
    setActive(key);
  };

  return (
    <div className="pt-16  max-w-7xl md:px-20 mx-auto">
      <div className="flex flex-wrap justify-center ">
        {sections.map((item, key) => {
          return (
            <Button.Secondary
              key={key}
              active={active === key}
              onClick={() => {
                handleChange(item, key);
              }}
            >
              {item.title}
            </Button.Secondary>
          );
        })}
      </div>

      <Information data={info} />
    </div>
  );
};

export default Listing;
