import React from 'react';

export const Primary = ({ children, active, onClick }) => {
  if (active)
    return (
      <button onClick={onClick} className={`m-2 p-3 px-7 bg-white border border-primary rounded-lg text-primary cursor-pointer hover:bg-primary hover:text-white`}>
        {children}
      </button>
    );

  return (
    <button onClick={onClick} className='m-2 p-3 px-7 bg-primary rounded-lg text-white cursor-pointer hover:bg-secondary'>
      {children}
    </button>
  );
};

export const Secondary = ({ children, active, onClick }) => {
  if (active)
    return (
      <button onClick={onClick} className='m-2 p-3 px-7 bg-primary rounded-lg text-white cursor-pointer hover:bg-secondary'>
        {children}
      </button>
    );

  return (
    <button onClick={onClick} className={`m-2 p-3 px-7 bg-white border border-primary rounded-lg text-primary cursor-pointer hover:bg-primary hover:text-white`}>
      {children}
    </button>
  );
};

export const MenuTab = ({ onClick, active, children }) => {
  return (
    <button
      onClick={onClick}
      className={`pt-10 pb-2 w-full border-b-2 ${active ? 'border-secondary text-secondary font-bold' : 'border-gray-400 text-gray-600'} hover:text-secondary hover:border-secondary`}>
      {children}
    </button>
  );
};
