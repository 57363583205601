import Block from "components/Block";
import React from "react";

const BookMe = () => {
  return (
    <Block
      title="Rückruf vereinbaren"
      description="Sie haben noch eine Frage und möchten sich beraten lassen? Schreiben Sie uns eine Nachricht oder buchen Sie sich schnell und unkompliziert ein kostenloses Beratungsgespräch. Gerne helfen wir Ihnen auch bei der Auswahl Ihrer Pflegebox."
      link="/kontakt"
      label="Rückruf vereinbaren"
      src="/vereinbaren.png"
    />
  );
};

export default BookMe;
