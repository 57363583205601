import React from "react";

import Text from "./Text";

import Dots from "assets/svg/dots";
import Faq2 from "./Faq2";
import { useState } from "react";

const Information = ({ data }) => {
  return (
    <section className="text-gray-600 body-font  max-w-7xl md:px-0 mx-auto">
      <div className="container px-5 py-10 mx-auto sm:overflow-x-auto ">
        {data.map((item, key) => {
          return (
            <InformationSection
              title={data.title}
              img={data[0].img}
              data={data}
              left={key % 2 === 0}
              id={data[0].id}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Information;

const InformationSection = ({ left, title, caption, data, id, img }) => {
  return (
    <div
      className={`flex flex-col  ${
        left ? "lg:flex-row" : "lg:flex-row-reverse"
      } justify-around items-center py-10`}
    >
      {id == 2 ? (
        <Faq2 />
      ) : (
        <>
          <div className="relative md:w-2/5 flex justify-center items-center ">
            <div className="absolute  lg:h-80 aspect-video bg-secondary right-1 -bottom-4 opacity-20"></div>
            <img
              className="h-full md:h-80 aspect-video z-10 object-cover"
              src={img}
              alt="Working"
            />
            <div
              className={`hidden md:block absolute -top-12 ${
                left ? "-left-12" : "-right-12"
              } z-10`}
            >
              <Dots className="h-32 w-32" />
            </div>
          </div>
          <div className="md:w-3/5 flex flex-col justify-center items-center space-y-8 py-10 sm:py-20 text-center">
            <div className="space-y-4 sm:space-y-1">
              <Text type="h2"></Text>
            </div>
            <div className="text-center px-4 space-y-4">
              {data[0].description.length > 0 ? (
                data[0].description.map((item, key) => {
                  return <Text>{item}</Text>;
                })
              ) : (
                <Text>{data[0].description}</Text>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
