import React from "react";

import * as Button from "components/Button";
import Text from "components/Text";
import Input from "components/Input";
import Divider from "components/Divider";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const insuranceSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Pflichtfeld *"),
  last_name: Yup.string().required("Pflichtfeld *"),
  insurence_number: Yup.string().required("Pflichtfeld *"),
  health_insurence: Yup.string().required("Pflichtfeld *"),
  street_number: Yup.string().required("Pflichtfeld *"),
  address: Yup.string().required("Pflichtfeld *"),
  house_number: Yup.string().required("Pflichtfeld *"),
  postal_code: Yup.string().required("Pflichtfeld *"),
  phone_number: Yup.string().matches(phoneRegExp, "Not Matched *"),
  dob: Yup.string().required("Pflichtfeld *"),
  email: Yup.string().email("Invalid email").required("Pflichtfeld *"),
});

const InsurenceStep = ({ goAhead, data }) => {
  const handleSubmit = (values) => {
    console.log("Insurance Values", values);
    goAhead(values);
  };
  return (
    <Formik
      validationSchema={insuranceSchema}
      initialValues={data}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="space-y-12 w-full lg:px-10 sm:px-5">
            <div className="">
              <Text type="h3">Versicherte(r)/Pflegebedürftige(r)</Text>
            </div>
            <section className="flex w-full text-left  relative flex-wrap">
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">Vorname *</Text>
                  <Field
                    name="first_name"
                    placeholder="Vorname *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="first_name" />
                  </h4>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">Nachname *</Text>
                  <Field
                    title="Nachname * "
                    name="last_name"
                    placeholder="Nachname * "
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="last_name" />
                  </h4>
                </div>
              </div>
              <div className="w-full md:w-full">
                <div className="relative space-y-4 m-2">
                  <Text type="h4"> Geburtsdatum *</Text>
                  <Field
                    type="date"
                    name="dob"
                    placeholder=" Geburtsdatum *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="dob" />
                  </h4>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">Straße *</Text>
                  <Field
                    name="street_number"
                    placeholder=" Straße *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="street_number" />
                  </h4>
                </div>
              </div>{" "}
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">Hausnummer *</Text>
                  <Field
                    name="house_number"
                    placeholder="Hausnummer *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="house_number" />
                  </h4>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">PLZ *</Text>
                  <Field
                    name="postal_code"
                    placeholder="PLZ *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="postal_code" />
                  </h4>
                </div>
              </div>
              <div className="w-full  md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">Ort *</Text>
                  <Field
                    name="address"
                    placeholder="Ort "
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="address" />
                  </h4>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">E-Mail-Adresse *</Text>
                  <Field
                    type="email"
                    name="email"
                    placeholder="E-Mail-Adresse *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />{" "}
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="email" />
                  </h4>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="relative space-y-4 m-2">
                  <Text type="h4">Telefonnummer * </Text>
                  <Field
                    type="tel"
                    name="phone_number"
                    placeholder="Telefonnummer *"
                    className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                  />
                  <h4 className="text-red-500 font-bold">
                    <ErrorMessage name="phone_number" />
                  </h4>
                  <svg
                    className="absolute w-4 h-4 right-4 top-9"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4809 14.4752C18.3172 14.4752 17.1747 14.2932 16.092 13.9354C15.5615 13.7544 14.9093 13.9205 14.5855 14.253L12.4485 15.8662C9.97015 14.5433 8.44354 13.0172 7.13867 10.5574L8.70442 8.47611C9.11122 8.06986 9.25713 7.47642 9.08232 6.9196C8.72299 5.83122 8.54045 4.68923 8.54045 3.52512C8.5405 2.68416 7.85634 2 7.01543 2H3.52507C2.68416 2 2 2.68416 2 3.52507C2 13.1641 9.84181 21.0059 19.4809 21.0059C20.3218 21.0059 21.0059 20.3217 21.0059 19.4808V16.0002C21.0059 15.1593 20.3217 14.4752 19.4809 14.4752Z"
                      fill="#A4A9A7"
                    />
                  </svg>
                </div>
              </div>
            </section>

            <section className="w-full text-left  relative space-y-10">
              <Text type="h3">Krankenversicherung</Text>
              <div className="w-full flex flex-wrap">
                <div className="w-full md:w-ful">
                  <div className="relative space-y-4 m-2">
                    <Text type="h4">Pflegekasse *</Text>
                    <Field
                      as="select"
                      name="health_insurence"
                      className="relative appearance-none w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg leading-tight"
                    >
                      <option value="" selected>
                        Auswählen Kranken/Pflegekasse
                      </option>
                      <option value="BARMER">BARMER</option>
                      <option value="DAK Gesundheit">DAK Gesundheit</option>
                      <option value="HEK - Hanseatische Krankenkasse">
                        HEK - Hanseatische Krankenkasse
                      </option>
                      <option value="hkk Krankenkasse">hkk Krankenkasse</option>
                      <option value="KKH Kaufmännische Krankenkasse">
                        KKH Kaufmännische Krankenkasse
                      </option>
                      <option value="KNAPPSCHAFT">KNAPPSCHAFT</option>
                      <option value="Techniker Krankenkasse (TK)">
                        Techniker Krankenkasse (TK)
                      </option>
                      <option value="BIG direkt gesund">
                        BIG direkt gesund
                      </option>
                      <option value="IKK Brandenburg und Berlin">
                        IKK Brandenburg und Berlin
                      </option>
                      <option value="IKK classic">IKK classic</option>
                      <option value="IKK gesund plus">IKK gesund plus</option>
                      <option value="IKK Nord">IKK Nord</option>
                      <option value="IKK Südwest">IKK Südwest</option>
                      <option value="AOK Baden-Württemberg">
                        AOK Baden-Württemberg
                      </option>
                      <option value="AOK Bayern">AOK Bayern</option>
                      <option value="AOK Bremen/Bremerhaven">
                        AOK Bremen/Bremerhaven
                      </option>
                      <option value="AOK Hessen">AOK Hessen</option>
                      <option value="AOK Niedersachsen">
                        AOK Niedersachsen
                      </option>
                      <option value="AOK Nordost">AOK Nordost</option>
                      <option value="AOK Nordwest">AOK Nordwest</option>
                      <option value="AOK PLUS">AOK PLUS</option>
                      <option value="AOK Rheinland-Pfalz/Saarland">
                        AOK Rheinland-Pfalz/Saarland
                      </option>
                      <option value="AOK Rheinland/Hamburg">
                        AOK Rheinland/Hamburg
                      </option>
                      <option value="AOK Sachsen-Anhalt">
                        AOK Sachsen-Anhalt
                      </option>
                      <option value="actimonda krankenkasse">
                        actimonda krankenkasse
                      </option>
                      <option value="atlas BKK ahlmann">
                        atlas BKK ahlmann
                      </option>
                      <option value="Audi BKK">Audi BKK</option>
                      <option value="BAHN-BKK">BAHN-BKK</option>
                      <option value="BERGISCHE Krankenkasse">
                        BERGISCHE Krankenkasse
                      </option>
                      <option value="Bertelsmann BKK">Bertelsmann BKK</option>
                      <option value="BKK Achenbach Buschhütten">
                        BKK Achenbach Buschhütten
                      </option>
                      <option value="BKK Akzo Nobel Bayern">
                        BKK Akzo Nobel Bayern
                      </option>
                      <option value="BKK Diakonie">BKK Diakonie</option>
                      <option value="BKK DürkoppAdler">BKK DürkoppAdler</option>
                      <option value="BKK EUREGIO">BKK EUREGIO</option>
                      <option value="BKK exklusiv">BKK exklusiv</option>
                      <option value="BKK Faber-Castell &amp; Partner">
                        BKK Faber-Castell &amp; Partner
                      </option>
                      <option value="BKK firmus">BKK firmus</option>
                      <option value="BKK Freudenberg">BKK Freudenberg</option>
                      <option value="BKK GILDEMEISTER SEIDENSTICKER">
                        BKK GILDEMEISTER SEIDENSTICKER
                      </option>
                      <option value="BKK HENSCHEL plus">
                        BKK HENSCHEL plus
                      </option>
                      <option value="BKK HERKULES">BKK HERKULES</option>
                      <option value="BKK HMR">BKK HMR</option>
                      <option value="BKK Linde">BKK Linde</option>
                      <option value="BKK Melitta Plus">BKK Melitta Plus</option>
                      <option value="BKK Mobil Oil">BKK Mobil Oil</option>
                      <option value="BKK PFAFF">BKK PFAFF</option>
                      <option value="BKK Pfalz">BKK Pfalz</option>
                      <option value="BKK ProVita">BKK ProVita</option>
                      <option value="BKK Public">BKK Public</option>
                      <option value="BKK SBH">BKK SBH</option>
                      <option value="BKK Scheufelen">BKK Scheufelen</option>
                      <option value="BKK Technoform">BKK Technoform</option>
                      <option value="BKK Textilgruppe Hof">
                        BKK Textilgruppe Hof
                      </option>
                      <option value="BKK VBU">BKK VBU</option>
                      <option value="BKK VDN">BKK VDN</option>
                      <option value="BKK VerbundPlus">BKK VerbundPlus</option>
                      <option value="BKK Werra-Meissner">
                        BKK Werra-Meissner
                      </option>
                      <option value="BKK WIRTSCHAFT &amp; FINANZEN">
                        BKK WIRTSCHAFT &amp; FINANZEN
                      </option>
                      <option value="BKK ZF &amp; Partner">
                        BKK ZF &amp; Partner
                      </option>
                      <option value="BKK24">BKK24</option>
                      <option value="Bosch BKK">Bosch BKK</option>
                      <option value="Brandenburgische BKK">
                        Brandenburgische BKK
                      </option>
                      <option value="Continentale BKK">Continentale BKK</option>
                      <option value="Debeka BKK">Debeka BKK</option>
                      <option value="energie-BKK">energie-BKK</option>
                      <option value="Heimat Krankenkasse">
                        Heimat Krankenkasse
                      </option>
                      <option value="mhplus Krankenkasse">
                        mhplus Krankenkasse
                      </option>
                      <option value="Novitas BKK">Novitas BKK</option>
                      <option value="pronova BKK">pronova BKK</option>
                      <option value="R+V Betriebskrankenkasse">
                        R+V Betriebskrankenkasse
                      </option>
                      <option value="Salus BKK">Salus BKK</option>
                      <option value="SBK">SBK</option>
                      <option value="Schwenninger Krankenkasse">
                        Schwenninger Krankenkasse
                      </option>
                      <option value="SECURVITA Krankenkasse">
                        SECURVITA Krankenkasse
                      </option>
                      <option value="SIEMAG BKK">SIEMAG BKK</option>
                      <option value="SKD BKK">SKD BKK</option>
                      <option value="TBK (Thüringer Betriebskrankenkasse)">
                        TBK (Thüringer Betriebskrankenkasse)
                      </option>
                      <option value="TUI BKK">TUI BKK</option>
                      <option value="VIACTIV Krankenkasse">
                        VIACTIV Krankenkasse
                      </option>
                      <option value="WMF BKK">WMF BKK</option>
                      <option value="Landwirtschaftliche Krankenkasse - LKK">
                        Landwirtschaftliche Krankenkasse - LKK
                      </option>
                      <option value="BKK Aesculap">BKK Aesculap</option>
                      <option value="BKK B. Braun">BKK B. Braun</option>
                      <option value="BKK BPW Bergische Achsen KG">
                        BKK BPW Bergische Achsen KG
                      </option>
                      <option value="BKK Deutsche Bank AG">
                        BKK Deutsche Bank AG
                      </option>
                      <option value="BKK evm">BKK evm</option>
                      <option value="BKK EWE">BKK EWE</option>
                      <option value="BKK GRILLO-WERKE AG">
                        BKK GRILLO-WERKE AG
                      </option>
                      <option value="BKK Groz-Beckert">BKK Groz-Beckert</option>
                      <option value="BKK KARL MAYER">BKK KARL MAYER</option>
                      <option value="BKK KBA">BKK KBA</option>
                      <option value="BKK MAHLE">BKK MAHLE</option>
                      <option value="BKK Merck">BKK Merck</option>
                      <option value="BKK Miele">BKK Miele</option>
                      <option value="BKK MTU Friedrichshafen GmbH">
                        BKK MTU Friedrichshafen GmbH
                      </option>
                      <option value="BKK PwC">BKK PwC</option>
                      <option value="BKK Rieker.Ricosta.Weisser">
                        BKK Rieker.Ricosta.Weisser
                      </option>
                      <option value="BKK RWE">BKK RWE</option>
                      <option value="BKK Salzgitter">BKK Salzgitter</option>
                      <option value="BKK Stadt Augsburg">
                        BKK Stadt Augsburg
                      </option>
                      <option value="BKK Voralb HELLER*INDEX*LEUZE">
                        BKK Voralb HELLER*INDEX*LEUZE
                      </option>
                      <option value="BKK Würth">BKK Würth</option>
                      <option value="BMW BKK">BMW BKK</option>
                      <option value="Daimler BKK">Daimler BKK</option>
                      <option value="Ernst &amp; Young BKK">
                        Ernst &amp; Young BKK
                      </option>
                      <option value="Krones BKK">Krones BKK</option>
                      <option value="Südzucker-BKK">Südzucker-BKK</option>
                      <option value="Wieland BKK">Wieland BKK</option>
                      <option value="BKK advita - jetzt: BKK24">
                        BKK advita - jetzt: BKK24
                      </option>
                      <option value="BKK AXEL SPRINGER - jetzt: DAK Gesundheit">
                        BKK AXEL SPRINGER - jetzt: DAK Gesundheit
                      </option>
                      <option value="BKK Basell - jetzt: BKK VBU">
                        BKK Basell - jetzt: BKK VBU
                      </option>
                      <option value="BKK Beiersdorf AG - jetzt: DAK Gesundheit">
                        BKK Beiersdorf AG - jetzt: DAK Gesundheit
                      </option>
                      <option value="BKK BJB - jetzt: BKK Gildemeister Seidensticker">
                        BKK BJB - jetzt: BKK Gildemeister Seidensticker
                      </option>
                      <option value="BKK Braun-Gillette - jetzt: pronova BKK">
                        BKK Braun-Gillette - jetzt: pronova BKK
                      </option>
                      <option value="BKK Demag Krauss-Maffei - jetzt: BKK VBU">
                        BKK Demag Krauss-Maffei - jetzt: BKK VBU
                      </option>
                      <option value="BKK ESSANELLE - jetzt: BARMER">
                        BKK ESSANELLE - jetzt: BARMER
                      </option>
                      <option value="BKK family - jetzt: BKK ProVita">
                        BKK family - jetzt: BKK ProVita
                      </option>
                      <option value="BKK futur - jetzt BKK VBU">
                        BKK futur - jetzt BKK VBU
                      </option>
                      <option value="BKK Gesundheit - jetzt: DAK Gesundheit">
                        BKK Gesundheit - jetzt: DAK Gesundheit
                      </option>
                      <option value="BKK Heimbach - jetzt: actimonda">
                        BKK Heimbach - jetzt: actimonda
                      </option>
                      <option value="BKK Kassana - jetzt: BKK VerbundPlus">
                        BKK Kassana - jetzt: BKK VerbundPlus
                      </option>
                      <option value="BKK MAN und MTU - jetzt: Audi BKK">
                        BKK MAN und MTU - jetzt: Audi BKK
                      </option>
                      <option value="BKK MEDICUS - jetzt: BKK VBU">
                        BKK MEDICUS - jetzt: BKK VBU
                      </option>
                      <option value="BKK MEM - jetzt Metzinger BKK">
                        BKK MEM - jetzt Metzinger BKK
                      </option>
                      <option value="BKK PHOENIX - jetzt: Novitas BKK">
                        BKK PHOENIX - jetzt: Novitas BKK
                      </option>
                      <option value="BKK S-H - jetzt: BKK VBU">
                        BKK S-H - jetzt: BKK VBU
                      </option>
                      <option value="BKK Schwesternschaft v. BRK - jetzt: BKK ProVita">
                        BKK Schwesternschaft v. BRK - jetzt: BKK ProVita
                      </option>
                      <option value="BKK Vital - jetzt: BKK Pfalz">
                        BKK Vital - jetzt: BKK Pfalz
                      </option>
                      <option value="DEUTSCHE BKK - jetzt: BARMER">
                        DEUTSCHE BKK - jetzt: BARMER
                      </option>
                      <option value="E.ON Betriebskrankenkasse - jetzt: energie-BKK">
                        E.ON Betriebskrankenkasse - jetzt: energie-BKK
                      </option>
                      <option value="ESSO BKK - jetzt: Novitas BKK">
                        ESSO BKK - jetzt: Novitas BKK
                      </option>
                      <option value="HEAG BKK - jetzt: Linde BKK">
                        HEAG BKK - jetzt: Linde BKK
                      </option>
                      <option value="Hypovereinsbank BKK - jetzt: BKK Mobil Oil">
                        Hypovereinsbank BKK - jetzt: BKK Mobil Oil
                      </option>
                      <option value="Metzinger BKK - jetzt: mhplus">
                        Metzinger BKK - jetzt: mhplus
                      </option>
                      <option value="SAINT-GOBAIN BKK">SAINT-GOBAIN BKK</option>
                      <option value="Shell BKK/LIFE - jetzt: DAK Gesundheit">
                        Shell BKK/LIFE - jetzt: DAK Gesundheit
                      </option>
                      <option value="Vaillant BKK - jetzt: pronova BKK">
                        Vaillant BKK - jetzt: pronova BKK
                      </option>
                      <option value="Vereinigte BKK - jetzt: BKK VBU">
                        Vereinigte BKK - jetzt: BKK VBU
                      </option>
                    </Field>
                    <h4 className="text-red-500 font-bold">
                      <ErrorMessage name="health_insurence" />
                    </h4>
                    <svg
                      className="absolute w-4 h-4 right-4 top-9"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 7.79998L11.8 15L4.59995 7.79997"
                        stroke="#ABABAB"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>{" "}
                </div>
                <div className="w-full md:w-full">
                  <div className="relative space-y-4 m-2">
                    <Text type="h4">Versicherten-Nr. *</Text>
                    <Field
                      title="Versicherten-Nr. *"
                      name="insurence_number"
                      placeholder="Versicherten-Nr. *"
                      className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                    />{" "}
                    <h4 className="text-red-500 font-bold">
                      <ErrorMessage name="insurence_number" />
                    </h4>
                  </div>
                </div>
              </div>
            </section>
            <Button.Primary type="submit">Weiter zu Schritt 02</Button.Primary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InsurenceStep;
