import React from 'react';

const Divider = ({ full }) => {
  if (full)
    return (
      <div className='w-full flex justify-center'>
        <div className='w-96 border-b-2 pb-10'></div>
      </div>
    );

  return (
    <div className='w-full flex justify-center'>
      <div className='w-64 border-b-2 pb-10'></div>
    </div>
  );
};

export default Divider;
