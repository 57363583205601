import React from "react";

import Listing from "components/Listing";
import BookMe from "components/common/BookMe";
import FirstAid from "assets/svg/firstaid";
import Text from "components/Text";

const FaqPage = () => {
  return (
    <React.Fragment>
      <div className="relative max-w-full mx-auto md:px-10 min-h-max pt-10 bg-light overflow-hidden flex flex-col items-center space-y-6">
        <div className="max-w-7xl md:flex-row  flex-col  px-4 md:px-20 lg:px-20 flex justify-start">
          <div className="lg:w-2/5 md:w-full sm:w-full flex">
            <img
              className="w-[600px]  h-[300px] left-0 bottom-0 mix-blend-multiply object-cover py-4 md:py-0 lg:py-0 "
              src="/wissenswertes.png"
              alt="doctor"
            />
          </div>
          <div className="lg:w-3/5 sm:w-full  flex flex-col items-center py-4 md:py-0 lg:py-0">
            <div className="space-y-6">
              <h2 className="font-[Poppins] font-bold text-4xl md:text-5xl text-center text-[#86A7FF]">
                Wissenswertes
              </h2>
              <p className="text-center text-lg mb-10">
                Erfahren Sie hier mehr über Pflegegrade und den Anspruch auf
                eine Pflegebox bei Pflegebox-Daheim.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative max-w-7xl md:px-20 mx-auto ">
        <svg
          width="189"
          height="173"
          className="hidden md:block absolute right-2 top-10 opacity-30"
          viewBox="0 0 189 173"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M72.7269 100.29L78.1081 60.0163C78.208 59.364 78.501 58.7564 78.9493 58.2721C79.3976 57.7877 79.9806 57.4487 80.6233 57.2986C81.2706 57.1592 81.9452 57.2229 82.555 57.4809C83.1648 57.7389 83.6802 58.1787 84.0309 58.7403L118.463 114.116L120.495 65.9867C120.519 65.3107 120.755 64.6595 121.171 64.1255C121.586 63.5915 122.159 63.2019 122.808 63.012C123.458 62.8567 124.139 62.8944 124.768 63.1205C125.397 63.3466 125.946 63.7513 126.348 64.285L140.076 84.5102L181.986 73.2804C188.161 62.0185 189.985 48.8809 187.111 36.3628C184.088 24.7609 176.885 14.685 166.885 8.0703C156.886 1.45556 144.795 -1.23166 132.935 0.524726C121.075 2.28111 110.282 8.35725 102.629 17.5862C94.9761 26.8152 91.0024 38.5463 91.4714 50.5264C85.8326 40.0164 76.5275 31.9454 65.326 27.8487C54.1245 23.7519 41.8079 23.9151 30.7189 28.3071C19.6298 32.6992 10.5418 41.0138 5.18339 51.6696C-0.175033 62.3253 -1.43008 74.5788 1.65692 86.0996C5.19414 97.458 12.4469 107.298 22.2499 114.038L72.7269 100.29Z"
            fill="#86A7FF"
          />
          <path
            d="M139.545 91.3171C138.901 91.4924 138.218 91.463 137.591 91.2329C136.964 91.0029 136.424 90.5836 136.046 90.0331L126.503 75.9732L124.441 124.822C124.41 125.51 124.161 126.171 123.73 126.71C123.3 127.248 122.71 127.636 122.045 127.818C121.377 127.995 120.67 127.949 120.031 127.687C119.391 127.426 118.855 126.963 118.502 126.368L83.2893 69.7357L78.9394 103.247C78.854 103.874 78.5857 104.462 78.1678 104.937C77.7499 105.413 77.2011 105.755 76.59 105.92L28.8763 118.705C42.1812 127.402 57.6593 134.096 69.7151 140.951C87.9622 151.346 106.052 162.316 124.456 172.136C138.331 148.469 152.307 124.686 166.015 101.063C169.809 94.4933 174.069 87.8423 177.848 81.0537L139.545 91.3171Z"
            fill="#86A7FF"
          />
        </svg>
      </div>
      <div className="relative max-w-full  ">
        <Listing />
      </div>

      <div className="relative py-4">
        <FirstAid className="hidden md:block absolute left-2 bottom-10" />
      </div>
      <div className="pb-10">
        <BookMe />
      </div>
    </React.Fragment>
  );
};

export default FaqPage;
