import React from "react";

const Text = ({ type, color, children }) => {
  if (type === "heading")
    return (
      <h2 className="text-xl sm:text-3xl font-bold text-primary">{children}</h2>
    );
  if (type === "heading-lg")
    return (
      <h2 className="text-2xl sm:text-4xl font-bold text-primary">
        {children}
      </h2>
    );

  if (type === "h1")
    return (
      <h1 className="text-3xl font-[Poppins] md:text-6xl text-center">
        {children}
      </h1>
    );
  if (type === "h2")
    return (
      <h2 className="font-[Poppins] font-bold text-2xl md:text-4xl text-center">
        {children}
      </h2>
    );
  if (type === "h3")
    return (
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800">
        {children}
      </h3>
    );
  if (type === "h4")
    return (
      <h3 className="font-[Poppins] font-bold text-lg text-gray-800">
        {children}
      </h3>
    );

  if (type === "caption")
    return (
      <h4 className="text-md sm:text-2xl font-semibold text-gray-500">
        {children}
      </h4>
    );
  if (type === "caption-bold")
    return (
      <h4 className="text-md sm:text-md font-bold text-gray-500 tracking-widest">
        {children}
      </h4>
    );

  if (type === "light")
    return <p className="text-lg sm:text-md text-gray-500">{children}</p>;

  if (type === "small")
    return (
      <p
        className={
          color ? "opacity-100	text-lg text-gray-700" : "opacity-30 text-lg"
        }
      >
        {children}
      </p>
    );

  return <p className="text-lg sm:text-md text-gray-700">{children}</p>;
};

export default Text;
