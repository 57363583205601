import React, { useEffect } from "react";

import * as Button from "components/Button";
import Text from "components/Text";
import Divider from "components/Divider";

import Writing from "assets/svg/writing";
import Checkup from "assets/svg/checkup";
import Heart from "assets/svg/heart";
import { Supplies } from "db/Supplies";
import { useSelector } from "react-redux";
import { Products } from "db/Products";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useState } from "react";
import ThankYouPage from "pages/Thankyou";
const privacyScHema = Yup.object().shape({
  privacy_acceptance: Yup.bool().oneOf([true], "Pflichtfeld *"),
});

const ConfirmationStep = ({ goBack, data }) => {
  const [success, setSuccess] = useState(false);
  const [btnclick, setBtnclick] = useState(false);
  const [productdesc, setProductdesc] = useState(null);
  const [customproduct, setCustomproduct] = useState(null);

  const cart = useSelector((state) => state.app.cart);
  const navigate = useNavigate();
  const product_Ids = [1, 2, 3, 4, 5, 6];

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  // eslint-disable-next-line
  let productId = query.get("name");
  let productSize = query.get("boxsize");
  // const Redirect = !product;

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  let birthday = new Date(data.dob);
  const sendProductData = async (values) => {
    try {
      const resp = await axios({
        method: "post",
        url: "https://fledgebox-app.herokuapp.com/product-details",
        data: values,
      });
      console.log(resp.data, "data", resp.status);
      if (resp.status == 200) {
        setSuccess(true);
      }
      if (resp.status !== 200) {
        setBtnclick(false);
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  const onSubmit = () => {
    data.product_name = query.get("name");
    data.product_size = query.get("boxsize");
    data.product_desc = productdesc && productdesc;
    let totalPrice = JSON.parse(localStorage.getItem("total"));
    let cart = JSON.parse(localStorage.getItem("cart"));
    data.custom_product = { price: totalPrice, items: cart };
    console.log(data, "========");
    sendProductData(data);
    setBtnclick(true);
  };
  // useEffect(() => {
  //   if (Redirect) navigate("/pflegebox-bestellen");
  // }, []);
  useEffect(() => {
    if (product_Ids.includes(Number(productId))) {
      const product = Products.find((item) => {
        return item.id == Number(productId);
      });
      console.log("Current Product*******", product);
      if (product) {
        setProductdesc(product);
      }
    }
    if (productId === "customize-box") {
      let totalPrice = JSON.parse(localStorage.getItem("total"));
      let cart = JSON.parse(localStorage.getItem("cart"));
      let custom_product = { price: totalPrice, items: cart };
      setCustomproduct(custom_product);
    }
  }, [productId]);
  console.log("productdesc=====", productdesc);
  console.log("custom_product=====", customproduct);
  // if (Redirect) return null;
  if (success) return <ThankYouPage />;
  return (
    <div className="space-y-14 w-full">
      <div className="space-y-2">
        <Text type="h4">Ihre ausgewählte Pflegebox</Text>
      </div>

      <section className="max-w-7xl md:px-10 mx-auto flex  text-left relative flex-wrap justify-between">
        <div className="h-full w-full md:w-1/2 border-2 border-blue-200 rounded overflow-hidden">
          <img
            className="h-80 w-full object-cover"
            // src={product.image}
            src="/box.png"
            alt="selected-box"
          />
        </div>

        <div className="w-full md:w-1/2 py-20 md:py-4 md:px-8 space-y-4 ">
          <Text type="h3">
            Pflegebox{" "}
            {query.get("name") === "customize-box"
              ? "'Individuell'"
              : query.get("name")}
          </Text>
          {customproduct &&
            customproduct.items.map((item) => {
              return (
                <p class="opacity-100	text-lg text-gray-700">
                  <b> {item.quantity}</b> x {item.title}
                </p>
              );
            })}
          {productdesc &&
            productdesc.checklist.map((item) => {
              return (
                <p class="opacity-100text-lg text-gray-700">
                  {item.active && item.name}
                  {item.name.includes("Einmalhandschuhe") && productSize && (
                    <b> ({productSize})</b>
                  )}
                </p>
              );
            })}
          {/* <CustomizerInfo data={cart.extra} /> */}
        </div>
        <Divider full />
      </section>

      <section
        className={
          !data.detail_cg && data.monthly_delivery !== "nursingService"
            ? "max-w-7xl md:px-10 mx-auto justify-center  text-left  relative flex w-full flex-wrap "
            : "max-w-7xl md:px-10 mx-auto justify-between relative text-center flex w-full flex-wrap "
        }
      >
        <Heart className="hidden md:block absolute top-4 left-[30%] opacity-25" />
        {/* Divider Vertical */}
        {!data.detail_cg && data.monthly_delivery !== "nursingService" ? (
          ""
        ) : (
          <div className="hidden md:block absolute right-[55%] top-60 h-96 border"></div>
        )}
        <div className="w-full md:w-1/2 space-y-14">
          <Text type="h4">Informationen zur versicherten Person:</Text>
          <div className="flex justify-center md:block">
            <Writing className="w-60" />
          </div>
          <div
            className={
              data.detail_cg == false &&
              data.monthly_delivery !== "nursingService"
                ? "w-full   md:w-full  space-y-6 "
                : "w-full  md:w-2/3 space-y-6"
            }
          >
            <div className="flex justify-between">
              <Text type="h4">Vorname :</Text>
              <Text type="light">{data.first_name}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">Nachname :</Text>
              <Text type="light">{data.last_name}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4"> Versicherten-Nr. :</Text>
              <Text type="light">{data.insurence_number}</Text>
            </div>{" "}
            <div className="flex justify-between">
              <Text type="h4"> Pflegekasse :</Text>
              <Text type="light">{data.health_insurence}</Text>
            </div>{" "}
            <div className="flex justify-between">
              <Text type="h4"> Straße :</Text>
              <Text type="light">{data.street_number}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">Hausnummer :</Text>
              <Text type="light">{data.house_number}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">PLZ :</Text>
              <Text type="light">{data.postal_code}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">Ort :</Text>
              <Text type="light">{data.address}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">Telefonnummer :</Text>
              <Text type="light">{data.phone_number}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">E-Mail-Adresse :</Text>
              <Text type="light">{data.email}</Text>
            </div>
            <div className="flex justify-between">
              <Text type="h4">Geburtsdatum :</Text>
              <Text type="light">
                {birthday.toLocaleDateString("de-De", options)}
              </Text>
            </div>
          </div>
        </div>
        {!data.detail_cg && data.monthly_delivery !== "nursingService" ? (
          ""
        ) : (
          <div className="w-full md:w-1/2 space-y-14 md:px-8">
            {data.detail_cg && (
              <Text type="h4">
                Informationen zur angehörigen oder pflegenden Person:
              </Text>
            )}
            <div className="flex justify-center md:block">
              <Checkup className="w-64" />
            </div>
            <div className="w-full md:w-2/3 space-y-6">
              {data.detail_cg && (
                <>
                  {" "}
                  <div className="flex justify-between">
                    {data.first_name_cg && (
                      <>
                        <Text type="h4">Vorname :</Text>
                        <Text type="light">{data.first_name_cg}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.last_name_cg && (
                      <>
                        <Text type="h4">Nachname :</Text>
                        <Text type="light">{data.last_name}</Text>{" "}
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.street_no_cg && (
                      <>
                        <Text type="h4"> Straße :</Text>
                        <Text type="light">{data.street_no_cg}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.house_no_cg && (
                      <>
                        <Text type="h4">Hausnummer :</Text>
                        <Text type="light">{data.house_no_cg}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.postal_code_cg && (
                      <>
                        <Text type="h4">PLZ :</Text>
                        <Text type="light">{data.postal_code_cg}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {" "}
                    {data.phone_cg && (
                      <>
                        <Text type="h4">Telefonnummer :</Text>
                        <Text type="light">{data.phone_cg}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.email_cg && (
                      <>
                        <Text type="h4">E-Mail-Adresse :</Text>
                        <Text type="light">{data.email_cg}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.address_cg && (
                      <>
                        <Text type="h4">Ort :</Text>
                        <Text type="light">{data.address_cg}</Text>
                      </>
                    )}
                  </div>
                </>
              )}

              {data.monthly_delivery === "nursingService" && (
                <>
                  <Text type="h4">Adresse des Pflegedienstes:</Text>
                  <div className="flex justify-between">
                    {data.name_ns && (
                      <>
                        <Text type="h4">Name des Pflegedienstes :</Text>
                        <Text type="light">{data.name_ns}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.street_no_ns && (
                      <>
                        <Text type="h4"> Straße :</Text>
                        <Text type="light">{data.street_no_ns}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.house_no_ns && (
                      <>
                        <Text type="h4">Hausnummer :</Text>
                        <Text type="light">{data.house_no_ns}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.postal_code_ns && (
                      <>
                        <Text type="h4">PLZ :</Text>
                        <Text type="light">{data.postal_code_ns}</Text>
                      </>
                    )}
                  </div>
                  <div className="flex justify-between">
                    {data.address_ns && (
                      <>
                        <Text type="h4">Ort :</Text>
                        <Text type="light">{data.address_ns}</Text>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <Divider />
      </section>
      <Formik
        validationSchema={privacyScHema}
        initialValues={data}
        onSubmit={onSubmit}
      >
        {({}) => (
          <Form>
            <section className="flex flex-col justify-center items-center space-y-6">
              <div className="flex space-x-2 justify-center items-center">
                <Field
                  type="checkbox"
                  className="mr-2"
                  name="privacy_acceptance"
                />
                <div className="w-1/2">
                  <p className="text-xs text-left mb-2 text-gray-500">
                    Hiermit stimme ich zu, dass pflegebox-daheim.de meine
                    personenbezogenen Informationen (auch gesundheitsbezogene),
                    v.a. Kontakt-, Statusinfos und vertragsbezogene
                    Informationen, für die Bearbeitung des Änderungsantrages (im
                    Rahmen der Belieferung mit Pflegemitteln) sowie für eine
                    etwaige Kontaktaufnahme in diesem Zusammenhang via E-Mail
                    verarbeitet.
                  </p>
                  <p className="text-xs text-left mb-2 text-gray-500">
                    Hinweis: Die Zustimmung ist freiwillig und kann jederzeit
                    mit Wirkung für die Zukunft per E-Mail an
                    kontakt@pflegebox-daheim.de widerrufen werden. Im Falle
                    eines Widerrufs können wir Ihnen gegenüber die
                    entsprechenden Serviceleistungen nicht mehr erbringen.
                    Weitere Informationen zum Datenschutz finden Sie&nbsp;
                    <b>
                      <Link to="/datenschutz">hier</Link>{" "}
                    </b>
                    .
                  </p>
                </div>
              </div>

              <h4 className="text-red-500 font-bold">
                <ErrorMessage name="privacy_acceptance" />
              </h4>
            </section>
            <Button.Secondary onClick={goBack}>Zurück</Button.Secondary>
            <button
              className={
                "m-2 p-3 px-7 bg-white border border-primary rounded-lg text-primary  hover:bg-primary hover:text-white " +
                (btnclick ? " px-10 " : "cursor-pointer")
              }
              onClick={() => (ErrorMessage ? "" : onSubmit)}
              disabled={btnclick ? true : false}
            >
              {btnclick ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#4B66AE"
                  />
                </svg>
              ) : (
                "Bestellung absenden"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConfirmationStep;

const CustomizerInfo = ({ data }) => {
  return (
    <div className="px-4 space-y-3 text-lg">
      {Supplies.map((item) => {
        if (!data[item.id]) return null;
        return (
          <div className="flex justify-between">
            <Text>{item.title}</Text>
            <Text>
              <span className="text-secondary">{data[item.id]}</span>
            </Text>
          </div>
        );
      })}
    </div>
  );
};
