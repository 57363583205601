import React from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <div className='w-full bg-gray-300 rounded-full h-8'>
      <div className='bg-secondary h-8 rounded-full' style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
