import React, { useState } from "react";
import Text from "./Text";
import * as Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProduct } from "app/slice/appSlice";

const Carebox = ({ product }) => {
  const cart = useSelector((state) => state.app.cart);
  const [currentRadioValue, setCurrentValue] = useState("S");

  const handleRadioChange = (e) => {
    setCurrentValue(e.target.value);
    console.log(e.target.value);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (id) => {
    dispatch(setProduct(product));
    navigate(
      id == 5
        ? "/checkout?name=" + `${id}`
        : "/checkout?name=" + `${id}` + "&boxsize=" + `${currentRadioValue}`
    );
  };

  return (
    <div className="max-w-xs p-4 px-6 shadow-md bg-white space-y-5 m-4 z-10">
      {/* <Text>
        <span className="text-secondary">{product.badge}</span>
      </Text> */}
      <Text type="h3">{product.title}</Text>
      <img className="w-full object-cover" src={product.image} alt="care-box" />
      {product.checklist.map((item, key) => {
        return (
          <div key={key} className="flex items-center">
            {item.active ? (
              <svg
                className="w-1/12 h-3"
                viewBox="0 0 13 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8991 1.11951L4.83745 9.08292L1.30664 5.10139"
                  stroke={item.active ? "#4B66AE" : "#A4A9A7"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                className="w-1/12 h-3"
                viewBox="0 0 13 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
            )}

            <div className="w-11/12 pl-2">
              <Text color={item.active} type="small">
                {item.name}
              </Text>
            </div>
          </div>
        );
      })}
      {product.size ? (
        <div className="flex items-center justify-around		">
          {product.size.map((item, key) => {
            return (
              <div>
                {" "}
                <input
                  type="radio"
                  name={item.name}
                  value={item.value}
                  checked={currentRadioValue === item.value}
                  onChange={handleRadioChange}
                />{" "}
                {item.value}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex items-center justify-around py-3	"></div>
      )}
      <div className="text-center">
        <Button.Secondary
          active={product.id === cart.product_id}
          onClick={() => {
            handleClick(product.id);
            window.localStorage.clear();
          }}
        >
          Box auswählen
        </Button.Secondary>
      </div>
    </div>
  );
};

export default Carebox;
