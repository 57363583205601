import React from "react";

import Container from "components/Container";
import Text from "components/Text";
import BookMe from "components/common/BookMe";
import * as Button from "components/Button";

import CurveLine from "assets/svg/curve";
import Dots from "assets/svg/dots";
import Heart from "assets/svg/heart";
import Doctors from "assets/svg/doctors";
import { Link } from "react-router-dom";
import FirstAid from "assets/svg/firstaid";

const ThankYouPage = () => {
  return (
    <div className="relative pt-6">
      <Heart className="absolute left-2 top-[40%] opacity-40 w-32" />
      <div className="max-w-7xl md:px-20 mx-auto flex">
        <div className="relative space-y-10 text-center pb-10 flex flex-col justify-center items-center">
          <Dots className="absolute  w-24 h-24 left-0 top-0 " />
          <FirstAid className="absolute w-24 h-24 top-0 right-0" />
          <div className="relative mx-auto max-w-max space-y-10 flex flex-col justify-center items-center">
            <div className="flex flex-col  items-center">
              <Text type="h2">
                <span className="text-secondary">Glückwunsch!</span>
              </Text>
              <CurveLine className="w-40" />
            </div>
            <Doctors className="w-80" />
            <div>
              <Text type="h3">Die Bestellung war erfolgreich.</Text>
            </div>
            <div className="text-justify px-30">
              <Text>
                Wir werden Ihren Auftrag schnellstmöglich bearbeiten. Eine
                Auftragsbestätigung senden wir Ihnen unverzüglich per E-Mail zu.
                Sollten Sie weitere Fragen haben, melden Sie sich gerne
                jederzeit über unser Kontakt-Formular bei uns.
              </Text>
            </div>
            <Link to="/">
              <Button.Primary>Zurück zur Startseite</Button.Primary>
            </Link>
          </div>
        </div>
      </div>

      {/* <BookMe /> */}
    </div>
  );
};

export default ThankYouPage;
