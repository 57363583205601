export const Supplies = [
  {
    id: 1,
    title: "Saugende Bettschutzeinlagen Einmalgebrauch 50 Stück ",
    image: "/Bettschutzeinlagen_transparent.png",
    price: "21.54",
    quantity: 1,
  },
  {
    id: 2,
    title: "Fingerlinge 100 Stück",
    image: "/fingerling.png",
    price: "5.64",
    quantity: 1,
  },
  {
    id: 4,
    title: "Mundschutz 50 Stück  ",
    image: "/new_Mundschutz.png",
    price: "7.18",
    quantity: 1,
  },
  {
    id: 5,
    title: "Schutzschürzen Einmalgebrauch 100 Stück",
    image: "/new_Schutzschurzen.png",
    price: "13.34",
    quantity: 1,
  },
  {
    id: 6,
    title: "Händedesinfektionsmittel 500 ml ",
    image: "/Händedesinfektion_transparent.png",
    price: "8.21",
    quantity: 1,
  },
  {
    id: 7,
    title: "Flächendesinfektionsmittel 500 ml ",
    image: "/Flächendesinfektion_transparent.png",
    price: "6.16",
    quantity: 1,
  },
  {
    id: 3,
    title: "Einmalhandschuhe 100 Stück S ",
    image: "/Einmalhandschuhe_transparent.png",
    price: "7.18",
    size: ["S"],
    quantity: 1,
  },
  {
    id: 8,
    title: "Einmalhandschuhe 100 Stück  M",
    image: "/Einmalhandschuhe_transparent.png",
    price: "7.18",
    size: ["M"],
    quantity: 1,
  },
  {
    id: 9,
    title: "Einmalhandschuhe 100 Stück  L",
    image: "/Einmalhandschuhe_transparent.png",
    price: "7.18",
    size: ["L"],
    quantity: 1,
  },
  {
    id: 10,
    title: "Einmalhandschuhe 100 Stück XL ",
    image: "/Einmalhandschuhe_transparent.png",
    price: "7.18",
    size: ["XL"],
    quantity: 1,
  },
];
