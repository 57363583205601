import CurveLine from "assets/svg/curve";
import React from "react";
import { Link } from "react-router-dom";
import { Primary } from "./Button";

import Text from "./Text";

const Block = ({ title, description, src, link }) => {
  return (
    <div className="flex flex-col md:flex-row  flex justify-center items-center  relative overflow-hidden">
      {/* <div className="hidden md:block md:w-1/2 h-96 z-10">
        <img className="w-full h-full object-contain" src={src} alt={title} />
      </div> */}
      <div className="md:w-1/2 p-10 flex justify-center items-center pt-20 z-10">
        <div className="max-w-lg text-center space-y-8">
          <div className="relative">
            <Text type="h2">
              <span className="text-secondary">Rückruf</span> vereinbaren
            </Text>
            <CurveLine className="absolute w-24 left-28" />
          </div>
          <Text>{description}</Text>
          <div>
            <Link to={link}>
              <Primary>Kontakt aufnehmen</Primary>
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-light h-full w-full absolute top-10 left-0"></div>
    </div>
  );
};

export default Block;
