import React from 'react';

const Logo = (props) => {
  return (
    <svg {...props} viewBox='0 0 76 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M60.7555 16.9611L55.7228 11.1116L54.7111 9.93657L37.6674 0L20.5718 9.98766L14.5792 16.9101L19.7676 20.5117V36.1191L37.6674 46.26L55.619 36.068V20.5373L60.7555 16.9611ZM54.763 11.5714L56.5529 13.6404L59.2509 16.7568L56.4751 18.6981L43.2967 27.8173L38.4456 21.9167L43.4005 18.7748L53.7512 12.2355L54.763 11.5714ZM37.6933 1.17502L54.1144 10.7795L43.7377 17.3443L37.6933 21.1759L21.2722 10.7795L37.6933 1.17502ZM16.0838 16.7312L20.5718 11.5458L36.8891 21.9167L32.038 27.8173L16.0838 16.7312ZM48.7445 38.8267L44.3084 41.33V33.7946L48.7445 31.1125V38.8267ZM49.7562 38.2392V29.2988L43.2708 33.2071V41.9175L38.1862 44.804V36.9365C38.1862 36.6555 37.9527 36.4256 37.6674 36.4256C37.5376 36.4256 37.4079 36.4767 37.3042 36.5788C37.2004 36.681 37.1485 36.8087 37.1485 36.9365V44.804L20.7793 35.506V21.227L32.2455 29.1967L37.6414 22.5808L43.0632 29.1456L54.5554 21.227V35.4805L49.7562 38.2392Z'
        fill='black'
      />
      <path
        d='M38.1863 33.6413V34.1522C38.1863 34.4331 37.9528 34.663 37.6675 34.663C37.3821 34.663 37.1486 34.4331 37.1486 34.1522V33.6413C37.1486 33.3603 37.3821 33.1304 37.6675 33.1304C37.9528 33.1304 38.1863 33.3603 38.1863 33.6413Z'
        fill='black'
      />
      <path
        d='M38.1863 30.857V31.0358C38.1863 31.3168 37.9528 31.5467 37.6675 31.5467C37.3821 31.5467 37.1486 31.3168 37.1486 31.0358V30.857C37.1486 30.576 37.3821 30.3461 37.6675 30.3461C37.9528 30.3461 38.1863 30.576 38.1863 30.857Z'
        fill='black'
      />
      <path
        d='M8.04193 37.3196L6.48543 34.9185L0 39.0055L0.726368 40.155L2.672 38.9289L2.56823 39.4142L3.19083 40.3849C3.6059 41.049 4.2285 41.4577 5.00675 41.6365C5.24023 41.6876 5.44776 41.7132 5.65529 41.7132C6.20007 41.7132 6.69296 41.5599 7.18586 41.2534C7.86034 40.8447 8.27541 40.2316 8.457 39.4653C8.61265 38.699 8.48294 37.9838 8.04193 37.3196ZM7.08209 39.1588C7.00426 39.5675 6.77079 39.874 6.40761 40.0784C6.04442 40.3082 5.68124 40.3593 5.26617 40.2827C4.8511 40.2061 4.5398 39.9762 4.33227 39.6186L3.52807 38.3669L6.07036 36.7832L6.87456 38.0348C7.10803 38.3925 7.18586 38.7501 7.08209 39.1588Z'
        fill='black'
      />
      <path
        d='M11.6738 44.0632L14.5274 46.7198L15.4613 45.7235L11.6219 42.1729L6.32977 47.6904L7.3415 48.61L9.49466 46.3366L11.8035 48.4823L13.7491 48.4056L10.4545 45.3659L11.6738 44.0632Z'
        fill='black'
      />
      <path d='M17.9517 47.5882L14.1123 54.153L18.237 56.5286L18.9375 55.3536L15.9801 53.6677L19.145 48.2779L17.9517 47.5882Z' fill='black' />
      <path
        d='M29.6254 54.0253L30.0145 52.7225L24.9559 51.241L22.7768 58.521L27.8354 60.0026L28.2246 58.6998L24.4889 57.627L24.9818 55.9155L28.017 56.8096L29.7292 55.89L25.371 54.6383L25.8898 52.9269L29.6254 54.0253Z'
        fill='black'
      />
      <path
        d='M36.7335 53.4633C35.6699 53.4122 34.736 53.7699 33.9577 54.4595C33.1795 55.1748 32.7644 56.0433 32.7125 57.0906C32.6606 58.1379 33.0238 59.0574 33.7243 59.8238C34.4506 60.5901 35.3326 60.9988 36.3963 61.0499L38.7829 61.152L38.9645 56.733L35.0213 56.5797L36.3444 57.9846L37.5377 58.0357L37.4599 59.7471L36.4481 59.696C35.7477 59.6705 35.2029 59.4151 34.736 58.9042C34.269 58.3933 34.0615 57.8313 34.0874 57.1417C34.1134 56.452 34.3728 55.9155 34.8916 55.4302C35.4105 54.9704 35.9812 54.7661 36.6816 54.7916L39.0683 54.8938L39.1201 53.54L36.7335 53.4633Z'
        fill='black'
      />
      <path
        d='M45.398 59.0319L45.0088 57.2949L48.07 56.6053L49.1076 54.996L44.6975 55.9922L44.2825 54.2552L48.0959 53.4123L47.7846 52.084L42.6481 53.2335L44.3603 60.6412L49.4967 59.4917L49.1854 58.1634L45.398 59.0319Z'
        fill='black'
      />
      <path
        d='M58.4467 53.259C58.1613 52.7226 57.7203 52.365 57.1496 52.1606C57.3312 51.5731 57.2793 51.0111 56.994 50.4747C56.7086 49.9383 56.2676 49.5551 55.6709 49.3763C55.0743 49.1975 54.5036 49.2231 53.9328 49.504L50.8198 51.0622L54.3479 57.8058L57.4609 56.2476C58.0057 55.9666 58.3948 55.5324 58.5764 54.9449C58.758 54.3829 58.7321 53.821 58.4467 53.259ZM57.2793 54.5617C57.2015 54.7916 57.0718 54.9704 56.8383 55.0726L54.9446 56.0177L52.6876 51.6753L54.5814 50.7301C54.8149 50.628 55.0224 50.6024 55.2559 50.6791C55.4893 50.7557 55.6709 50.8834 55.7747 51.1133C55.9044 51.3432 55.9044 51.5475 55.8266 51.7774C55.7488 52.0073 55.619 52.1861 55.3856 52.2883L53.881 53.0546L55.3856 53.821L56.0341 53.4889C56.2676 53.3867 56.4751 53.3612 56.7086 53.4378C56.9421 53.5144 57.1237 53.6421 57.2274 53.872C57.3312 54.1019 57.3571 54.3063 57.2793 54.5617Z'
        fill='black'
      />
      <path
        d='M66.774 44.5485C66.0476 43.7822 65.1397 43.3735 64.0761 43.348C63.0125 43.3224 62.0785 43.6545 61.3262 44.3697C60.548 45.0849 60.1329 45.979 60.107 47.0263C60.081 48.0736 60.4183 48.9931 61.1446 49.7339C61.871 50.5002 62.779 50.9089 63.8426 50.9345C63.8945 50.9345 63.9464 50.9345 63.9982 50.9345C64.984 50.9345 65.866 50.5769 66.6183 49.9127C67.0075 49.5551 67.2928 49.1464 67.5004 48.7122C67.7079 48.2779 67.8117 47.7926 67.8376 47.2562C67.8635 46.2089 67.5004 45.3148 66.774 44.5485ZM65.6585 48.9165C65.1397 49.3763 64.569 49.6062 63.8945 49.5807C63.194 49.5551 62.6493 49.2997 62.1564 48.8143C61.6894 48.329 61.4559 47.7415 61.4819 47.0774C61.5078 46.3877 61.7672 45.8513 62.2601 45.3659C62.753 44.9317 63.2978 44.7018 63.9464 44.7018C63.9723 44.7018 63.9982 44.7018 64.0501 44.7018C64.7505 44.7273 65.2953 44.9828 65.7882 45.4681C66.2552 45.9534 66.4886 46.5409 66.4627 47.2051C66.4368 47.8948 66.1773 48.4567 65.6585 48.9165Z'
        fill='black'
      />
      <path
        d='M75.1791 39.2609L70.6133 39.2099L68.6937 35.1484L67.8116 36.5022L69.1087 39.2099L66.0476 39.1843L65.1915 40.5381L69.7313 40.5637L71.677 44.6252L72.533 43.2969L71.2619 40.5637L74.2971 40.5892L75.1791 39.2609Z'
        fill='black'
      />
      <path
        d='M15.4612 61.9439L16.9139 62.7103C18.0035 63.2722 18.3667 64.3961 17.7959 65.4945C17.2512 66.5929 16.1357 66.9505 15.0461 66.3886L13.5934 65.6222L15.4612 61.9439ZM15.3055 65.8011C16.0578 66.1842 16.7842 65.9543 17.1733 65.188C17.5625 64.4217 17.329 63.6809 16.5767 63.2978L15.7725 62.8635L14.5013 65.3924L15.3055 65.8011Z'
        fill='black'
      />
      <path
        d='M25.3451 69.4027L23.5291 68.8919L22.9065 69.7093L22.2061 69.5049L24.93 66.0054L25.5785 66.1842L26.0974 70.6289L25.3969 70.4245L25.3451 69.4027ZM25.2672 68.7641L25.0597 66.8228L23.8923 68.3554L25.2672 68.7641Z'
        fill='black'
      />
      <path
        d='M35.7736 67.819L35.4623 71.9316L34.7619 71.8805L34.8916 70.118L32.946 69.9647L32.8163 71.7272L32.1158 71.6762L32.4271 67.5636L33.1276 67.6147L32.9979 69.3261L34.9435 69.4794L35.0732 67.7679L35.7736 67.819Z'
        fill='black'
      />
      <path
        d='M44.7494 70.8332L44.8273 71.4718L42.0255 71.7783L41.5586 67.6658L44.2825 67.3593L44.3603 67.9978L42.3368 68.2277L42.4666 69.3006L44.2565 69.0962L44.3344 69.7348L42.5444 69.9392L42.6741 71.0631L44.7494 70.8332Z'
        fill='black'
      />
      <path d='M49.834 66.1587L50.5085 65.9543L51.624 69.9136L50.9495 70.118L49.834 66.1587Z' fill='black' />
      <path
        d='M60.8852 66.082L59.6919 63.6298L59.5881 66.2098L59.3027 66.363L57.2274 64.9326L58.4207 67.3592L57.824 67.6658L56.0081 63.9619L56.527 63.7065L59.0693 65.469L59.173 62.3526L59.6919 62.0972L61.5078 65.8011L60.8852 66.082Z'
        fill='black'
      />
    </svg>
  );
};

export default Logo;
