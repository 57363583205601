import React from 'react';

import Text from 'components/Text';

const ComingSoonPage = () => {
  return (
    <div className='flex flex-1 justify-center items-center h-screen -mt-40'>
      <Text type={'heading-lg'}>Coming Soon</Text>
    </div>
  );
};

export default ComingSoonPage;
