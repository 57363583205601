import Logo from "assets/svg/logo";
import React from "react";
import { Link } from "react-router-dom";
import Container from "./Container";
import JsCookie from "./JsCookie";
import Text from "./Text";

const DEMO = "https://www.instagram.com/pflegebox_daheim/?hl=de";

const HyperLinks = [
  {
    title: "About Us",
    children: [
      { title: "Our Story", href: "#" },
      { title: "Investor Relations", href: "#" },
      { title: "Press", href: "#" },
      { title: "Careers", href: "#" },
      { title: "Blog", href: "#" },
    ],
  },
  {
    title: "Our Experience",
    children: [
      { title: "Oscar App", href: "#" },
      { title: "Your Care Team", href: "#" },
      { title: "Virtual Urgent Care", href: "#" },
    ],
  },
  {
    title: "For Business",
    children: [
      { title: "Employers", href: "#" },
      { title: "Health Plans", href: "#" },
      { title: "Consultants", href: "#" },
    ],
  },
  {
    title: "Departments",
    children: [
      { title: "Rehabilitation", href: "#" },
      { title: "Laboratory Analysis", href: "#" },
      { title: "Face Lift Surgery", href: "#" },
      { title: "Liposuction", href: "#" },
    ],
  },
  {
    title: "Providers",
    children: [
      { title: "Partner with Us", href: "#" },
      { title: "Provider Experience", href: "#" },
      { title: "Provider Center", href: "#" },
      { title: "Frequently Asked Questions", href: "#" },
    ],
  },
];

const Footer = () => {
  return (
    <React.Fragment>
      <div className="max-w-full">
        <footer className="max-w-7xl mx-auto px-4 md:px-20 text-gray-600 body-font py-5 space-y-20">
          {/* Brand Information */}
          <div className="flex flex-col md:flex-row sm:justify-between space-y-10">
            <div className="flex flex-col justify-center items-center space-y-4">
              <Logo className="h-24 w-24" />
              <div className="inline-flex justify-center">
                <a href={DEMO} className="ml-3 text-gray-700">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="text-center md:text-right space-y-4">
              <Text type="h4">So können Sie uns erreichen</Text>
              <p className="text-lg text-gray-400">
                Bei Fragen oder Anmerkungen helfen wir Ihnen gerne weiter!
              </p>
              <p className="text-lg text-gray-400">
                Kundenservice: 040 65908570
              </p>
              <p className="text-lg text-gray-400">
                <span className="mr-1 ">E-Mail:</span>{" "}
                kontakt@pflegebox-daheim.de
              </p>
              <p className="text-lg text-gray-400">
                Kundenservice erreichbar von 09:00 bis 16:00 Uhr
              </p>
            </div>
          </div>
          {/* Listing */}
          {/* <div className="flex flex-wrap md:justify-evenly">
            {HyperLinks.map((item, key) => {
              return (
                <div
                  key={key}
                  className="w-full md:max-w-max space-y-2 m-4 flex flex-col items-center md:block"
                >
                  <Text type="h4">{item.title}</Text>
                  <DisplayLinks links={item.children} />
                </div>
              );
            })}
          </div> */}
          <div className=" lg:flex sm:flex-col md:flex-row justify-between  text-center ">
            <div>
              {" "}
              <Text type="light">© Alle Rechte vorbehalten – 2022.</Text>
            </div>
            <div className="flex gap-2  justify-center	">
              {" "}
              <Text type="light">
                <Link to="/datenschutz">Datenschutz</Link>
              </Text>
              <Text type="light">
                <Link to="/impressum">Impressum</Link>
              </Text>
            </div>
          </div>
        </footer>
      </div>
      <JsCookie />
    </React.Fragment>
  );
};

export default Footer;

const DisplayLinks = ({ links }) => {
  return links.map((item, key) => {
    return (
      <div key={key}>
        <Link to="#">
          <Text type="light">{item.title}</Text>
        </Link>
      </div>
    );
  });
};
