const CART_KEY = "x-cart";

export const setLocalCart = (payload) => {
  localStorage.setItem(CART_KEY, JSON.stringify(payload));
};
export const clearLocalCart = () => localStorage.removeItem(CART_KEY);
export const getLocalCart = () => {
  let payload = localStorage.getItem(CART_KEY);
  return payload ? JSON.parse(payload) : null;
};
