import React from 'react';

const Doctors = (props) => {
  return (
    <svg {...props} viewBox='0 0 440 418' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M435.846 220.785H431.752V220.084C431.749 218.172 430.988 216.339 429.636 214.987C428.284 213.635 426.451 212.874 424.539 212.872H421.767C419.853 212.872 418.017 213.631 416.663 214.984C415.309 216.336 414.547 218.17 414.544 220.084V220.785H410.459C409.914 220.783 409.373 220.89 408.868 221.098C408.364 221.306 407.905 221.612 407.519 221.998C407.133 222.384 406.828 222.843 406.619 223.347C406.411 223.852 406.304 224.393 406.306 224.938V243.863C406.306 244.964 406.743 246.021 407.522 246.8C408.301 247.579 409.358 248.016 410.459 248.016H435.846C436.947 248.014 438.002 247.575 438.781 246.797C439.559 246.018 439.997 244.963 440 243.863V224.938C440 223.837 439.562 222.78 438.783 222.001C438.004 221.222 436.948 220.785 435.846 220.785ZM416.518 220.084C416.52 218.694 417.074 217.361 418.058 216.379C419.043 215.397 420.376 214.845 421.767 214.845H424.539C425.929 214.845 427.261 215.397 428.244 216.379C429.226 217.362 429.778 218.695 429.778 220.084V220.785H416.518V220.084Z'
        fill='#313131'
      />
      <path d='M430.715 232.467H425.486V227.247H420.809V232.467H415.58V237.153H420.809V242.373H425.486V237.153H430.715V232.467Z' fill='white' />
      <path
        d='M427.756 108.01C426.942 108.01 426.137 107.849 425.386 107.538C424.635 107.226 423.952 106.77 423.378 106.194C422.803 105.619 422.348 104.936 422.037 104.184C421.727 103.432 421.568 102.627 421.569 101.813V85.1192C421.568 84.306 421.727 83.5004 422.037 82.7487C422.348 81.997 422.803 81.3139 423.378 80.7384C423.952 80.1629 424.635 79.7063 425.386 79.3948C426.137 79.0833 426.942 78.923 427.756 78.923C428.569 78.923 429.374 79.0833 430.125 79.3948C430.877 79.7063 431.559 80.1629 432.134 80.7384C432.708 81.3139 433.164 81.997 433.474 82.7487C433.784 83.5004 433.943 84.306 433.942 85.1192V101.784C433.947 102.6 433.791 103.408 433.482 104.163C433.174 104.918 432.719 105.605 432.144 106.184C431.569 106.762 430.885 107.222 430.132 107.535C429.379 107.848 428.571 108.01 427.756 108.01Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M427.756 108.01C426.942 108.01 426.137 107.849 425.386 107.538C424.635 107.226 423.952 106.77 423.378 106.194C422.803 105.619 422.348 104.936 422.037 104.184C421.727 103.432 421.568 102.627 421.569 101.813V93.4762H433.942V101.813C433.943 102.627 433.784 103.432 433.474 104.184C433.164 104.936 432.708 105.619 432.134 106.194C431.559 106.77 430.877 107.226 430.125 107.538C429.374 107.849 428.569 108.01 427.756 108.01Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M396.636 83.4221C396.128 82.7868 395.75 82.0572 395.524 81.2753C395.298 80.4934 395.229 79.6745 395.321 78.8659C395.412 78.0572 395.663 77.2746 396.058 76.5631C396.453 75.8515 396.985 75.2251 397.623 74.7198L410.617 64.281C411.904 63.2581 413.544 62.7875 415.177 62.9725C416.811 63.1575 418.304 63.9829 419.329 65.2676C420.352 66.5528 420.822 68.1912 420.637 69.823C420.452 71.4547 419.627 72.9463 418.343 73.9699L405.339 84.3989C404.703 84.9069 403.974 85.2846 403.193 85.5105C402.411 85.7364 401.593 85.8059 400.785 85.7152C399.977 85.6245 399.194 85.3753 398.482 84.9818C397.77 84.5883 397.143 84.0583 396.636 83.4221Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M396.636 83.4221C396.128 82.7868 395.75 82.0572 395.524 81.2753C395.298 80.4934 395.229 79.6746 395.321 78.8659C395.412 78.0572 395.663 77.2746 396.058 76.5631C396.453 75.8515 396.985 75.2251 397.623 74.7198L404.125 69.5004L411.87 79.1598L405.368 84.3693C404.734 84.8836 404.004 85.2671 403.221 85.498C402.438 85.7288 401.617 85.8023 400.805 85.7142C399.993 85.6262 399.207 85.3783 398.492 84.9849C397.776 84.5915 397.146 84.0604 396.636 83.4221Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M334.763 37.9964C333.991 37.9968 333.239 38.2383 332.612 38.6871L318.236 27.528C318.941 26.224 319.272 24.7507 319.191 23.2707C319.111 21.7907 318.624 20.3617 317.783 19.1414L326.564 7.91322C327.307 8.19979 328.122 8.24091 328.891 8.03059C329.659 7.82026 330.339 7.36952 330.833 6.74437C331.326 6.11922 331.607 5.35248 331.633 4.55644C331.659 3.76039 331.43 2.97684 330.979 2.3205C330.528 1.66417 329.878 1.16953 329.126 0.909001C328.373 0.648475 327.557 0.63575 326.796 0.872688C326.036 1.10963 325.371 1.58378 324.9 2.22573C324.428 2.86768 324.175 3.64371 324.176 4.44019C324.172 5.20779 324.41 5.95717 324.857 6.58124L316.312 17.5233C315.167 16.5584 313.775 15.932 312.293 15.7141C310.811 15.4963 309.297 15.6956 307.922 16.2899C306.547 16.8842 305.365 17.8498 304.508 19.0785C303.651 20.3071 303.154 21.7502 303.071 23.2459H293.087C292.977 22.3596 292.554 21.5418 291.893 20.9412C291.232 20.3407 290.378 19.9971 289.485 19.9731C288.593 19.9491 287.721 20.2462 287.029 20.8103C286.336 21.3745 285.87 22.1683 285.713 23.0475C285.556 23.9266 285.721 24.8328 286.175 25.6012C286.63 26.3697 287.346 26.9494 288.192 27.235C289.038 27.5206 289.959 27.4932 290.786 27.1577C291.614 26.8221 292.293 26.2008 292.702 25.4067H303.259C303.568 26.8413 304.262 28.1645 305.267 29.234C306.271 30.3036 307.549 31.0789 308.961 31.4767C310.374 31.8745 311.868 31.8796 313.283 31.4916C314.698 31.1035 315.981 30.337 316.993 29.2744L331.29 40.394C331.008 41.1357 330.97 41.9481 331.183 42.7126C331.396 43.4771 331.847 44.1536 332.471 44.6432C333.096 45.1329 333.861 45.41 334.654 45.4341C335.447 45.4582 336.227 45.228 336.88 44.7772C337.533 44.3264 338.025 43.6786 338.283 42.9284C338.542 42.1783 338.554 41.365 338.317 40.6076C338.081 39.8501 337.608 39.1882 336.969 38.7184C336.329 38.2486 335.556 37.9956 334.763 37.9964Z'
        fill='#313131'
      />
      <path
        d='M42.7424 108.128C41.5474 108.125 40.402 107.65 39.5569 106.805C38.7119 105.959 38.236 104.814 38.2334 103.619V66.126H47.2515V103.619C47.2515 104.815 46.7764 105.962 45.9308 106.807C45.0852 107.653 43.9383 108.128 42.7424 108.128Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M42.7424 108.128C41.5474 108.125 40.402 107.65 39.5569 106.805C38.7119 105.959 38.236 104.814 38.2334 103.619V80.5608H47.2515V103.619C47.2515 104.815 46.7764 105.962 45.9308 106.807C45.0852 107.653 43.9383 108.128 42.7424 108.128Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M48.5434 63.9357H36.9502V67.2212H48.5434V63.9357Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M29.0774 108.128C27.8823 108.125 26.7369 107.65 25.8919 106.805C25.0469 105.959 24.571 104.814 24.5684 103.619V66.126H33.5864V103.619C33.5864 104.815 33.1114 105.962 32.2657 106.807C31.4201 107.653 30.2733 108.128 29.0774 108.128Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M29.0774 108.128C27.8823 108.125 26.7369 107.65 25.8919 106.805C25.0469 105.959 24.571 104.814 24.5684 103.619V80.5608H33.5864V103.619C33.5864 104.815 33.1114 105.962 32.2657 106.807C31.4201 107.653 30.2733 108.128 29.0774 108.128Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M34.8784 63.9357H23.2852V67.2212H34.8784V63.9357Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M159.167 26.9754L162.759 18.3521C162.82 18.2131 162.921 18.0957 163.05 18.0148C163.178 17.9339 163.328 17.8933 163.479 17.8982C163.631 17.9058 163.777 17.96 163.897 18.0536C164.017 18.1472 164.106 18.2755 164.15 18.4211L168.501 32.7671L171.816 22.2C171.862 22.0513 171.953 21.9208 172.077 21.8269C172.201 21.7331 172.352 21.6806 172.507 21.6771C172.661 21.6813 172.81 21.7302 172.936 21.8178C173.062 21.9054 173.16 22.028 173.218 22.1704L175.063 27.4786H185.038C187.079 25.3452 188.266 22.5363 188.373 19.5854C188.392 16.8291 187.391 14.1629 185.565 12.099C183.738 10.0351 181.212 8.7189 178.474 8.40323C175.736 8.08756 172.978 8.79467 170.729 10.3887C168.48 11.9828 166.9 14.3515 166.291 17.0398C165.664 14.3703 164.078 12.0243 161.834 10.4481C159.591 8.87177 156.846 8.17513 154.122 8.49064C151.398 8.80615 148.885 10.1118 147.061 12.1593C145.237 14.2067 144.229 16.8532 144.229 19.5952C144.339 22.3281 145.364 24.9447 147.14 27.0248L159.167 26.9754Z'
        fill='#313131'
      />
      <path
        d='M174.54 28.9586C174.386 28.9592 174.236 28.9121 174.111 28.8237C173.985 28.7353 173.89 28.61 173.839 28.4653L172.556 24.7752L169.192 35.5002C169.144 35.6512 169.049 35.7832 168.922 35.8772C168.794 35.9711 168.64 36.0222 168.482 36.0231C168.323 36.0226 168.168 35.9703 168.042 35.8741C167.916 35.778 167.824 35.6432 167.781 35.4903L163.331 20.8187L160.371 28.0016C160.315 28.1358 160.22 28.2504 160.099 28.3312C159.978 28.4119 159.836 28.4551 159.69 28.4554H148.334C150.771 31.1786 153.81 33.5861 156.079 35.8258C159.513 39.2199 162.877 42.7324 166.38 46.0081C170.869 41.578 175.388 37.1282 179.838 32.6981C181.071 31.4647 182.413 30.2413 183.656 28.9586H174.54Z'
        fill='#313131'
      />
      <path d='M8.09855 249.49L12.9365 251.168L18.1714 236.076L13.3334 234.398L8.09855 249.49Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M5.80576 251.886L13.2725 254.476L14.2587 251.633L6.79196 249.043L5.80576 251.886Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M7.99922 238.282L19.959 242.43L29.4426 215.09L17.4828 210.941L7.99922 238.282Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M13.8139 226.586L22.6416 229.648L27.8765 214.556L19.0488 211.494L13.8139 226.586Z' fill='#313131' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M12.2528 237.526L17.0908 239.204L22.3257 224.112L17.4877 222.434L12.2528 237.526Z' fill='#313131' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M7.15467 237.995L20.8203 242.735L22.007 239.314L8.34133 234.574L7.15467 237.995Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M20.3987 210.371L27.5205 212.842L29.1016 208.283L21.9798 205.813L20.3987 210.371Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M16.3392 211.507L30.0049 216.247L31.1915 212.826L17.5259 208.086L16.3392 211.507Z' fill='white' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path d='M25.6133 206.429L31.3359 189.912' stroke='#313131' stroke-width='0.50439' stroke-miterlimit='10' />
      <path
        d='M343.79 411.703C372.305 411.703 395.422 406.49 395.422 400.06C395.422 393.63 372.305 388.418 343.79 388.418C315.275 388.418 292.158 393.63 292.158 400.06C292.158 406.49 315.275 411.703 343.79 411.703Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M303.979 136.899C303.979 136.899 296.993 141.171 297.072 143.046C297.151 144.921 294.467 179.108 294.161 183.38C293.855 187.653 289.79 196.473 289.475 200.479C289.159 204.485 290.165 208.501 291.339 210.346C292.514 212.191 296.825 218.091 296.825 218.091L308.419 200.183C308.703 196.273 308.762 192.349 308.596 188.432C308.419 179.898 303.979 136.899 303.979 136.899Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M348.585 52.1846C348.585 52.1846 336.252 51.3952 329.227 59.9495C322.202 68.5039 323.712 81.587 323.662 85.6027C323.613 89.6184 311.684 101.389 313.006 106.184C314.329 110.979 317.841 111.522 316.716 115.528C315.591 119.534 308.251 121.941 309.316 127.023C310.382 132.104 314.25 139.306 314.25 139.306L385.289 139.79C385.289 139.79 387.825 132.311 385.121 126.697C382.418 121.083 378.58 119.228 379.201 116.554C379.823 113.88 383.256 107.467 381.934 102.662C380.612 97.8569 376.014 96.5249 375.531 87.9706C375.047 79.4163 373.785 65.2775 366.996 58.3709C360.208 51.4643 348.585 52.1846 348.585 52.1846Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M326.741 381.837C324.559 386.041 321.911 389.986 318.847 393.598C314.309 398.679 309.908 401.353 310.056 404.027C310.204 406.701 320.11 407.223 325.35 405.349C330.589 403.474 334.397 399.735 335.453 399.429C336.509 399.123 339.459 399.429 339.528 395.68C339.597 391.93 339.025 381.255 339.025 381.255L326.741 381.837Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M325.33 405.349C330.579 403.474 334.378 399.735 335.433 399.429C336.489 399.123 339.439 399.429 339.508 395.68C339.508 393.706 339.4 389.76 339.262 386.533C338.485 387.989 337.336 389.213 335.932 390.08C334.528 390.946 332.919 391.425 331.27 391.467C331.27 391.467 331.171 389.592 328.31 386.395C326.465 384.303 324.6 385.951 323.514 387.382L322.636 388.704L322.567 388.832C321.428 390.512 320.183 392.117 318.838 393.637C314.299 398.718 309.899 401.392 310.047 404.066C310.195 406.74 320.091 407.223 325.33 405.349Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M363.296 381.817C365.948 386.036 369.022 389.973 372.472 393.568C377.554 398.64 382.24 401.304 382.339 403.977C382.438 406.651 372.63 407.184 367.184 405.319C361.737 403.454 357.534 399.715 356.459 399.449C355.383 399.182 352.443 399.449 351.979 395.719C351.516 391.989 350.934 381.294 350.934 381.294L363.296 381.817Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M367.223 405.319C361.786 403.454 357.573 399.715 356.498 399.449C355.422 399.182 352.482 399.449 352.018 395.719C351.772 393.746 351.495 389.799 351.288 386.563C352.201 388.01 353.454 389.211 354.939 390.06C356.423 390.91 358.093 391.383 359.803 391.437C359.803 391.437 359.704 389.562 362.201 386.356C363.829 384.274 365.871 385.912 367.134 387.342C367.459 387.776 367.795 388.211 368.12 388.664L368.209 388.793C369.535 390.47 370.952 392.074 372.452 393.598C377.533 398.669 382.22 401.333 382.318 404.007C382.417 406.681 372.669 407.184 367.223 405.319Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M305.281 240.814C305.281 240.814 306.929 296.372 309.119 307.324C311.31 318.276 321.088 381.047 321.088 381.047C324.076 382.312 327.295 382.944 330.54 382.902H341.225L342.212 336.155L342.577 268.302L347.934 383.159C347.934 383.159 362.655 383.682 364.244 383.415C365.832 383.149 367.155 382.883 367.155 382.883L376.617 310.215L382.033 261.869L382.329 242.639C382.329 242.639 337.101 241.07 323.959 240.005C310.816 238.939 305.41 238.673 305.41 238.673L305.281 240.814Z'
        fill='#86A7FF'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M366.354 338.907C366.236 339.835 366.118 340.693 365.979 341.482' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M367.569 272.022C367.569 272.022 368.486 311.606 366.878 333.096' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M319.074 268.845C319.074 268.845 320.209 295.031 321.334 300.901' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M361.234 252.16V272.15C363.795 272.84 366.415 273.292 369.059 273.502C373.114 273.768 380.681 273.502 380.681 273.502L381.224 265.934L361.234 252.16Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M306.119 267.552C306.119 267.552 316.39 270.798 320.711 271.065C325.033 271.331 329.078 271.874 329.078 271.874L328.546 266.477L305.576 264.316L306.119 267.552Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M337.299 115.775L329.238 124.862L306.88 141.171L311.645 240.024C311.645 240.024 329.464 244.82 344.758 246.152C360.051 247.484 379.715 243.981 379.715 243.981L376.824 140.382C376.824 140.382 368.122 127.555 363.662 124.092C359.202 120.629 357.16 117.422 351.25 116.89C345.34 116.357 337.299 115.775 337.299 115.775Z'
        fill='#86A7FF'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M327.945 120.639L303.979 136.948L307.738 197.046L306.465 203.084L296.392 214.687L300.684 235.071L298.769 263.833C298.769 263.833 308.291 267.039 314.743 267.828C319.548 268.343 324.376 268.61 329.208 268.628L330.037 229.359C330.037 229.359 333.224 164.17 333.598 161.279C333.973 158.388 337.298 115.893 337.298 115.893L327.945 120.639Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M328.329 123.096L316.726 152.192L329.246 157.392L317.426 159.543L327.895 200.558' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M335.285 108.029C335.285 108.029 339.449 135.804 342.725 142.217C346 148.63 346.928 150.762 346.928 150.762C346.928 150.762 354.051 129.114 355.482 125.908C356.913 122.701 358.442 111.216 358.442 111.216C358.442 111.216 344.244 115.765 335.285 108.029Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M335.285 108.029C335.285 108.029 335.66 110.555 336.272 114.255C338.354 115.844 343.869 119.771 347.944 119.978C351.052 120.136 354.999 118.123 357.623 116.515C358.097 113.604 358.422 111.216 358.422 111.216C358.422 111.216 344.244 115.765 335.285 108.029Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M329.632 88.829C329.632 88.829 326.79 84.7837 324.876 88.829C322.962 92.8743 327.836 98.8633 329.198 99.6823C333.065 101.981 332.039 97.7089 332.039 97.7089L329.632 88.829Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M364.924 90.5754C364.924 90.5754 367.321 86.392 369.67 90.3386C372.018 94.2852 367.785 100.491 366.522 101.369C362.911 103.866 363.483 99.5836 363.483 99.5836L364.924 90.5754Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M328.961 85.2573C328.961 85.2573 339.262 82.8499 344.451 79.7814C347.63 77.8615 350.402 75.3374 352.611 72.3518C352.611 72.3518 357.071 80.7779 360.998 84.1917C364.925 87.6056 365.744 87.8029 365.744 87.8029C365.744 87.8029 363.859 102.553 361.728 107.684C359.597 112.815 352.641 117.807 346.149 116.367C342.458 115.558 336.065 112.894 334.21 108.404C332.355 103.915 328.961 85.2573 328.961 85.2573Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M355.245 96.594C352.049 96.673 341.343 96.6927 335.818 93.3578C333.604 92.0685 331.53 90.5506 329.632 88.829C329.829 89.8157 330.066 91.049 330.323 92.3317L331.526 93.2098L334.545 104.616L333.134 104.921C333.432 106.087 333.791 107.237 334.21 108.365C336.065 112.854 342.458 115.518 346.149 116.327C352.631 117.758 359.567 112.795 361.718 107.674C361.21 106.679 361.061 105.54 361.294 104.448C361.915 101.221 362.685 95.5679 362.685 95.5679L364.885 93.5946C365.004 92.8743 365.102 92.1837 365.201 91.5522C362.537 93.3973 357.544 96.5348 355.245 96.594Z'
        fill='#86A7FF'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M343.494 91.8285C343.494 91.8285 340.05 87.645 335.531 88.0298' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M352.364 92.6573C353.463 91.5021 354.828 90.6337 356.34 90.128C357.852 89.6223 359.464 89.4948 361.037 89.7565'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M333.549 85.9381C333.549 85.9381 338.482 83.9648 342.853 84.8923' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M352.769 85.9677C354.068 85.3036 355.541 85.0554 356.986 85.2566C358.432 85.4578 359.78 86.0987 360.849 87.0925'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M340.179 90.457C340.201 90.6087 340.193 90.7632 340.156 90.9118C340.118 91.0604 340.051 91.2001 339.96 91.323C339.868 91.4458 339.753 91.5493 339.621 91.6276C339.49 91.7059 339.344 91.7574 339.192 91.7791C338.883 91.7444 338.599 91.5935 338.398 91.3572C338.196 91.1208 338.092 90.8166 338.107 90.5064C338.083 90.3545 338.09 90.1995 338.127 90.0504C338.164 89.9013 338.23 89.761 338.322 89.6379C338.414 89.5147 338.529 89.4112 338.662 89.3333C338.794 89.2554 338.941 89.2047 339.093 89.1842C339.401 89.2212 339.684 89.3727 339.885 89.6085C340.086 89.8444 340.191 90.1473 340.179 90.457Z'
        fill='#313131'
      />
      <path
        d='M358.048 91.7397C358.074 91.8918 358.07 92.0478 358.034 92.198C357.998 92.3482 357.932 92.4896 357.839 92.6133C357.747 92.7371 357.63 92.8407 357.497 92.9179C357.363 92.995 357.215 93.0439 357.061 93.0618C356.753 93.0294 356.47 92.8806 356.268 92.6458C356.066 92.4111 355.962 92.1081 355.976 91.7989C355.949 91.6467 355.954 91.4908 355.99 91.3405C356.026 91.1903 356.092 91.049 356.184 90.9252C356.277 90.8014 356.393 90.6978 356.527 90.6207C356.661 90.5436 356.809 90.4946 356.963 90.4767C357.27 90.5091 357.554 90.6579 357.756 90.8927C357.958 91.1275 358.062 91.4304 358.048 91.7397Z'
        fill='#313131'
      />
      <path
        d='M384.618 131.236C384.618 131.236 390.37 133.9 392.511 138.142C394.652 142.385 411.771 188.353 411.692 191.826C411.613 195.299 405.95 199.305 400.641 199.848C395.333 200.39 387.598 190.771 386.443 189.172C385.289 187.574 385.082 168.867 385.082 168.867L384.618 131.236Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M357.505 118.962L360.307 210.859L361.294 268.825C366.666 270.146 372.17 270.858 377.702 270.946C382.316 271.043 386.903 270.225 391.199 268.539C391.199 268.539 389.522 232.477 389.226 226.596C388.93 220.716 386.976 209.497 386.976 209.497L384.648 131.236C384.648 131.236 362.971 121.093 357.505 118.962Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M363.662 124.033L373.597 156.445L364.54 158.901L373.351 163.174L362.359 198.091' fill='white' />
      <path d='M363.662 124.033L373.597 156.445L364.54 158.901L373.351 163.174L362.359 198.091' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M370.588 153.682L358.265 212.724C358.265 212.724 366.691 215.388 372.847 215.684C379.004 215.98 391.831 215.94 391.831 215.94L404.657 160.904C404.657 160.904 379.409 158.487 370.588 153.682Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M374.88 208.708L375.284 190.257L385.062 192.32C385.062 192.32 378.6 206.557 374.88 208.708Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M382.369 183.588C382.369 183.588 379.764 179.858 378.925 179.049C378.087 178.24 373.943 175.852 373.143 175.852C372.344 175.852 368.052 175.586 367.223 175.586C366.395 175.586 365.457 177.46 366.306 178.26C367.154 179.059 370.105 179.325 370.105 179.325L372.127 182.285L363.001 181.486C363.001 181.486 361.481 183.094 361.826 184.446C362.085 185.362 362.415 186.256 362.813 187.12C362.778 189.081 362.881 191.043 363.119 192.99C363.474 194.599 365.092 195.132 367.312 196.464C369.532 197.796 376.962 196.996 376.962 196.996H380.395L382.369 183.588Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M404.618 185.443L380.938 182.256C380.938 182.256 379.093 197.49 377.228 202.561C375.363 207.632 374.88 208.708 374.88 208.708C374.88 208.708 399.694 208.155 405.545 207.623C411.396 207.09 414.682 203.607 414.425 198.802C414.169 193.997 411.238 189.182 411.238 189.182'
        fill='white'
      />
      <path
        d='M404.618 185.443L380.938 182.256C380.938 182.256 379.093 197.49 377.228 202.561C375.363 207.632 374.88 208.708 374.88 208.708C374.88 208.708 399.694 208.155 405.545 207.623C411.396 207.09 414.682 203.607 414.425 198.802C414.169 193.997 411.238 189.182 411.238 189.182'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M305.873 202.078C305.873 202.078 311.398 205.541 312.779 206.616C314.161 207.692 314.279 214.628 315.157 215.96C316.035 217.292 314.999 218.101 313.401 218.101C311.802 218.101 309.385 213.03 309.385 213.03L309.286 211.155L297.999 214.904L295.039 209.833C295.039 209.833 298.394 202.078 305.873 202.078Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M223.645 411.703C252.161 411.703 275.277 406.49 275.277 400.06C275.277 393.63 252.161 388.418 223.645 388.418C195.13 388.418 172.014 393.63 172.014 400.06C172.014 406.49 195.13 411.703 223.645 411.703Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M263.97 123.392C263.97 123.392 270.739 157.362 271.942 163.647C273.146 169.932 275.356 184.87 277.862 191.422C280.368 197.973 279.549 206.36 278.296 208.718C277.04 210.767 275.596 212.696 273.985 214.48L265.578 203.518C265.578 203.518 260.576 149.804 260.389 142.197C260.201 134.59 263.97 123.392 263.97 123.392Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M268.43 202.176C268.43 202.176 273.758 205.501 274.695 206.912C275.633 208.323 276.303 210.119 275.573 211.648C274.843 213.177 270.867 217.795 269.861 218.693C268.854 219.591 268.006 216.009 267.887 212.931C267.769 209.852 268.43 202.176 268.43 202.176Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M239.778 388.457C239.778 388.457 256.482 396.577 257.537 398.156C258.593 399.735 254.785 401.836 244.829 401.836C234.874 401.836 234.578 400.524 230.621 399.478C226.665 398.432 218.781 399.212 217.716 396.854C216.65 394.496 218.505 386.375 218.505 386.375L239.778 388.457Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M217.597 392.651C217.597 392.651 231.41 401.037 230.197 403.918C228.983 406.799 218.998 406.799 213.739 406.01C208.481 405.221 197.302 398.117 196.71 395.532C196.118 392.947 197.598 388.98 205.698 388.201C213.799 387.421 217.597 392.651 217.597 392.651Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M259.53 255.614C259.53 255.614 251.637 316.402 250.265 325.311C248.894 334.221 244.543 348.369 242.194 359.114C240.864 365.433 239.876 371.82 239.235 378.245C238.995 380.073 239.038 381.927 239.363 383.741C239.698 386.366 239.748 388.457 239.748 388.457C236.234 389.339 232.659 389.952 229.052 390.292C224.602 390.559 218.574 390.559 218.574 390.559L216.601 373.529C216.601 373.529 218.071 348.369 218.939 341.038C219.807 333.708 220.735 276.057 220.695 274.479C220.656 272.9 220.31 259.018 220.31 259.018L247.197 256.137C247.197 256.137 250.867 245.658 256.383 246.181C261.898 246.704 259.53 255.614 259.53 255.614Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M256.422 246.181C250.907 245.658 247.236 256.137 247.236 256.137L220.32 259.018C220.32 259.018 220.409 262.708 220.498 266.496C223.369 266.753 226.023 266.901 228.293 266.901C236.916 266.887 245.533 266.403 254.103 265.451L255.258 253.374L257.063 265.678L258.307 265.273C259.076 259.412 259.57 255.594 259.57 255.594C259.57 255.594 261.938 246.704 256.422 246.181Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M192.24 257.449C192.24 257.449 192.24 257.824 192.299 258.505C192.664 265.668 194.835 307.176 195.496 314.833C196.236 323.219 192.536 332.385 192.724 339.983C192.911 347.58 194.697 388.724 194.697 388.724L196.67 395.532C196.67 395.532 201.308 392.394 205.757 392.394C210.207 392.394 217.597 392.651 217.597 392.651C217.597 392.651 217.982 387.145 217.943 385.32C217.903 383.494 217.735 376.933 217.735 376.933C217.735 376.933 225.264 342.607 225.718 339.726C226.171 336.845 232.012 287.058 231.973 285.46C231.933 283.862 233.206 261.613 233.206 261.613L192.24 257.449Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M232.93 266.852C233.098 263.813 233.216 261.642 233.216 261.642L192.24 257.449C192.24 257.449 192.24 257.824 192.299 258.505C192.299 259.373 192.408 260.744 192.507 262.451C202.087 264.168 218.712 266.871 228.293 266.871C229.871 266.901 231.411 266.881 232.93 266.852Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M222.432 96.5644C222.432 96.5644 218.1 97.2945 216.452 99.2284C214.801 101.109 213.316 103.13 212.012 105.267C211.786 105.997 186.31 121.458 186.31 121.458L188.145 195.181C188.145 195.181 188.905 206.537 187.553 210.405C185.27 218.01 184.162 225.919 184.268 233.858C184.504 243.28 185.116 258.524 185.116 258.524C185.116 258.524 214.272 264.326 228.292 264.326C236.916 264.305 245.533 263.815 254.103 262.856L255.258 250.769L257.014 263.102L263.003 261.129L267.936 224.623C267.936 224.623 271.37 216.887 271.33 215.19C271.291 213.493 272.199 211.323 271.202 209.872C270.206 208.422 268.43 205.521 268.43 205.521C268.43 205.521 263.97 125.572 263.97 123.392C263.97 121.211 250.778 108.414 249.801 107.931C248.825 107.447 242.806 98.4983 235.002 96.0908C227.197 93.6834 222.432 96.5644 222.432 96.5644Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M214.49 107.684L211.569 134.235L223.567 139.81L220.568 148.818L250.572 201.545' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M248.006 110.664L257.004 131.67L253.146 136.81L259.579 145.809L257.004 203.686' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M221.316 100.195C221.535 105.375 222.475 110.499 224.109 115.419C226.704 122.671 243.901 172.705 243.901 172.705L255.021 211.855C255.021 211.855 253.551 171.975 253.511 170.287C253.472 168.6 245.085 113.239 245.085 113.239C245.085 113.239 243.654 104.3 241.395 100.916C239.135 97.5313 223.99 91.2463 221.316 100.195Z'
        fill='#86A7FF'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M220.252 96.3276C220.252 96.3276 213.256 97.0479 214.49 107.684C215.723 118.32 217.351 125.572 217.351 125.572'
        stroke='#313131'
        stroke-width='1.00878'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M216.492 139.593C216.492 139.593 219.452 151.916 221.475 155.3C223.497 158.684 229.615 162.069 233.966 162.069'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M225.846 136.692C225.846 136.692 229.102 150.949 229.644 153.613C230.187 156.277 228.391 161.338 225.372 166.44'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M224.504 168.127C224.193 167.956 223.963 167.669 223.863 167.329C223.763 166.989 223.802 166.623 223.971 166.311L224.316 165.68C224.401 165.529 224.515 165.396 224.652 165.29C224.789 165.184 224.945 165.106 225.113 165.061C225.28 165.016 225.454 165.005 225.626 165.028C225.798 165.051 225.963 165.108 226.112 165.196C226.422 165.367 226.653 165.654 226.755 165.993C226.856 166.333 226.82 166.698 226.655 167.012L226.309 167.633C226.224 167.785 226.11 167.919 225.972 168.026C225.835 168.134 225.678 168.213 225.51 168.259C225.342 168.304 225.166 168.316 224.994 168.294C224.821 168.271 224.654 168.214 224.504 168.127Z'
        fill='#313131'
      />
      <path
        d='M235.584 162.335C235.584 162.509 235.549 162.681 235.481 162.841C235.413 163.001 235.313 163.146 235.188 163.267C235.062 163.388 234.914 163.482 234.751 163.544C234.589 163.606 234.415 163.634 234.242 163.628H233.521C233.166 163.62 232.829 163.473 232.581 163.218C232.334 162.963 232.197 162.621 232.199 162.266C232.198 162.092 232.232 161.92 232.299 161.76C232.365 161.6 232.464 161.455 232.589 161.335C232.713 161.214 232.861 161.119 233.023 161.057C233.185 160.995 233.358 160.967 233.531 160.973H234.252C234.607 160.984 234.944 161.131 235.192 161.385C235.441 161.639 235.581 161.98 235.584 162.335Z'
        fill='#313131'
      />
      <path
        d='M216.512 140.559L214.855 130.052C214.67 128.968 214.904 127.855 215.511 126.938C216.118 126.022 217.051 125.371 218.121 125.118V125.118C219.273 124.856 220.482 125.051 221.494 125.662C222.506 126.273 223.242 127.252 223.547 128.394L226.379 138.853'
        stroke='#313131'
        stroke-width='1.00878'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M255.514 147.661C257.889 147.423 259.592 145.011 259.317 142.273C259.043 139.535 256.896 137.508 254.521 137.746C252.146 137.983 250.443 140.396 250.717 143.134C250.992 145.872 253.139 147.899 255.514 147.661Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M243.379 100.758C243.379 100.758 252.88 114.127 255.909 141.378' stroke='#313131' stroke-width='1.00878' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M256.161 144.533C257.458 144.413 258.395 143.088 258.254 141.574C258.114 140.06 256.948 138.931 255.652 139.051C254.355 139.172 253.418 140.497 253.558 142.011C253.699 143.524 254.864 144.654 256.161 144.533Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M270.048 204.258C269.466 206.064 268.988 207.902 268.618 209.764C268.618 210.553 268.43 212.911 266.585 212.378C264.74 211.846 265.293 202.423 265.53 201.377C265.766 200.331 270.256 203.715 270.256 203.715'
        fill='white'
      />
      <path
        d='M270.048 204.258C269.466 206.064 268.988 207.902 268.618 209.764C268.618 210.553 268.43 212.911 266.585 212.378C264.74 211.846 265.293 202.423 265.53 201.377C265.766 200.331 270.256 203.715 270.256 203.715'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M210.158 195.911C211.799 196.815 213.633 197.312 215.506 197.361C218.406 197.361 223.705 196.375 225.649 196.878C227.592 197.381 232.555 203.637 232.555 203.637C232.555 203.637 234.035 205.087 233.335 205.817C232.634 206.547 231.203 207.79 230.217 207.021C229.23 206.251 224.297 202.186 224.297 202.186L211.855 207.267L206.744 204.603L210.158 195.911Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M191.105 119.771C191.105 119.771 182.906 120.757 181.515 122.908C180.124 125.059 157.658 174.392 157.707 176.572C157.756 178.753 202.63 214.065 202.63 214.065L212.319 195.625L188.076 173.149L198.594 148.739'
        fill='white'
      />
      <path
        d='M191.105 119.771C191.105 119.771 182.906 120.757 181.515 122.908C180.124 125.059 157.658 174.392 157.707 176.572C157.756 178.753 202.63 214.065 202.63 214.065L212.319 195.625L188.076 173.149L198.594 148.739'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M206.231 222.975L229.931 223.458L229.813 208.718' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M211.884 207.267L205.619 209.606' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M219.028 85.9282C219.028 85.9282 221.721 106.964 223.497 110.101C225.273 113.239 237.903 125.809 237.903 125.809C237.903 125.809 241.849 111.315 242.293 107.931C242.737 104.546 243.083 91.0095 243.083 91.0095L218.979 84.2411L219.028 85.9282Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M219.028 85.9282C219.028 85.9282 219.344 88.3949 219.827 91.7594C221.716 93.5186 223.875 94.9642 226.221 96.0415C229.329 97.4129 237.616 98.1825 242.856 97.5708C242.994 94.0287 243.083 91.0095 243.083 91.0095L218.979 84.2411L219.028 85.9282Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M212.042 67.5665C212.042 67.5665 208.816 64.1823 207.217 68.0401C205.619 71.898 209.191 77.7094 211.095 77.9561C212.225 78.1034 213.373 78.0195 214.469 77.7094C214.469 77.7094 215.821 83.2741 217.064 84.9613C219.511 88.0551 222.646 90.535 226.22 92.2034C230.621 94.1767 245.352 94.8772 247.216 92.2034C249.081 89.5295 248.973 75.2822 248.943 74.0785C248.913 72.8748 248.706 55.6477 248.706 55.6477C248.706 55.6477 250.028 49.6686 248.233 45.5542C246.437 41.4398 228.411 35.6876 223.882 38.0655C219.353 40.4433 214.134 44.5873 213.778 49.9054C213.423 55.2234 213.64 63.9258 213.64 63.9258L212.042 67.5665Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M238.682 60.0679C238.682 60.0679 241.691 73.8812 241.001 75.5387C240.31 77.1963 238.149 77.2259 235.446 75.7755C232.742 74.3251 234.153 72.3913 234.153 72.3913'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M228.204 76.0222C228.204 76.0222 229.506 79.6432 231.924 79.8899C234.341 80.1366 240.409 80.6102 240.409 80.6102'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M219.57 60.5514L219.955 66.353C219.955 66.353 222.165 67.5665 227.237 67.5665C232.308 67.5665 233.976 66.353 233.976 66.353L233.837 60.5514C233.837 60.5514 226.477 56.2002 219.57 60.5514Z'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M238.702 60.5514L240.537 66.5996C240.537 66.5996 241.287 67.5863 246.368 67.5863C251.45 67.5863 253.117 66.3727 253.117 66.3727L252.969 60.5711C252.969 60.5711 245.599 56.2003 238.702 60.5514Z'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M233.867 60.5514H238.702' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M219.6 60.5514L210.069 66.1162' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M221.948 57.6506C221.948 57.6506 224.533 54.7499 230.394 57.1672' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M239.59 57.6506C240.46 56.9523 241.51 56.5142 242.619 56.3868C243.727 56.2593 244.849 56.4478 245.855 56.9304'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M228.125 63.2154C228.125 64.281 227.74 65.1887 227.207 65.1887C226.675 65.1887 226.221 64.3204 226.221 63.2154C226.221 62.1103 226.605 61.2421 227.148 61.2421C227.691 61.2421 228.105 62.1399 228.125 63.2154Z'
        fill='#313131'
      />
      <path
        d='M243.596 62.9687C243.596 64.0343 243.211 64.942 242.678 64.942C242.145 64.942 241.691 64.0738 241.691 62.9687C241.691 61.8637 242.076 60.9954 242.609 60.9954C243.142 60.9954 243.566 61.9031 243.596 62.9687Z'
        fill='#313131'
      />
      <path
        d='M227.04 40.6111C227.04 40.6111 229.368 45.7515 226.477 47.6262C225.585 48.1424 224.573 48.4142 223.542 48.4142C222.511 48.4142 221.499 48.1424 220.607 47.6262C221.375 48.6879 221.804 49.9573 221.838 51.2675C221.871 52.5777 221.507 53.8674 220.794 54.9669C219.377 57.3282 217.724 59.5397 215.861 61.5677C215.861 61.5677 216.384 67.8132 215.298 68.5433C214.213 69.2735 211.253 68.1783 211.204 66.3431C211.154 64.5079 209.97 60.8375 209.921 59.0024C209.872 57.1672 209.023 52.3918 209.023 52.3918C209.023 52.3918 201.604 49.0864 205.54 45.051C209.477 41.0156 217.834 37.7103 217.834 37.7103C217.834 37.7103 218.021 30.3695 223.103 28.1693C228.184 25.9691 239.116 22.6637 239.205 26.3341C239.344 28.2231 239.005 30.1166 238.218 31.8397C238.218 31.8397 247.384 31.4746 253.778 37.7596C260.172 44.0446 259.954 49.8758 256.649 49.8758C253.344 49.8758 252.268 50.6059 252.268 50.6059C252.268 50.6059 258.593 54.2763 255.337 56.1114C252.081 57.9466 248.726 55.7464 248.726 55.7464C248.726 55.7464 250.127 53.1811 246.003 49.8264C241.879 46.4718 227.04 40.6111 227.04 40.6111Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M101.843 411.703C130.358 411.703 153.475 406.49 153.475 400.06C153.475 393.63 130.358 388.418 101.843 388.418C73.3273 388.418 50.2109 393.63 50.2109 400.06C50.2109 406.49 73.3273 411.703 101.843 411.703Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M135.843 159.247V168.679L149.557 174.964C149.557 174.964 150.988 168.679 149.843 157.53L135.843 159.247Z'
        fill='#999999'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M67.7732 385.596C64.6341 388.291 61.6682 391.181 58.8932 394.249C54.9466 398.699 54.177 403.129 57.8474 405.25C61.5177 407.371 67.2798 406.819 73.5648 402.892C79.8498 398.965 79.5933 397.387 83.2637 396.084C86.9341 394.782 89.2922 392.137 89.2922 387.954C89.2922 383.771 75.923 383.238 73.0419 383.021C70.1609 382.804 67.7732 385.596 67.7732 385.596Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M89.2629 387.954C89.2629 387.954 83.3429 390.579 80.4717 392.966C77.6006 395.354 71.8779 403.948 65.1884 403.948C61.7818 403.951 58.4317 403.078 55.46 401.412C55.4643 402.206 55.6865 402.984 56.1023 403.661C56.518 404.338 57.1115 404.887 57.8181 405.25C61.4884 407.342 67.2505 406.819 73.5355 402.892C79.8205 398.965 79.564 397.387 83.2344 396.084C86.9047 394.782 89.2629 392.147 89.2629 387.954Z'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M134.264 385.596C137.403 388.291 140.369 391.181 143.144 394.249C147.091 398.699 147.86 403.129 144.19 405.25C140.52 407.371 134.757 406.819 128.472 402.892C122.187 398.965 122.444 397.387 118.774 396.084C115.103 394.782 112.745 392.137 112.745 387.954C112.745 383.771 126.114 383.238 128.995 383.021C131.876 382.804 134.264 385.596 134.264 385.596Z'
        fill='white'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M112.774 387.954C112.774 387.954 118.694 390.579 121.566 392.966C124.437 395.354 130.159 403.948 136.849 403.948C140.255 403.951 143.606 403.078 146.577 401.412C146.573 402.206 146.351 402.984 145.935 403.661C145.519 404.338 144.926 404.887 144.219 405.25C140.549 407.342 134.787 406.819 128.502 402.892C122.217 398.965 122.473 397.387 118.803 396.084C115.133 394.782 112.774 392.147 112.774 387.954Z'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M60.1758 236.769C60.1758 236.769 61.7446 295.721 62.5339 304.374C63.3232 313.027 65.6517 332.672 65.6517 333.757C65.6517 334.842 66.9541 378.305 66.9541 378.305L64.596 382.251L65.3853 387.757L75.8636 389.848C81.1028 390.894 87.9206 392.473 88.9665 391.427C90.0123 390.381 90.2787 388.28 90.8016 385.399C91.3246 382.518 92.3704 377.269 91.3246 373.085C90.2787 368.902 92.6368 324.344 92.9032 323.298C93.1696 322.253 99.1981 279.215 99.1981 279.215C99.1981 279.215 103.145 314.596 106.006 325.331C108.867 336.066 107.841 352.059 108.887 358.344C109.933 364.629 112.035 385.862 112.301 387.431C112.567 389 113.08 391.378 113.08 391.378C113.08 391.378 126.183 389.543 128.541 388.487C130.932 387.606 133.386 386.907 135.882 386.395C135.882 386.395 136.661 371.457 135.616 365.429C134.854 361.193 134.415 356.905 134.303 352.602C134.303 350.767 135.616 292.603 135.616 292.603L134.303 236.265C134.303 236.265 98.9317 239.936 85.306 237.311C71.6802 234.687 60.4422 233.118 60.4422 233.118L60.1758 236.769Z'
        fill='#86A7FF'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M85.3257 237.291C71.6999 234.677 60.4422 233.118 60.4422 233.118L60.1758 236.778C60.1758 236.778 60.2646 240.163 60.4126 245.412C66.6581 247.158 79.3465 250.227 91.6107 250.227C104.575 250.227 125.226 246.28 134.52 244.395L134.323 236.255C134.323 236.255 98.9514 239.916 85.3257 237.291Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M130.841 370.056C131.009 371.862 131.117 372.898 131.117 372.898' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M126.824 250.601C126.824 250.601 127.811 301.237 127.811 320.348C127.811 333.569 129.409 353.638 130.396 364.748'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M73.792 247.74L76.1797 302.677' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M75.6963 306.969V316.046' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M88.1185 98.3799C86.8284 98.3867 85.5586 98.7009 84.4142 99.2963C83.2697 99.8917 82.2836 100.751 81.5375 101.804C79.9058 103.957 78.09 105.964 76.1109 107.802L52.6777 120.382L54.9668 209.833L55.8252 240.419C55.8252 240.419 73.8218 246.418 90.9699 246.418C108.118 246.418 138.695 239.511 138.695 239.511C138.695 239.511 136.129 120.343 136.988 120.343C137.846 120.343 120.126 104.951 112.124 100.906C104.122 96.8604 88.1185 98.3799 88.1185 98.3799Z'
        fill='#86A7FF'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M81.4385 109.194C81.4385 109.194 90.5157 125.918 94.344 133.564C98.1722 141.211 105.316 152.212 105.316 152.212L117.264 120.205'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M88.1478 215.269L87.6841 201.92L60.7188 200.351C60.7188 200.351 60.9852 220.005 61.5081 221.071'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M88.473 224.721L88.3447 220.972' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M120.442 222.62L119.652 200.617C119.652 200.617 129.085 201.397 136.948 196.158' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M80.0078 148.186C80.8365 150.801 83.3624 152.34 85.6416 151.61C87.9207 150.88 89.0751 148.167 88.2365 145.552C87.3978 142.937 84.8818 141.398 82.6027 142.128C80.3235 142.858 79.1691 145.572 80.0078 148.186Z'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M80.2747 102.405C80.2747 102.405 77.2457 114.048 84.1326 145.651' stroke='#313131' stroke-width='1.00878' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M86.2533 146.736C86.0503 147.145 85.7341 147.488 85.3422 147.723C84.9503 147.958 84.4992 148.075 84.0424 148.062C83.5857 148.048 83.1425 147.903 82.7654 147.645C82.3884 147.387 82.0933 147.026 81.9153 146.605C81.7372 146.185 81.6837 145.721 81.761 145.271C81.8383 144.821 82.0432 144.402 82.3514 144.065C82.6596 143.727 83.0581 143.485 83.4997 143.368C83.9412 143.25 84.4072 143.262 84.8424 143.401C85.4692 143.659 85.9689 144.154 86.233 144.779C86.4972 145.403 86.5045 146.106 86.2533 146.736V146.736Z'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M135.843 118.666C135.843 118.666 140.125 121.813 140.411 122.958C140.697 124.102 155.27 158.961 155.27 158.961L134.126 162.079L134.412 141.497' fill='#86A7FF' />
      <path
        d='M135.843 118.666C135.843 118.666 140.125 121.813 140.411 122.958C140.697 124.102 155.27 158.961 155.27 158.961L134.126 162.079L134.412 141.497'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M55.5387 119.238C55.5387 119.238 50.6745 119.81 49.53 123.52C48.3855 127.23 40.1074 164.96 40.1074 164.96L70.3978 162.079L67.5365 133.781' fill='#86A7FF' />
      <path
        d='M55.5387 119.238C55.5387 119.238 50.6745 119.81 49.53 123.52C48.3855 127.23 40.1074 164.96 40.1074 164.96L70.3978 162.079L67.5365 133.781'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M114.048 100.629C114.048 100.629 121.556 104.912 122.296 115.567C123.036 126.223 122.296 128.927 122.296 128.927'
        stroke='#313131'
        stroke-width='1.00878'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M125.69 142.572C125.69 142.572 125.019 155.172 123.648 158.813C122.276 162.453 116.85 166.765 112.548 167.456'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M115.972 141.211C115.972 141.211 115.37 155.744 115.311 158.448C115.251 161.151 117.974 165.857 121.882 170.366'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M123.003 171.949L123.118 171.856C123.655 171.42 123.737 170.631 123.302 170.094L122.748 169.412C122.313 168.874 121.523 168.792 120.986 169.228L120.871 169.321C120.333 169.757 120.251 170.546 120.687 171.083L121.24 171.765C121.676 172.303 122.465 172.385 123.003 171.949Z'
        fill='#313131'
      />
      <path
        d='M111.003 167.916L111.03 168.062C111.159 168.742 111.815 169.188 112.495 169.06L113.358 168.896C114.038 168.767 114.484 168.112 114.355 167.432L114.328 167.286C114.199 166.606 113.543 166.16 112.863 166.288L112.001 166.452C111.321 166.581 110.874 167.236 111.003 167.916Z'
        fill='#313131'
      />
      <path
        d='M125.838 143.529L125.552 132.943C125.525 131.838 125.088 130.784 124.325 129.984C123.563 129.185 122.53 128.699 121.428 128.621V128.621C120.258 128.534 119.1 128.913 118.209 129.677C117.318 130.441 116.765 131.526 116.672 132.696L115.784 143.431'
        stroke='#313131'
        stroke-width='1.00878'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M150.415 167.821L143.598 166.39L119.592 162.394L85.9275 155.981L77.9455 152.459C77.9455 152.459 70.3975 142.592 69.7463 141.605C69.0951 140.619 60.5606 144.062 60.8664 145.374C61.1723 146.687 62.1787 159.799 62.8397 160.628C63.5008 161.457 70.8711 161.447 70.8711 161.447C70.8711 161.447 106.036 178.418 107.496 179.039C127.791 187.593 137.579 187.515 141.042 188.077C144.505 188.639 146.765 187.238 148.758 184.949C150.751 182.66 150.415 167.821 150.415 167.821Z'
        fill='#999999'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M68.7203 141.467C68.7203 141.467 64.6256 141.635 58.5577 145.73C56.2982 147.259 55.1143 148.354 55.1143 149.015C55.2436 149.802 55.4655 150.57 55.7753 151.304C59.7094 149.41 63.8293 147.928 68.0691 146.884'
        fill='#999999'
      />
      <path
        d='M68.7203 141.467C68.7203 141.467 64.6256 141.635 58.5577 145.73C56.2982 147.259 55.1143 148.354 55.1143 149.015C55.2436 149.802 55.4655 150.57 55.7753 151.304C59.7094 149.41 63.8293 147.928 68.0691 146.884'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M70.3584 151.965C70.3584 151.965 59.219 154.748 57.4134 155.083C55.6078 155.419 55.2822 153.603 55.2822 152.952C55.2822 152.301 55.7756 151.304 57.739 150.327C59.7025 149.351 69.5789 146.716 69.5789 146.716'
        fill='#999999'
      />
      <path
        d='M70.3584 151.965C70.3584 151.965 59.219 154.748 57.4134 155.083C55.6078 155.419 55.2822 153.603 55.2822 152.952C55.2822 152.301 55.7756 151.304 57.739 150.327C59.7025 149.351 69.5789 146.716 69.5789 146.716'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M71.5124 157.047C71.5124 157.047 58.5575 158.359 57.5709 157.54C57.269 157.211 57.04 156.822 56.8989 156.399C56.7577 155.976 56.7077 155.527 56.752 155.083L68.3945 152.616'
        fill='#999999'
      />
      <path
        d='M71.5124 157.047C71.5124 157.047 58.5575 158.359 57.5709 157.54C57.269 157.211 57.04 156.822 56.8989 156.399C56.7577 155.976 56.7077 155.527 56.752 155.083L68.3945 152.616'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M67.9021 157.698L57.9072 158.191C57.9072 158.191 58.3907 159.671 58.8939 159.829C61.2617 160.414 63.6743 160.8 66.1064 160.983C67.7012 161.036 69.2904 161.201 70.862 161.477'
        fill='#999999'
      />
      <path
        d='M67.9021 157.698L57.9072 158.191C57.9072 158.191 58.3907 159.671 58.8939 159.829C61.2617 160.414 63.6743 160.8 66.1064 160.983C67.7012 161.036 69.2904 161.201 70.862 161.477'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M46.3926 164.387C46.3926 164.387 50.9608 184.12 52.1053 185.541C53.2498 186.962 62.6823 185.541 72.1147 182.68C81.5472 179.819 121.556 162.68 121.556 162.68L127.269 163.815C127.269 163.815 124.98 156.672 120.984 156.385C116.988 156.099 70.398 162.079 70.398 162.079L46.3926 164.387Z'
        fill='#999999'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M86.3721 85.8C86.3721 85.8 86.3721 86.2243 86.3721 86.9445C86.4609 90.7037 86.8259 102.642 87.783 105.523C88.9275 108.947 104.359 137.817 105.543 135.528C106.727 133.238 113.831 102.09 113.831 102.09V89.8058C113.831 89.8058 110.111 92.9434 103.254 92.9434C96.3965 92.9434 86.3721 85.8 86.3721 85.8Z'
        fill='#999999'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M109.263 102.731C110.863 101.754 112.392 100.666 113.841 99.4751V89.8058C113.841 89.8058 110.121 92.9434 103.264 92.9434C96.4064 92.9434 86.3721 85.8 86.3721 85.8C86.3721 85.8 86.3721 86.2243 86.3721 86.9445C86.3721 88.2666 86.4707 90.605 86.5891 93.2098C86.8598 93.6435 87.1632 94.0558 87.4969 94.4431C89.4702 96.7223 98.9223 102.445 101.211 103.017C103.5 103.589 106.687 104.152 109.263 102.731Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M120.697 69.2241C120.697 69.2241 125.265 66.649 125.265 71.5132C125.265 76.3774 120.983 82.0901 120.983 82.0901L120.697 69.2241Z'
        fill='#999999'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M79.83 69.5103C79.83 69.5103 73.5352 67.7935 73.5352 73.5161C73.5352 79.2387 79.2578 82.9387 79.2578 82.9387H83.2044C83.2044 82.9387 85.4935 88.6613 87.4964 90.9405C89.4993 93.2196 98.9219 98.9423 101.211 99.5145C103.5 100.087 106.638 100.659 109.213 99.2284C112.448 97.2115 115.429 94.8132 118.093 92.085C119.237 90.6642 121.24 80.9456 121.24 80.9456C121.24 80.9456 121.812 63.5114 120.668 57.5125C119.523 51.5136 112.094 42.0812 101.803 40.9367C91.5121 39.7921 81.7935 48.0801 80.3727 54.6512C78.9519 61.2223 79.83 69.5103 79.83 69.5103Z'
        fill='#999999'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M98.3989 74.0884C98.3989 74.0884 96.4256 78.0843 99.8296 79.2288C103.234 80.3734 107.545 80.0872 108.404 78.3705C109.262 76.6537 107.831 74.6606 107.831 74.6606'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M94.9756 81.2318C94.9756 81.2318 102.119 87.2306 111.838 81.5179' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M98.9717 86.6584C98.9717 86.6584 104.98 90.0919 108.976 86.0861' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M95.5478 70.6548C96.338 70.6548 96.9785 69.6299 96.9785 68.3657C96.9785 67.1015 96.338 66.0767 95.5478 66.0767C94.7577 66.0767 94.1172 67.1015 94.1172 68.3657C94.1172 69.6299 94.7577 70.6548 95.5478 70.6548Z'
        fill='#313131'
      />
      <path
        d='M110.979 68.0796C110.979 69.3425 110.338 70.3687 109.549 70.3687C108.76 70.3687 108.118 69.382 108.118 68.0796C108.118 66.7772 108.76 65.8004 109.549 65.8004C110.338 65.8004 110.979 66.8167 110.979 68.0796Z'
        fill='#313131'
      />
      <path d='M91.542 65.2282C91.542 65.2282 92.9726 62.9391 98.4486 63.7975' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M107.26 64.0837C107.26 64.0837 109.835 60.0778 113.841 64.0837' stroke='#313131' stroke-width='0.50439' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M120.697 57.4928C120.326 55.7957 119.683 54.1698 118.793 52.6779C118.46 54.2598 118.344 55.8796 118.448 57.4928C118.862 59.5845 120.332 62.505 120.332 63.9652C120.332 65.4255 120.115 71.2764 121.161 70.4476C121.265 70.3381 121.348 70.2112 121.408 70.0727C121.473 65.8678 121.236 61.6636 120.697 57.4928Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M106.332 45.6529C108.167 45.5557 109.993 45.3348 111.798 44.9918C108.883 42.8096 105.447 41.4285 101.833 40.986C91.5421 39.8415 81.8235 48.1294 80.4028 54.7005C78.982 61.2717 79.8305 69.5596 79.8305 69.5596C79.8305 69.5596 82.0998 71.1383 82.9385 69.4708C83.7771 67.8033 82.9385 65.0802 83.7771 62.1597C84.6158 59.2392 85.859 57.769 86.0662 55.4701C86.2734 53.1712 84.8131 49.8363 85.6518 47.9518C86.4904 46.0673 91.7099 42.5252 95.0546 43.7782C98.6532 45.1412 102.486 45.7783 106.332 45.6529Z'
        fill='#313131'
        stroke='#313131'
        stroke-width='0.50439'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default Doctors;
