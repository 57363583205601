import React, { useEffect, useState } from "react";

import Container from "components/Container";
import Text from "components/Text";
import Stepper from "components/Stepper";
import BookMe from "components/common/BookMe";

import InsurenceStep from "./CheckoutSteps/Insurence";
import CareGiverStep from "./CheckoutSteps/Caregiver";
import ShippingStep from "./CheckoutSteps/Shipping";
import ConfirmationStep from "./CheckoutSteps/Confirmation";

import CurveLine from "assets/svg/curve";
import Dots from "assets/svg/dots";
import Heart from "assets/svg/heart";

import { STEPS } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "app/slice/appSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ThankYouPage from "./Thankyou";
import { ScrollUp } from "components/ScrollToTop";

const CheckoutPage = () => {
  const { product_id, step_key } = useSelector((state) => state.app.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    insurence_number: "",
    health_insurence: "",
    street_number: "",
    house_number: "",
    postal_code: "",
    address: "",
    phone_number: "",
    email: "",
    dob: "",
    doc: "",
    detail_cg: false,
    first_name_cg: "",
    last_name_cg: "",
    dob_cg: "",
    street_no_cg: "",
    house_no_cg: "",
    postal_code_cg: "",
    address_cg: "",
    email_cg: "",
    phone_cg: "",
    form_send: "",
    monthly_delivery: "",
    detail_ns: false,
    name_ns: "",
    street_no_ns: "",
    house_no_ns: "",
    postal_code_ns: "",
    address_ns: "",
    privacy_acceptance: false,
  });
  const [success, setSuccess] = useState(false);

  const [step, setStep] = useState(0);

  const goBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (step > 0 && step < STEPS.length) setStep((state) => state - 1);
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  console.log(query.get("name"));
  const goAhead = (newData) => {
    console.log(step, "steps", STEPS);
    setData((prev) => ({ ...prev, ...newData }));
    if (step >= 0 && step < STEPS.length - 1) setStep((state) => state + 1);
  };
  console.log("data", data);
  const goToStep = (index) => {
    // todo - if visited only
    if (index < step) setStep(index);
  };

  const onSubmit = () => {
    ScrollUp();
    setSuccess(true);
  };

  const RenderStepPage = () => {
    let Component = <div></div>;

    switch (STEPS[step].key) {
      case "insurance_information":
        Component = <InsurenceStep goAhead={goAhead} data={data} />;
        break;

      case "caregiver_information":
        Component = (
          <CareGiverStep goAhead={goAhead} goBack={goBack} data={data} />
        );
        break;

      case "shipping_address":
        Component = (
          <ShippingStep goAhead={goAhead} goBack={goBack} data={data} />
        );
        break;

      case "confirmation":
        Component = (
          <ConfirmationStep onSubmit={onSubmit} goBack={goBack} data={data} />
        );
        break;

      default:
        break;
    }

    return Component;
  };

  // useEffect(() => {
  //   // if (!product_id) navigate("/pflegebox-bestellen");

  //   try {
  //     const stepValue = STEPS.findIndex((item) => item.key === step_key);
  //     if (stepValue >= 0) setStep(stepValue);
  //   } catch (err) {}
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    dispatch(changeStep(STEPS[step].key));
    ScrollUp();
    // eslint-disable-next-line
  }, [step]);

  if (success) return <ThankYouPage />;

  return (
    <div className="max-w-full relative pt-6">
      <Heart className="absolute left-2 top-[40%] opacity-40 w-32" />
      <div className="max-w-7xl px-4 md:px-16 mx-auto">
        <div className="relative space-y-10 text-center pb-10 flex flex-col justify-center items-center">
          <Dots className="absolute  w-24 h-24 left-0 top-0 " />
          <div className="relative mx-auto max-w-max">
            <div className="flex flex-col  items-center">
              <Text type="h2">
                <span className="text-secondary">Checkout</span>
              </Text>
              <CurveLine className="w-40" />
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center space-y-10 ">
            <div className="flex flex-wrap flex-col space-y-2 md:space-y-0 md:flex-row">
              {STEPS.map((item, key) => {
                return (
                  <Stepper
                    key={key}
                    active={step === key}
                    onClick={() => goToStep(key)}
                  >
                    {item.title}
                  </Stepper>
                );
              })}
            </div>
            {/* content */}
            <RenderStepPage />
          </div>
        </div>
      </div>

      <BookMe />
    </div>
  );
};

export default CheckoutPage;
