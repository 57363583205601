import React from "react";

import * as Button from "components/Button";
import Text from "components/Text";
import Input from "components/Input";
import Divider from "components/Divider";
import { useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const CareGiverSchema = Yup.object().shape({
  first_name_cg: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Pflichtfeld *"),
  last_name_cg: Yup.string().required("Pflichtfeld *"),
  street_no_cg: Yup.string().required("Pflichtfeld *"),
  house_no_cg: Yup.string().required("Pflichtfeld *"),
  postal_code_cg: Yup.string().required("Pflichtfeld *"),
  address_cg: Yup.string().required("Pflichtfeld *"),
  phone_cg: Yup.string().required("Pflichtfeld *"),
  email_cg: Yup.string().email("Invalid email").required("Pflichtfeld *"),
});
const CareGiverStep = ({ goAhead, goBack, data }) => {
  const [careGiverDetail, setCaregiverDetail] = useState(data.detail_cg);
  const handleChange = () => {
    setCaregiverDetail(!careGiverDetail);
  };
  const handleSubmit = (values) => {
    console.log("caregiver values", values);
    goAhead(values);
  };
  return (
    <Formik
      validationSchema={careGiverDetail ? CareGiverSchema : ""}
      initialValues={data}
      onSubmit={handleSubmit}
    >
      {({}) => (
        <Form className="space-y-12 w-full lg:px-10 sm:px-5">
          <div>
            <div className="space-y-2">
              <Text type="h3">Angehörige(r)/Pflegeperson (optional)</Text>
              <div className="flex justify-center items-center py-4">
                <label
                  for="default-toggle"
                  class="inline-flex relative items-center cursor-pointer"
                >
                  <Field
                    name="detail_cg"
                    render={({ field, form }) => {
                      return (
                        <input
                          type="checkbox"
                          id="default-toggle"
                          class="sr-only peer"
                          {...field}
                          onClick={handleChange}
                          checked={field.value}
                        />
                      );
                    }}
                  />

                  <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span class="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                    Sollen die Daten einer Pflegeperson erfasst werden, zum
                    Beispiel um eine Bestätigungsmail zu versenden?
                  </span>
                </label>
              </div>
            </div>
            {careGiverDetail && (
              <>
                {" "}
                <section className="flex w-full text-left relative flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="relative space-y-4 m-2">
                      <Text type="h4">Vorname *</Text>
                      <Field
                        name="first_name_cg"
                        placeholder="Vorname *"
                        className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                      />{" "}
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="first_name_cg" />
                      </h4>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="relative space-y-4 m-2">
                      <Text type="h4">Nachname *</Text>
                      <Field
                        title="Nachname"
                        name="last_name_cg"
                        placeholder="Nachname *"
                        className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                      />{" "}
                      <h4 className="text-red-500 font-bold">
                        <ErrorMessage name="last_name_cg" />
                      </h4>
                    </div>
                  </div>
                </section>
                <section className="w-full text-left  relative space-y-10">
                  <div className="w-full flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="relative space-y-4 m-2">
                        <Text type="h4">Straße *</Text>
                        <Field
                          name="street_no_cg"
                          placeholder=" Straße * "
                          className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                        />
                        <h4 className="text-red-500 font-bold">
                          <ErrorMessage name="street_no_cg" />
                        </h4>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <div className="relative space-y-4 m-2">
                        <Text type="h4">Hausnummer * </Text>
                        <Field
                          name="house_no_cg"
                          placeholder="Hausnummer *"
                          className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                        />{" "}
                        <h4 className="text-red-500 font-bold">
                          <ErrorMessage name="house_no_cg" />
                        </h4>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <div className="relative space-y-4 m-2">
                        <Text type="h4">PLZ * </Text>
                        <Field
                          name="postal_code_cg"
                          placeholder="PLZ * "
                          className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                        />{" "}
                        <h4 className="text-red-500 font-bold">
                          <ErrorMessage name="postal_code_cg" />
                        </h4>
                      </div>
                    </div>
                    <div className="w-full  md:w-1/2">
                      <div className="relative space-y-4 m-2">
                        <Text type="h4">Ort * </Text>
                        <Field
                          name="address_cg"
                          placeholder="Ort *"
                          className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                        />{" "}
                        <h4 className="text-red-500 font-bold">
                          <ErrorMessage name="address_cg" />
                        </h4>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="relative space-y-4 m-2">
                        <Text type="h4">E-Mail-Adresse * </Text>
                        <Field
                          type="email"
                          name="email_cg"
                          placeholder="E-Mail-Adresse *"
                          className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                        />{" "}
                        <h4 className="text-red-500 font-bold">
                          <ErrorMessage name="email_cg" />
                        </h4>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <div className="relative space-y-4 m-2">
                        <Text type="h4">Telefonnummer *</Text>
                        <Field
                          type="tel"
                          name="phone_cg"
                          placeholder="Telefonnummer *"
                          className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                        />{" "}
                        <svg
                          className="absolute w-4 h-4 right-4 top-9"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.4809 14.4752C18.3172 14.4752 17.1747 14.2932 16.092 13.9354C15.5615 13.7544 14.9093 13.9205 14.5855 14.253L12.4485 15.8662C9.97015 14.5433 8.44354 13.0172 7.13867 10.5574L8.70442 8.47611C9.11122 8.06986 9.25713 7.47642 9.08232 6.9196C8.72299 5.83122 8.54045 4.68923 8.54045 3.52512C8.5405 2.68416 7.85634 2 7.01543 2H3.52507C2.68416 2 2 2.68416 2 3.52507C2 13.1641 9.84181 21.0059 19.4809 21.0059C20.3218 21.0059 21.0059 20.3217 21.0059 19.4808V16.0002C21.0059 15.1593 20.3217 14.4752 19.4809 14.4752Z"
                            fill="#A4A9A7"
                          />
                        </svg>{" "}
                        <h4 className="text-red-500 font-bold">
                          <ErrorMessage name="phone_cg" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
            <Button.Secondary onClick={goBack}>Zurück</Button.Secondary>
            <Button.Primary type="submit">Weiter zu Schritt 03</Button.Primary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CareGiverStep;
