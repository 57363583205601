import React, { Component, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { push } from "@socialgouv/matomo-next";
import Logo from "assets/svg/logo";

const JsCookie = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const { debug } = props;
    if (Cookies.get("consent") === undefined) {
      document.body.style.overflow = "hidden";
      setVisible(true);
    }
    if (
      window.location.href.includes("privacy-policy") ||
      window.location.href.includes("site-notice")
    ) {
      document.body.style.overflow = "scroll";
    } else if (Cookies.get("consent") === undefined) {
      document.body.style.overflow = "hidden";
    }
  });
  const accept = () => {
    Cookies.set("consent", true, { sameSite: "strict", expires: 365 });
    //enableGoogleAnalytics();
    //enableGoogleAdsense();
    push(["trackEvent", "consent", "true"]);
    setVisible(false);
    document.body.style.overflow = "scroll";
  };

  const decline = () => {
    Cookies.set("consent", false, { sameSite: "strict", expires: 365 });
    //window['ga-disable-GA_MEASUREMENT_ID'] = true;
    push(["trackEvent", "consent", "false"]);
    setVisible(false);
    document.body.style.overflow = "scroll";
  };
  return (
    <>
      {" "}
      {visible && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          class="block overflow-y-auto overflow-x-hidden fixed flex justify-items-center items-center	 justify-center z-50 p-4 w-full md:inset-0 h-modal md:h-full top-0"
        >
          <div class="relative w-full max-w-2xl h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <Logo className="h-12 w-12" />
              </div>

              <div class="p-6 space-y-6 ">
                <h3 className="font-[Poppins] font-bold text-xl text-gray-800">
                  Wir verwenden Cookies
                </h3>
                <p class="text-lg sm:text-md text-gray-700">
                  Wir nutzen auf unserer Website verschiedene Technologien von
                  Dritten. Diese helfen uns dabei, unsere Dienste
                  bereitzustellen und kontinuierlich zu verbessern. Mit dem
                  Klick auf "Alles akzeptieren" stimmen Sie allen Cookies zu.
                  Ihre Einwilligung können Sie jederzeit mit Wirkung für die
                  Zukunft widerrufen oder ändern.
                </p>
              </div>

              <div class="flex flex-col md:flex-row items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  onClick={accept}
                  data-modal-toggle="defaultModal"
                  type="button"
                  className="m-2 p-3 px-7 bg-primary rounded-lg text-white cursor-pointer hover:bg-secondary w-full md:w-2/4"
                >
                  Alle Cookies akzeptieren
                </button>
                <button
                  onClick={decline}
                  data-modal-toggle="defaultModal"
                  type="button"
                  className="w-full md:w-1/4	 m-2 p-3 px-7 bg-secondary rounded-lg text-white cursor-pointer hover:bg-primary"
                >
                  Ablehnen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JsCookie;
