import React from "react";

const Stepper = ({ active, onClick, children }) => {
  return (
    <div
      className={`stepper py-4 px-10 min-w-[280px] ${
        active ? "bg-primary text-white" : "bg-gray-300 text-gray-500"
      } cursor-pointer -mr-4 text-xs md:text-medium`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Stepper;
