export const Products = [
  {
    id: 1,
    title: "Pflegebox 1",
    badge: "basic",
    image: "/box.png",
    checklist: [
      {
        name: " 25 Stk. Bettschutzeinlagen",
        active: false,
      },

      {
        name: "500 ml Händedesinfektionsmittel",
        active: true,
      },
      {
        name: "500 ml Flächendesinfektionsmittel",
        active: true,
      },
      {
        name: "100 Stk. Schutzschürzen (kurz)",
        active: true,
      },
      {
        name: "200 Stk. Einmalhandschuhe",
        active: true,
      },
    ],
    size: [
      { name: "productOne", value: "S" },
      { name: "productOne", value: "M" },
      { name: "productOne", value: "L" },
    ],
  },
  {
    id: 2,
    title: "Pflegebox 2",
    badge: "pro",
    image: "/box.png",
    checklist: [
      {
        name: " 50 Stk. Bettschutzeinlagen",
        active: true,
      },

      {
        name: "500 ml Händedesinfektionsmittel",
        active: true,
      },
      {
        name: "500 ml Flächendesinfektionsmittel",
        active: true,
      },
      {
        name: "100 Stk. Schutzschürzen (lang)",
        active: false,
      },
      {
        name: "100 Stk. Einmalhandschuhe",
        active: true,
      },
    ],
    size: [
      { name: "productTwo", value: "S" },
      { name: "productTwo", value: "M" },
      { name: "productTwo", value: "L" },
    ],
  },
  {
    id: 3,
    title: "Pflegebox 3",
    badge: "premium",
    image: "/box.png",
    checklist: [
      {
        name: "500 ml Händedesinfektionsmittel",
        active: true,
      },
      {
        name: "50 Stk. Mundschutz",
        active: true,
      },
      {
        name: "500 ml Flächendesinfektionsmittel",
        active: true,
      },
      {
        name: "100 Stk. Schutzschürzen (lang)",
        active: true,
      },
      {
        name: "100 Stk. Einmalhandschuhe",
        active: true,
      },
    ],
    size: [
      { name: "productThree", value: "S" },
      { name: "productThree", value: "M" },
      { name: "productThree", value: "L" },
    ],
  },
  {
    id: 4,
    title: "Pflegebox 4",
    badge: "star",
    image: "/box.png",
    checklist: [
      {
        name: "50 Stk. Bettschutzeinlagen",
        active: true,
      },

      {
        name: "500 ml Händedesinfektionsmittel",
        active: true,
      },
      {
        name: "500 ml Flächendesinfektionsmittel",
        active: false,
      },
      {
        name: "100 Stk. Schutzschürzen (lang)",
        active: false,
      },
      {
        name: "100 Stk. Einmalhandschuhe",
        active: true,
      },
    ],
    size: [
      { name: "productFour", value: "S" },
      { name: "productFour", value: "M" },
      { name: "productFour", value: "L" },
    ],
  },
  {
    id: 5,
    title: "Pflegebox 5",
    badge: "gold",
    image: "/box.png",
    checklist: [
      {
        name: "75 Stk. Bettschutzeinlagen",
        active: true,
      },
      {
        name: "100 Stk. Einmalhandschuhe",
        active: false,
      },

      {
        name: "500 ml Händedesinfektionsmittel",
        active: false,
      },
      {
        name: "500 ml Flächendesinfektionsmittel",
        active: false,
      },
      {
        name: "100 Stk. Schutzschürzen (kurz)",
        active: true,
      },
    ],
  },
  {
    id: 6,
    title: "Pflegebox 6",
    badge: "sliver",
    image: "/box.png",
    checklist: [
      {
        name: " 25 Stk. Bettschutzeinlagen",
        active: true,
      },

      {
        name: "500 ml Händedesinfektionsmittel",
        active: true,
      },
      {
        name: "500 ml Flächendesinfektionsmittel",
        active: true,
      },
      {
        name: "100 Stk. Schutzschürzen (lang)",
        active: true,
      },
      {
        name: "100 Stk. Einmalhandschuhe",
        active: true,
      },
    ],
    size: [
      { name: "productSix", value: "S" },
      { name: "productSix", value: "M" },
      { name: "productSix", value: "L" },
    ],
  },
];
