/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";

import Logo from "assets/svg/logo";
import { useSelector } from "react-redux";

const pages = [
  { title: "Startseite", href: "/" },
  { title: "Pflegebox bestellen", href: "/pflegebox-bestellen" },
  { title: "Wissenswertes", href: "/wissenswertes" },
  { title: "Kontakt", href: "/kontakt" },
];

const Navbar = () => {
  const HeaderFill = useSelector((state) => state.app.header.filled);

  return (
    <Popover className={`w-full ${HeaderFill ? "bg-light" : "bg-gray-50"}`}>
      <div className="max-w-7xl mx-auto md:px-10 sm:px-6">
        <div className="flex justify-between items-center px-4 py-4 md:px-10  ">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={"/"}>
              <span className="sr-only">PFLEGEBOX</span>
              <Logo className="h-16 w-16" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-light rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-light focus:outline-none">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-8">
            {pages.map((item, key) => {
              return <PageLink key={key} item={item} />;
            })}
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-light divide-y-2 divide-secondary divide-opacity-20">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Logo className="h-12 w-12" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-light rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-light focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="flex flex-col space-y-4">
                {pages.map((item, key) => {
                  return <PageLink key={key} item={item} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Popover>
  );
};

export default Navbar;

const PageLink = ({ item }) => {
  // Location hook
  const location = useLocation();
  const active = location.pathname === item.href;

  return (
    <Link
      to={item.href}
      className={`relative text-lg font-[Inter]  ${
        active ? "text-secondary font-semibold" : "text-gray-600 "
      }hover:text-secondary `}
    >
      <div className="flex flex-col items-center">
        {item.title}{" "}
        {active && (
          <span className="border-t-4 border-secondary w-4 rounded-sm" />
        )}
      </div>
    </Link>
  );
};
