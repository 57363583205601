import Dropbox from "assets/svg/dropbox";
import Working from "assets/svg/working";
import Delivery from "assets/svg/delivery";

export const informationOne = [
  {
    id: 1,
    title: "Caption",
    img: "/faq1.jpg",
    description: [
      "Ein Pflegegrad gibt Auskunft über den Umfang der Pflegebedürftigkeit einer Person. Je höher der Pflegegrad eingestuft ist, desto geringer ist die Selbständigkeit einer Person im Alltag. So ist etwa im Rahmen des höchsten Pflegegrades der selbstbestimmte Alltag kaum noch möglich.",
    ],
  },
];
export const informationTwo = [
  {
    id: 2,
    title: "Caption",
    description: [
      "Ein Pflegegrad gibt Auskunft über den Umfang der Pflegebedürftigkeit einer Person. Je höher der Pflegegrad eingestuft ist, desto geringer ist die Selbständigkeit einer Person im Alltag. So ist etwa im Rahmen des höchsten Pflegegrades der selbstbestimmte Alltag kaum noch möglich.",
    ],
  },
];
export const informationThree = [
  {
    id: 3,
    img: "/faq3.jpg",
    title: "Caption",
    description: [
      "Um einen Pflegegrad zu erhalten, muss zunächst ein Antrag auf Feststellung der Pflegebedürftigkeit gestellt werden. Das geht entweder bei der Pflegekasse oder bei der Krankenversicherung. Ein formloses Anschreiben genügt und gelangen automatisch zum passenden Ansprechpartner.",
    ],
  },
];
export const informationFour = [
  {
    id: 4,
    title: "Caption",
    img: "/faq4.jpg",
    description: [
      "Damit die Kosten für Pflegehilfsmittel zum Verbrauch von der Pflegekasse übernommen werden, muss die pflegebedürftige Person bestimmte Voraussetzungen erfüllen. Der Anspruch richtet sich nach den folgenden Voraussetzungen:",
      "- Anspruchsteller ist ein pflegebedürftiger Mensch (§ 14 SGB XI)",
      "- Es liegt ein anerkannten Pflegegrad 1 bis 5 (§ 15 SGB XI) vor",
      "- Pflegebedürftige Person wird im häuslichen Umfeld gepflegt",
      "- Die Pflege wird von einem Angehörigen (Pflegeperson) und / oder einem Pflegedienst durchgeführt",
    ],
  },
];
export const sections = [
  { id: 1, title: "Was ist ein Pflegegrad?", data: informationOne },
  {
    id: 2,
    title: "Übersicht aller Pflegegrade",
    data: informationTwo,
  },
  { id: 3, title: "Antrag auf Pflegebedürftigkeit", data: informationThree },
  { id: 4, title: "Anspruch auf die Pflegebox-Daheim", data: informationFour },
];

// HOME PAGE
export const HowItWorksTracker = [
  {
    title: "Pflegebox auswählen oder individuell zusammenstellen",
    icon: "/boxOne.png",
  },
  {
    title: "Formulare ausfüllen und unterschreiben",
    icon: "/laptop.png",
  },
  {
    title:
      "Unser Serviceteam kümmert sich um die Formalitäten mit der Pflegekasse",
    icon: "/chat.png",
  },
  {
    title: "Versandkostenfreie Lieferung",
    icon: "/delivery.png",
  },
];
export const Benifits = [
  {
    title: "ZEITERSPARNIS",
    description: [
      "Wir kümmern uns um alle notwendigen Formalitäten mit dem Kostenträger. Die Pflegehilfsmittel werden direkt zu Ihnen nach Hause geliefert.",
    ],
    img: "/chronograph-watch.png",
  },
  {
    title: "QUALITÄT",
    description: [
      "Es werden nur geprüfte und qualitativ hochwertige Produkte ausgeliefert.",
    ],
    img: "/quality.png",
  },
  {
    title: "KOSTENERSPARNIS",
    description: [
      "Nach Genehmigung des Kostenantrags übernimmt die Pflegekasse die Kosten der Pflegehilfsmittel im Wert von bis zu 40€. Damit sparen Sie bis zu 480€ pro Jahr!",
    ],
    img: "/piggy-bank.png",
  },
  {
    title: "EINFACHE ABWICKLUNG",
    description: [
      "Kein Rezept vom Arzt notwendig! Antrag ausfüllen, unterschreiben und absenden. ",
    ],
    img: "/cloud.png",
  },
  {
    title: "INDIVIDUELLE ANPASSUNG",
    description: [
      " Die Produkte der Pflegebox können je nach Bedarf angepasst werden.",
    ],
    img: "/customize.png",
  },
  {
    title: "INTERNATIONALER KUNDENSERVICE",
    description: [
      "Telefonische Beratung auf Deutsch, Türkisch, Russisch. Kontaktieren Sie uns, wir helfen Ihnen gerne! ",
    ],
    img: "/headphones.png",
  },
];
