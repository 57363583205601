import React from "react";

import ProgressBar from "components/Progress";
import Container from "components/Container";
import Text from "components/Text";
import * as Button from "components/Button";

import CurveLine from "assets/svg/curve";
import Dots from "assets/svg/dots";
import Heart from "assets/svg/heart";
import { Link, useNavigate } from "react-router-dom";
import { Supplies } from "db/Supplies";
import { useDispatch, useSelector } from "react-redux";
import { minusExtraItem, plusExtraItem } from "app/slice/appSlice";
import { useState } from "react";
import { useEffect } from "react";

const CustomizerPage = () => {
  const navigate = useNavigate();

  // const goAhead = () => navigate("/checkout");
  const goBack = () => navigate(-1);
  const [total, setTotal] = useState(JSON.parse(localStorage.getItem("total")));
  const [complete, setComplete] = useState(0);
  const [fullproduct, setFullproduct] = useState([]);
  const [productopacity, setProductopacity] = useState(false);
  const productTotalCheck = (item) => {
    let totalPrice = JSON.parse(localStorage.getItem("total"));
    let ProductExists = fullproduct.find((pr) => pr === item.id);
    if (Number(item.price) + totalPrice > 40) {
      if (ProductExists) {
      } else {
        setFullproduct((prev) => [...prev, item.id]);
      }

      console.log(
        "not added this product anymore" + `${item.id}`,
        ProductExists
      );
    }
    if (Number(item.price) + totalPrice < 40) {
      setFullproduct((prev) => prev.filter((product) => product !== item.id));
    }
  };

  useEffect(() => {
    setTotal(total);
    if (total > 40) {
      setComplete(100);
      setProductopacity(true);
    } else {
      setComplete(((total / 44) * 100).toFixed(2));
      // setProductopacity(false);
      Supplies.forEach(productTotalCheck);
    }
  }, [total]);
  console.log("fullproduct", fullproduct);
  return (
    <div className="relative pt-20 ">
      <Heart className="absolute left-2 top-[40%] opacity-40 w-32" />
      <Container>
        <div className="relative space-y-10 text-center pb-10 flex flex-col justify-center items-center">
          <div className="w-full max-w-5xl space-y-6  flex flex-col justify-center items-center  sticky top-0 bg-slate-50	">
            {" "}
            <Dots className="absolute  w-24 h-24 left-0 top-0" />
            <div className="relative mx-auto max-w-max  ">
              <Text type="h2">
                Stellen Sie Ihre Pflegebox individuell zusammen
              </Text>
              <CurveLine className="absolute right-0 w-36" />
            </div>
            <div className="w-full">
              <h3 className="text-left	m-2">Füllstand Ihrer Box</h3>
              <ProgressBar progress={complete} />
            </div>
          </div>

          <div className="  w-full max-w-5xl space-y-6">
            <h3 className="font-[Poppins] font-bold text-lg md:text-lg text-gray-600 md:text-left">
              Wählen Sie hier Ihre Produkte
            </h3>
            {Supplies.map((item, key) => {
              return (
                <Item
                  productopacity={productopacity}
                  fullproduct={fullproduct}
                  key={key}
                  data={item}
                  total={total}
                  setTotal={setTotal}
                />
              );
            })}
          </div>
          <div className="flex">
            <Button.Secondary onClick={goBack}>Zurück</Button.Secondary>
            {total > 0 && (
              <Link to={"/checkout?name=customize-box"}>
                <Button.Primary>Jetzt bestellen</Button.Primary>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CustomizerPage;

const Item = ({ data, total, setTotal, productopacity, fullproduct }) => {
  const extra = useSelector((state) => state.app.cart.extra);
  const [qty, setQty] = useState(0);
  const [btn, setBtn] = useState(false);
  const value = extra[data.id] ? extra[data.id] : 0;
  const [opacity, setOpacity] = useState(false);

  // const plus = () => dispatch(plusExtraItem(data));
  // const minus = () => dispatch(minusExtraItem(data));

  const plus = (id) => {
    const currentItems = getItemsFromCart();
    const cItem = currentItems.find((item) => item.id == id);

    if (currentItems.length === 0 || !cItem) {
      //add item to cart and save using setItemsToCartFunction

      let product = products.find((product) => {
        return product.id == id;
      });

      let totalPrice = JSON.parse(localStorage.getItem("total"));
      if (Number(product.price) + totalPrice > 40) {
        setBtn(true);
        setOpacity(true);
        console.log("you cannot add more products");
        return;
      } else {
        if (Number(product.price) + Number(product.price) + totalPrice > 40) {
          setBtn(true);
          setOpacity(true);
        } else {
          setBtn(false);
          setOpacity(false);
        }
        currentItems.push(product);
        saveItemsToCart(currentItems, id);
        getTotal();

        console.log("you can add more products");
      }
    } else {
      let totalPrice = JSON.parse(localStorage.getItem("total"));

      let product = currentItems.find((product) => {
        return product.id == id;
      });
      if (Number(product.price) + totalPrice > 40) {
        setOpacity(true);
        setBtn(true);
        console.log("you cannot add more products");
        return;
      } else {
        if (Number(product.price) + Number(product.price) + totalPrice > 40) {
          setBtn(true);
          setOpacity(true);
        } else {
          setBtn(false);
          setOpacity(false);
        }
        const updatedProduct = currentItems.map((item) => {
          if (item.id === id) {
            item.quantity++;
          }
          return item;
        });

        saveItemsToCart(updatedProduct, id);
        getTotal();

        console.log("2nd add ", totalPrice);
      }
    }

    console.log(btn, "btn status");
  };

  const minus = (id) => {
    const currentItems = getItemsFromCart();
    const item = currentItems.find((item) => item.id == id);
    if (!item) return;
    if (item.quantity === 1) {
      const filteredItems = currentItems.filter((item) => item.id != id);
      saveItemsToCart(filteredItems, id);
    } else {
      const filteredItems = currentItems.map((item) => {
        if (item.id == id) {
          item.quantity -= 1;
        }
        return item;
      });

      saveItemsToCart(filteredItems, id);
    }
    getTotal();
    let totalPrice = JSON.parse(localStorage.getItem("total"));
    if (totalPrice < 40) {
      setOpacity(false);
      setBtn(false);
    }
  };
  const getItemsFromCart = () => {
    const temp = localStorage.getItem("cart");
    try {
      const arr = JSON.parse(temp);
      if (!Array.isArray(arr)) {
        return [];
      }
      return arr;
    } catch (err) {
      return [];
    }
  };
  const saveItemsToCart = (data, id) => {
    localStorage.setItem("cart", JSON.stringify(data));
    const item = data.find((item) => item.id == id);
    setQty(item ? item.quantity : 0);
  };

  localStorage.setItem("products", JSON.stringify(Supplies));
  if (!localStorage.getItem("cart")) {
    localStorage.setItem("cart", "[]");
  }
  let products = JSON.parse(localStorage.getItem("products"));
  let cart = JSON.parse(localStorage.getItem("cart"));

  const getTotal = () => {
    const currentItems = getItemsFromCart();
    let sum = 0;

    if (currentItems.length === 0) {
      localStorage.setItem("total", JSON.stringify(0));
      setTotal(0);
    } else {
      currentItems.map(function (item) {
        sum += item.price * item.quantity;
      });
      localStorage.setItem("total", JSON.stringify(sum));
      setTotal(sum);
      console.log(sum, "totalsum");
    }
  };
  console.log("fullproduct=======", fullproduct);
  useEffect(() => {
    let totalPrice = JSON.parse(localStorage.getItem("total"));
    if (totalPrice < 10) {
      setOpacity(false);
    }
  }, [minus, plus]);
  return (
    <div
      className={
        "flex-col w-full flex justify-between items-center border-b-2 pb-2  md:border-b-0 md:flex-row  "
      }
    >
      <div
        className={
          productopacity || fullproduct.includes(data.id)
            ? "opacity-25 disabled cursor-not-allowed flex items-center space-x-4"
            : "w-full flex items-center justify-starts  space-x-4  "
        }
      >
        <img
          className="w-24 h-24 object-cover rounded-xl border border-light"
          src={data.image}
          alt="box-order"
        />
        <h3 className="font-[Poppins] font-bold text-lg md:text-lg text-gray-600 md:text-left">
          {data.title}
        </h3>
        <br />
      </div>{" "}
      <div className="flex  md:flex-row border-2 sm:flex-row">
        <button
          onClick={() => minus(data.id)}
          className=" p-3 border-b-2 md:border-b-0  md:border-r-2 cursor-pointer hover:bg-white flex justify-center items-center"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 0C3.80568 0 0 3.80568 0 8.5C0 13.1943 3.80568 17 8.5 17C13.1943 17 17 13.1943 17 8.5C17 3.80568 13.1943 0 8.5 0ZM5.40909 7.72727C5.20415 7.72727 5.0076 7.80868 4.86269 7.9536C4.71778 8.09851 4.63636 8.29506 4.63636 8.5C4.63636 8.70494 4.71778 8.90149 4.86269 9.0464C5.0076 9.19131 5.20415 9.27273 5.40909 9.27273H11.5909C11.7958 9.27273 11.9924 9.19131 12.1373 9.0464C12.2822 8.90149 12.3636 8.70494 12.3636 8.5C12.3636 8.29506 12.2822 8.09851 12.1373 7.9536C11.9924 7.80868 11.7958 7.72727 11.5909 7.72727H5.40909Z"
              fill="#4B66AE"
            />
          </svg>
        </button>
        <div className="p-3 px-6">
          <Text>{qty}</Text>
        </div>
        <button
          onClick={() => plus(data.id)}
          className={
            productopacity || fullproduct.includes(data.id)
              ? " disabled cursor-not-allowed p-3 border-t-2 md:border-t-0 md:border-l-2 cursor-pointer hover:bg-white flex justify-center items-center"
              : "p-3 border-t-2 md:border-t-0 md:border-l-2 cursor-pointer hover:bg-white flex justify-center items-center"
          }
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 0C3.80568 0 0 3.80568 0 8.5C0 13.1943 3.80568 17 8.5 17C13.1943 17 17 13.1943 17 8.5C17 3.80568 13.1943 0 8.5 0ZM9.27273 11.5909C9.27273 11.7958 9.19131 11.9924 9.0464 12.1373C8.90149 12.2822 8.70494 12.3636 8.5 12.3636C8.29506 12.3636 8.09851 12.2822 7.9536 12.1373C7.80868 11.9924 7.72727 11.7958 7.72727 11.5909V9.27273H5.40909C5.20415 9.27273 5.0076 9.19131 4.86269 9.0464C4.71778 8.90149 4.63636 8.70494 4.63636 8.5C4.63636 8.29506 4.71778 8.09851 4.86269 7.9536C5.0076 7.80868 5.20415 7.72727 5.40909 7.72727H7.72727V5.40909C7.72727 5.20415 7.80868 5.0076 7.9536 4.86269C8.09851 4.71778 8.29506 4.63636 8.5 4.63636C8.70494 4.63636 8.90149 4.71778 9.0464 4.86269C9.19131 5.0076 9.27273 5.20415 9.27273 5.40909V7.72727H11.5909C11.7958 7.72727 11.9924 7.80868 12.1373 7.9536C12.2822 8.09851 12.3636 8.29506 12.3636 8.5C12.3636 8.70494 12.2822 8.90149 12.1373 9.0464C11.9924 9.19131 11.7958 9.27273 11.5909 9.27273H9.27273V11.5909Z"
              fill="#4B66AE"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
