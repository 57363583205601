import React from "react";
import Text from "./Text";

const Faq2 = () => {
  return (
    <div className="relative flex-col	 md:w-full flex  z-10 ">
      <h2 className="text-center font-[Poppins] font-bold text-xl text-gray-800">
        {" "}
        Übersicht der Pflegegrade
      </h2>
      <p className="m-2 text-lg sm:text-md text-gray-700">
        Mit der Einführung des 2. Pflegestärkungsgesetzes im Jahr 2017 (PSG II)
        wurde das gesamte Pflegesystem für Betroffene, deren Angehörige und
        Pflegekräfte durch die umfassende Gesetzesänderung verbessert.
        Insbesondere <b>Menschen mit Demenz</b> und{" "}
        <b>eingeschränkter Alltagskompetenz</b>, sowie in häuslicher Umgebung
        gepflegte Personen, profitieren von den neuen Gesetzen.
      </p>
      <p className="m-2 text-lg sm:text-md text-gray-700">
        Die Pflegebedürftigkeit einer Person wird nun anhand von 5 Pflegegraden
        (PG) festgelegt. Die Feststellung des vorliegenden Pflegegrades
        übernimmt bei gesetzlich Versicherten der medizinische Dienst der
        Krankenkassen (MDK) und bei privatversicherten MEDICPROOF.
      </p>{" "}
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        In einem persönlichen Gespräch ermittelt der Gutachter anhand eines
        Fragebogens die Selbständigkeit und die noch vorhandenen Fähigkeiten der
        Betroffenen. Dabei geht er unteranderem auf den körperlichen Zustand,
        das Orientierungsvermögen und die geistige Verfassung der Betroffenen
        ein. Im Zentrum der Bewertung einer Pflegebedürftigkeit steht also die
        Frage, wie groß ihre <b>Selbständigkeit im Alltag eingeschränkt</b> ist.
      </p>
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800 text-center">
        PG 1
      </h3>
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        Bei PG 1 liegt eine „geringe Beeinträchtigung der Selbständigkeit“ bei
        den Betroffenen vor. Meistens liegen diese Beeinträchtigungen im
        körperlichen Bereich. Wurde der PG 1 anerkannt, stehen dem
        Pflegebedürftigen oder den pflegenden Angehörigen verschiedene Geld- und
        Sachleistungen der Pflegeversicherung zu.
      </p>
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800 text-center">
        PG 2
      </h3>
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        Bei einem PG 2 hat die pflegebedürftige Person „erhebliche
        Beeinträchtigungen der Selbständigkeit und der Fähigkeiten“. Die
        Selbstständigkeit muss also durch körperliche oder geistige
        Einschränkungen bereits deutlich verringert sein. Die
        Pflegebedürftigkeit muss für voraussichtlich mindesten 6 Monate
        bestehen. Pflegebedürftigen Personen, mit anerkanntem PG 2, stehen noch
        mehr Geld- und Sachleistungen der Pflegeversicherung zu.
      </p>
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800 text-center">
        PG 3 – PG 5
      </h3>
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        Die Einstufung der pflegebedürftigen Person in einen der höheren
        Pflegegrade kann sowohl beim Erstantrag als auch bei Folgeanträgen durch
        Hoch- oder Herabstufung erfolgen. Wird der Pflegebedürftige bereits beim
        Erstantrag in PG 3 eingestuft, ist in den meisten Fällen eine{" "}
        <b>schwere Erkrankung </b>vorausgegangen und es liegt eine starke
        Beeinträchtigung der Selbstständigkeit vor. Da die Pflegebedürftigkeit
        bereits ab PG 3 sehr stark ausgeprägt ist, haben die Betroffenen einen
        Anspruch&nbsp;
        <b>
          auf alle zur Verfügung stehenden Geld- als auch Sachleistungen der
          Pflegekasse
        </b>
        .
      </p>
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800 text-center">
        Übersicht der monatlichen Leistungen der Pflegekasse
      </h3>
      {/* <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-2 lg:-mx-4">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                    ></th>
                    <th
                      scope="col"
                      class="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                    >
                      PG 3
                    </th>
                    <th
                      scope="col"
                      class="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                    >
                      PG 3 mit e.A.
                    </th>
                    <th
                      scope="col"
                      class="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                    >
                      PG 4
                    </th>{" "}
                    <th
                      scope="col"
                      class="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                    >
                      Härtefall
                    </th>{" "}
                    <th
                      scope="col"
                      class="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                    >
                      PG 5
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td class="px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-900">
                      Pflegegeld
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      728€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      728€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      728€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      728€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      901€
                    </td>
                  </tr>
                  <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td class="px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-900">
                      Pflegesach-leistungen
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.612€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.693€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.612€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.995€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      2.095€
                    </td>
                  </tr>
                  <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td class="px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-900">
                      Tages- und Nachtpflege
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.612€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.995€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.612€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.612€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.995€
                    </td>
                  </tr>
                  <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    <td class="px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-900">
                      Stationäre Pflege
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.612€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.775€
                    </td>
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.995€
                    </td>{" "}
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      1.995€
                    </td>{" "}
                    <td class="text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                      2.005€
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="container">
        <table class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
          <thead class="text-black">
            <tr class="bg-gray-300 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <th class="p-3 text-left"></th>
              <th class="p-3 text-left">PG 3 </th>
              <th class="p-3 text-left">PG 3 mit e.A.</th>
              <th class="p-3 text-left">PG 4 </th>
              <th class="p-3 text-left">Härtefall</th>
              <th class="p-3 text-left">PG 5</th>
            </tr>
            <tr class="bg-gray-300 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <th class="p-3 text-left"> </th>
              <th class="p-3 text-left">PG 3 </th>
              <th class="p-3 text-left">PG 3 mit e.A.</th>
              <th class="p-3 text-left">PG 4 </th>
              <th class="p-3 text-left">Härtefall</th>
              <th class="p-3 text-left">PG 5</th>
            </tr>
            <tr class="bg-gray-300 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <th class="p-3 text-left"></th>
              <th class="p-3 text-left">PG 3 </th>
              <th class="p-3 text-left">PG 3 mit e.A.</th>
              <th class="p-3 text-left">PG 4 </th>
              <th class="p-3 text-left">Härtefall</th>
              <th class="p-3 text-left">PG 5</th>
            </tr>
            <tr class="bg-gray-300 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <th class="p-3 text-left"></th>
              <th class="p-3 text-left">PG 3 </th>
              <th class="p-3 text-left">PG 3 mit e.A.</th>
              <th class="p-3 text-left">PG 4 </th>
              <th class="p-3 text-left">Härtefall</th>
              <th class="p-3 text-left">PG 5</th>
            </tr>
          </thead>
          <tbody class="flex-1 sm:flex-none">
            <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
              <td class="border-grey-light border hover:bg-gray-100 p-3 text-lg font-bold text-gray-900">
                Pflegegeld
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                728€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                728€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                728€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                728€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                901€
              </td>
            </tr>
            <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
              <td class="border-grey-light border hover:bg-gray-100 p-3 text-lg font-bold text-gray-900">
                Pflegesach-leistungen
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.612€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.693€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.612€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.995€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                2.095€
              </td>
            </tr>{" "}
            <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
              <td class="border-grey-light border hover:bg-gray-100 p-3 text-lg font-bold text-gray-900">
                Tages- und Nachtpflege
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.612€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.995€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.612€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.612€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.995€
              </td>
            </tr>{" "}
            <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
              <td class="border-grey-light border hover:bg-gray-100 p-3 text-lg font-bold text-gray-900">
                Stationäre Pflege
              </td>
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.612€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.775€
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.995€{" "}
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                1.995€{" "}
              </td>{" "}
              <td class="border-grey-light border hover:bg-gray-100 p-3 truncate text-lg text-gray-900 font-light whitespace-nowrap">
                2.005€
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        Um einen Pflegegrad zu erhalten, muss zunächst ein{" "}
        <b>Antrag auf Feststellung der Pflegebedürftigkeit</b> gestellt werden.
        Das geht entweder bei der Pflegekasse oder bei der Krankenversicherung.
        Ein formloses Anschreiben genügt und Sie gelangen automatisch zum
        passenden Ansprechpartner.
        <br />
        <br /> Bei Ihrer Antragsstellung und dem weiteren Vorgehen beraten wir
        sie gerne!
      </p>
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800 text-center m-2 mt-4">
        Pflegebox-Daheim Pflegehilfsmittel
      </h3>
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        Die zu Hause gepflegten Pflegebedürftige mit PG 1 haben einen Anspruch
        auf Pflegehilfsmittel zum Verbrauch. Diese Leistung der
        Pflegeversicherung umfasst monatlich einen Betrag von 40€ und ist auch
        bei höheren Pflegegraden gleichbleibend.
        <br /> <br /> Unser Serviceteam von der Pflegebox-Daheim unterstützt Sie
        gerne bei der Auswahl der passenden Produkte.
      </p>
      <h3 className="font-[Poppins] font-bold text-xl text-gray-800 text-center m-2 mt-4">
        Hausnotruf-Systeme
      </h3>
      <p className="m-2 mb-5 text-lg sm:text-md text-gray-700">
        Die Pflegekasse unterstützt finanziell den Pflegebedürftigen auch bei
        der Einrichtung eines Hausnotrufsystems. Der Zuschuss für den einmaligen
        Anschluss sowie die monatlichen laufenden Kosten wurde ab dem 01.07.2021
        auf 25,50€ erhöht. Da es unterschiedliche Hausnotrufsysteme gibt, kommt
        es bei der Auswahl darauf an, wie mobil der Pflegebedürftige noch ist.
        <br />
        <br />
        Auch bei dieser Auswahl kann unser Serviceteam Sie unterstützen. Rufen
        Sie uns an.
      </p>
    </div>
  );
};

export default Faq2;
