import React from 'react';

const FirstAid = (props) => {
  return (
    <svg width='186' height='189' {...props} viewBox='0 0 186 189' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M148.922 49.7404L169.16 53.7838C174.605 54.8717 179.396 58.0817 182.478 62.7076C185.56 67.3335 186.682 72.9964 185.596 78.4505L166.939 172.141C165.841 177.588 162.634 182.378 158.019 185.464C153.405 188.549 147.758 189.678 142.314 188.604L16.8399 163.534C11.3949 162.446 6.60429 159.236 3.52206 154.611C0.439821 149.985 -0.681553 144.322 0.404504 138.868L19.0605 45.1776C19.5922 42.4739 20.6519 39.9021 22.1788 37.6099C23.7057 35.3176 25.6696 33.35 27.9578 31.8202C30.246 30.2905 32.8133 29.2286 35.5124 28.6957C38.2115 28.1627 40.9892 28.1692 43.6858 28.7147L63.8748 32.7483L64.5654 29.2802C66.465 19.808 72.0401 11.4783 80.0663 6.12042C88.0924 0.762564 97.9134 -1.18536 107.372 0.704488L121.075 3.44231C130.523 5.34338 138.833 10.9196 144.183 18.9484C149.532 26.9772 151.485 36.8033 149.613 46.2722L148.922 49.7404ZM85.5872 14.4064C79.755 18.2976 75.7022 24.3477 74.3185 31.2288L73.6279 34.6969L139.169 47.7917L139.86 44.3236C141.23 37.4444 139.815 30.3017 135.928 24.4672C132.04 18.6326 125.998 14.584 119.13 13.2119L105.427 10.474C98.5547 9.10099 91.4195 10.5153 85.5872 14.4064ZM106.439 101.389L132.285 106.553L127.664 129.755L101.819 124.592L96.6731 150.432L73.558 145.814L78.7035 119.973L52.8577 114.809L57.478 91.6067L83.3237 96.7706L88.4692 70.9301L111.584 75.5484L106.439 101.389Z'
        fill='#E8EEFF'
      />
    </svg>
  );
};

export default FirstAid;
