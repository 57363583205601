import React from "react";

import Text from "components/Text";
import Container from "components/Container";
import Tracker from "components/Tracker";
import * as Button from "components/Button";
import Section from "components/Section";

import CurveLine from "assets/svg/curve";
import Dots from "assets/svg/dots";

import { HowItWorksTracker, Benifits } from "utils/seeder";
import { Link } from "react-router-dom";
import BookMe from "components/common/BookMe";

const HomePage = () => {
  return (
    <React.Fragment>
      <div className="relative max-w-full mx-auto md:px-10 min-h-max pt-10 bg-light overflow-hidden flex flex-col items-center space-y-6">
        <div className="max-w-7xl flex-col md:flex-row lg:flex-row px-4 md:px-20 flex justify-start ">
          <div className="lg:w-2/5 md:w-full sm:w-full flex">
            {" "}
            <img
              className="  w-[600px]  h-[440px] object-cover py-4 md:py-0 lg:py-0 "
              src="/startseite.png"
              alt="doctor"
            />
          </div>
          <div className="lg:w-3/5 sm:w-full  flex flex-col items-center py-4 md:py-0 lg:py-0">
            <Text type="h1">
              Gut versorgt mit
              <br />{" "}
              <span className="text-secondary mb-5 leading-5	">
                {" "}
                Pflegebox-Daheim.
              </span>
            </Text>
            <CurveLine className="w-60 md:w-full mb-4 md:mb-4" />
            <p className="w-70 md:w-96 text-center text-lg mb-4 md:mb-5 px-4">
              Bestellen Sie jetzt kostenfrei eine Auswahl an Pflegehilfsmitteln
              im Wert von 40 Euro zu sich nach Hause!
            </p>

            <Link to="/pflegebox-bestellen" className="z-10 mb-2">
              <Button.Primary>Jetzt kostenfrei bestellen</Button.Primary>
            </Link>
          </div>
        </div>
      </div>

      <div className="max-w-full  md:px-10">
        <div className=" md:px-20 flex flex-col lg:flex-col-reverse justify-around items-center py-10">
          <div className="relative md:w-1/2 flex justify-center items-center ">
            <div className="absolute lg:h-80 aspect-video bg-secondary  -bottom-5 opacity-20"></div>
            <img
              className="h-full md:h-80 aspect-video z-10 object-cover"
              src="/sind-pflegeboxen.png"
              alt="Working"
            />
            <div className="hidden md:block absolute -top-12 -left-12 z-10">
              <Dots className="h-32 w-32" />
            </div>
          </div>
          <div className="md:w-1/2 flex flex-col justify-center items-center space-y-8 py-10 sm:py-20 text-center">
            <div className="max-w-7xl space-y-4 sm:space-y-1">
              <Text type="h2">
                Was
                <span className="text-secondary"> sind Pflegeboxen?</span>
              </Text>
            </div>
            <div className="max-w-7xl text-center px-4 space-y-4">
              <Text>
                Der Gesetzgeber hat die Versorgung mit zum Verbrauch bestimmter
                Pflegehilfsmittel im Sozialgesetzbuch geregelt. Demnach haben
                Pflegebedürftige einen gesetzlichen Anspruch auf 40€ für
                Pflegehilfsmittel, welche die Pflege von Pflegebedürftigen zu
                Hause erleichtern, angenehmer und sicherer machen. Einfach
                bestellen und jeden Monat die gewünschten Pflegehilfsmittel
                KOSTENLOS nach Hause geliefert bekommen!
              </Text>
            </div>
            <div className="px-4 md:px-0 lg:px-0">
              <Link to="/pflegebox-bestellen">
                <Button.Primary>Pflegebox bestellen</Button.Primary>
              </Link>
              <Link to="/kontakt">
                <Button.Secondary>
                  Kostenloses Beratungsgespräch buchen
                </Button.Secondary>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="max-w-full px-4 md:px-10">
          <div className="max-w-7xl md:px-20 mx-auto flex flex-col justify-center items-center space-y-8 py-10 md:py-20 lg:py-20 text-center">
            <Text type="h2">
              Ablauf der <span className="text-secondary">Bestellung</span>
            </Text>
            <div className="xl:flex md:flex-row  md:items-start sm:items:center sm:flex-col">
              {HowItWorksTracker.map((item, key) => {
                return <Tracker key={key} title={item.title} img={item.icon} />;
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <svg
          width="189"
          height="173"
          className="max-w-7xl hidden md:block absolute center top-4 opacity-30"
          viewBox="0 0 189 173"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M72.7269 100.29L78.1081 60.0163C78.208 59.364 78.501 58.7564 78.9493 58.2721C79.3976 57.7877 79.9806 57.4487 80.6233 57.2986C81.2706 57.1592 81.9452 57.2229 82.555 57.4809C83.1648 57.7389 83.6802 58.1787 84.0309 58.7403L118.463 114.116L120.495 65.9867C120.519 65.3107 120.755 64.6595 121.171 64.1255C121.586 63.5915 122.159 63.2019 122.808 63.012C123.458 62.8567 124.139 62.8944 124.768 63.1205C125.397 63.3466 125.946 63.7513 126.348 64.285L140.076 84.5102L181.986 73.2804C188.161 62.0185 189.985 48.8809 187.111 36.3628C184.088 24.7609 176.885 14.685 166.885 8.0703C156.886 1.45556 144.795 -1.23166 132.935 0.524726C121.075 2.28111 110.282 8.35725 102.629 17.5862C94.9761 26.8152 91.0024 38.5463 91.4714 50.5264C85.8326 40.0164 76.5275 31.9454 65.326 27.8487C54.1245 23.7519 41.8079 23.9151 30.7189 28.3071C19.6298 32.6992 10.5418 41.0138 5.18339 51.6696C-0.175033 62.3253 -1.43008 74.5788 1.65692 86.0996C5.19414 97.458 12.4469 107.298 22.2499 114.038L72.7269 100.29Z"
            fill="#86A7FF"
          />
          <path
            d="M139.545 91.3171C138.901 91.4924 138.218 91.463 137.591 91.2329C136.964 91.0029 136.424 90.5836 136.046 90.0331L126.503 75.9732L124.441 124.822C124.41 125.51 124.161 126.171 123.73 126.71C123.3 127.248 122.71 127.636 122.045 127.818C121.377 127.995 120.67 127.949 120.031 127.687C119.391 127.426 118.855 126.963 118.502 126.368L83.2893 69.7357L78.9394 103.247C78.854 103.874 78.5857 104.462 78.1678 104.937C77.7499 105.413 77.2011 105.755 76.59 105.92L28.8763 118.705C42.1812 127.402 57.6593 134.096 69.7151 140.951C87.9622 151.346 106.052 162.316 124.456 172.136C138.331 148.469 152.307 124.686 166.015 101.063C169.809 94.4933 174.069 87.8423 177.848 81.0537L139.545 91.3171Z"
            fill="#86A7FF"
          />
        </svg>

        <div className="max-w-full md:px-10">
          <div className="max-w-7xl px-4 md:px-0 lg:px-0 mx-auto flex flex-col justify-center items-center space-y-8 py-10 text-center">
            <div>
              <Text type="h2">
                <span className="text-secondary">
                  Vorteile der Pflegebox-Daheim
                </span>
              </Text>
              <CurveLine className="w-60 md:w-40" />
            </div>
            <div className="flex justify-center flex-wrap  items-baseline	">
              {Benifits.map((item, key) => {
                const show = (key + 1) % 3 !== 0;

                return (
                  <Section
                    show={show}
                    key={key}
                    title={item.title}
                    caption={item.description}
                    img={item.img}
                  ></Section>
                );
              })}
            </div>
          </div>
        </div>
        <BookMe />
      </div>
    </React.Fragment>
  );
};

export default HomePage;
