import React, { useState } from "react";

import * as Button from "components/Button";
import Text from "components/Text";
import Input from "components/Input";
import Container from "components/Container";

import { InlineWidget } from "react-calendly";
import { Form, Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useRef } from "react";

const contactSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Pflichtfeld *"),
  message: Yup.string().required("Pflichtfeld *"),
  email: Yup.string().email("Invalid email").required("Pflichtfeld *"),
});

const ContactPage = () => {
  const [data, setData] = useState({
    first_name: "",
    email: "",
    language: "",
    phone_number: "",
    message: "",
  });
  const [showMessage, setShowMessage] = useState(false);
  const calendlyDiv = useRef();
  const messageDiv = useRef();
  const selectRef = useRef();
  console.log("selectRef", selectRef);
  const openSelect = () => {
    selectRef.current.focus();
  };
  const calendlyHandle = () => {
    calendlyDiv.current.scrollIntoView({ behavior: "smooth" });
  };
  const messageHandle = () => {
    messageDiv.current.scrollIntoView({ behavior: "smooth" });
  };
  const sendContactData = async (values) => {
    try {
      const resp = await axios({
        method: "post",
        url: "https://fledgebox-app.herokuapp.com/contact-us",
        data: values,
      });
      console.log(resp.data, "data", resp.status);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  const handleSubmit = (values) => {
    setData(values);
    sendContactData(values);
  };
  console.log(data);

  return (
    <React.Fragment>
      {/* Cover */}
      <div className="relative max-w-full mx-auto md:px-10 min-h-max pt-10 bg-light overflow-hidden flex flex-col items-center space-y-6">
        <div className="max-w-7xl  flex-col md:flex-row lg:flex-row px-4 md:px-20 flex justify-start items-end	">
          {" "}
          <div className="lg:w-2/5 md:w-full sm:w-full flex">
            <img
              className="w-full  h-[380px] left-0 bottom-0  object-cover py-4 md:py-0 lg:py-0 "
              src="/kontakt.png"
              alt="doctor"
            />
          </div>
          <div className="lg:w-3/5 sm:w-full flex flex-col items-center py-4 md:py-0 lg:py-0">
            <div className="space-y-6 mb-10">
              <Text type="h1">
                Kostenlose{" "}
                <span className="text-secondary">Pflegeberatung</span>
              </Text>
              <p className=" text-center text-lg mb-10">
                Kommt es bei Ihnen oder einem Familienangehörigen zu einer
                Pflegebedürftigkeit, ist die Organisation und die Versorgung des
                Betroffenen nicht nur körperlich, sondern auch psychisch extrem
                belastend. Unsere persönliche Pflegeberatung wird Ihnen dabei
                helfen eine Übersicht über die komplexen Pflegeansprüche zu
                bekommen, die eigene Pflegesituation zu organisieren und sich
                auf die Pflege vorzubereiten.
              </p>
              <div className="flex flex-col lg:flex-row justify-center md:justify-center ">
                <Button.Primary
                  onClick={() => {
                    setShowMessage(false);
                    calendlyHandle();
                  }}
                >
                  Kostenlosen Rückruf buchen
                </Button.Primary>
                <Button.Secondary
                  onClick={() => {
                    setShowMessage(true);
                    messageHandle();
                  }}
                >
                  Nachricht senden
                </Button.Secondary>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Tab */}

      <div className="max-w-full px-4  md:px-20 space-y-16">
        <div className="max-w-7xl  mx-auto  flex">
          <Button.MenuTab
            active={!showMessage}
            onClick={() => setShowMessage(false)}
          >
            Kostenlosen Rückruf buchen
          </Button.MenuTab>
          <Button.MenuTab
            active={showMessage}
            onClick={() => setShowMessage(true)}
          >
            Nachricht senden
          </Button.MenuTab>
        </div>

        <div className="max-w-full mx-auto ">
          {showMessage ? (
            <Formik
              validationSchema={contactSchema}
              initialValues={data}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form className="space-y-14 text-center pb-10" ref={messageDiv}>
                  <div className="space-y-14 text-center pb-10">
                    <Text type="h2">
                      <span className="text-secondary">Nachricht senden</span>
                    </Text>
                    <div className="text-left flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="relative space-y-4 m-2">
                          <Text type="h4">Ihr vollständiger Name *</Text>
                          <Field
                            name="first_name"
                            placeholder="Bitte tragen Sie Ihren Namen ein."
                            className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                          />
                          <h4 className="text-red-500 font-bold">
                            <ErrorMessage name="first_name" />
                          </h4>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2">
                        <div className="relative space-y-4 m-2">
                          <Text type="h4">E-Mail-Adresse *</Text>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Bitte tragen Sie Ihre E-Mail-Adresse ein."
                            className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                          />{" "}
                          <h4 className="text-red-500 font-bold">
                            <ErrorMessage name="email" />
                          </h4>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2">
                        <div className="relative space-y-4 m-2">
                          <Text type="h4">Bevorzugte Sprache</Text>
                          <Field
                            ref={selectRef}
                            as="select"
                            name="language"
                            className="relative w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg leading-tight"
                          >
                            {/* appearance-none */}
                            <option value="Deutsch" selected>
                              Deutsch
                            </option>
                            <option value="Englisch">Englisch</option>
                            <option value="Russisch">Russisch</option>{" "}
                            <option value="Turkish">Türkisch</option>
                          </Field>
                          {/* 
                          <span
                            className="absolute w-4 h-4 right-4 top-9"
                            onClick={openSelect}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 7.79998L11.8 15L4.59995 7.79997"
                                stroke="#ABABAB"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span> */}
                        </div>{" "}
                      </div>
                      <div className="w-full md:w-1/2">
                        <div className="relative space-y-4 m-2">
                          <Text type="h4">Telefonnummer</Text>
                          <Field
                            type="tel"
                            name="phone_number"
                            placeholder="Telefonnummer"
                            className="w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                          />

                          <svg
                            className="absolute w-4 h-4 right-4 top-9"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.4809 14.4752C18.3172 14.4752 17.1747 14.2932 16.092 13.9354C15.5615 13.7544 14.9093 13.9205 14.5855 14.253L12.4485 15.8662C9.97015 14.5433 8.44354 13.0172 7.13867 10.5574L8.70442 8.47611C9.11122 8.06986 9.25713 7.47642 9.08232 6.9196C8.72299 5.83122 8.54045 4.68923 8.54045 3.52512C8.5405 2.68416 7.85634 2 7.01543 2H3.52507C2.68416 2 2 2.68416 2 3.52507C2 13.1641 9.84181 21.0059 19.4809 21.0059C20.3218 21.0059 21.0059 20.3217 21.0059 19.4808V16.0002C21.0059 15.1593 20.3217 14.4752 19.4809 14.4752Z"
                              fill="#A4A9A7"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="relative space-y-4 m-2">
                          <Text type="h4">Ihre Nachricht * </Text>
                          <Field
                            as="textarea"
                            id="message"
                            name="message"
                            placeholder="Bitte tragen Sie Ihre Nachricht ein."
                            className="py-3 w-full h-12 border-2 border-gray-200 px-4 text-gray-500 focus:outline-none bg-gray-50 text-lg"
                          ></Field>
                          <h4 className="text-red-500 font-bold">
                            <ErrorMessage name="message" />
                          </h4>
                        </div>
                      </div>
                    </div>
                    <Button.Primary onClick={handleSubmit}>
                      Nachricht Senden
                    </Button.Primary>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div ref={calendlyDiv}>
              <Text type="h2">
                <span className="text-secondary">
                  Kostenlosen Rückruf buchen
                </span>
              </Text>
              <InlineWidget url="https://calendly.com/pflegebox-daheim/30min" />
            </div>
          )}
        </div>
      </div>

      {/* Map */}
      <section className="max-w-7xl  mx-auto  flex  px-4   md:px-10  md:h-96 md:min-h-max bg-light flex-col md:flex-row xl:flex ">
        <div className="w-full md:w-1/2 h-full p-10">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="map"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.6459896393617!2d10.013831122673336!3d53.56408671155446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18edb21e350f3%3A0xa433b2de3e746c5f!2sSchwanenwik%2014%2C%2022087%20Hamburg%2C%20Germany!5e0!3m2!1sen!2sus!4v1668431673913!5m2!1sen!2sus"
          ></iframe>
        </div>
        <div className="w-full md:w-1/2 p-10  flex justify-center items-center">
          <div className="space-y-10">
            <Text type="h2">
              <span className="text-secondary">Kontakt</span>
            </Text>
            <div className="space-y-4">
              <Text type="light">
                Bei Fragen oder Anmerkungen helfen wir Ihnen gerne weiter!
              </Text>
              <Text type="light">
                <span className="text-gray-800"> Kundenservice:</span> 040
                65908570
              </Text>
              <Text type="light">
                <span className="text-gray-800 mr-2"> E-Mail:</span>
                kontakt@pflegebox-daheim.de
              </Text>
              <Text type="light">
                <span className="text-gray-800"> </span>Kundenservice erreichbar
                von 09:00 bis 16:00 Uhr
              </Text>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactPage;
