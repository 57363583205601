export const STEPS = [
  {
    title: "Pflegebedürftige(r)",
    key: "insurance_information",
  },
  {
    title: "Pflegeperson",
    key: "caregiver_information",
  },
  {
    title: "Lieferoptionen",
    key: "shipping_address",
  },
  {
    title: "Übersicht",
    key: "confirmation",
  },
];
